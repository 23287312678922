const LastQCRecordStatuses = {
  unknown: {
    textColor: (theme) => theme.palette.text.primary,
    label: undefined,
  },
  failed: {
    textColor: (theme) => theme.palette.error.main,
    label: 'Last QC Failed',
  },
  passed: {
    textColor: (theme) => theme.palette.success.main,
    label: 'Last QC Passed',
  },
};

export { LastQCRecordStatuses };
