import { Radar } from 'ca-react-component-lib';
import { UploadIconsVariants } from 'src/types/UploadIcons';
import { UploadIconProps, UploadIconSize } from './types';

const { useTheme } = Radar;

export const UploadIcon = (props: UploadIconProps) => {
  const theme = useTheme();
  const {
    variant = 'default',
    size = UploadIconSize.MEDIUM,
  } = props;

  return (
    <>
      {UploadIconsVariants(theme, size)[variant]}
    </>
  );
};
