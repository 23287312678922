// graphql
import { File as GQLFile } from 'src/__generated__/graphql';

// types
import { CreateFileType, FileExtension, FileType, FileTypesHandler } from 'src/types/FileTypes';
import { FileStatus, FileStatusSettings } from 'src/types/FileStatus';
import { FolderName } from 'src/types/Folder';

const getFileExtension = (type: string): FileExtension => {
  let foundFileExtension: FileExtension = 'unknown';

  FileTypesHandler.forEach((item) => {
    if (item.isFileExtensionMatched(type.split('/')[1])) {
      foundFileExtension = item.fileExtension;
    }
  });

  return foundFileExtension;
};

const getFileExtensionForGqlFile = (gqlFile: GQLFile): FileExtension => {
  let foundFileExtension: FileExtension = 'unknown';

  const keys = gqlFile?.fileName?.split('.');
  const extension = keys && keys[keys.length - 1].toLowerCase();

  FileTypesHandler.forEach((item) => {
    if (extension && item.isFileExtensionMatched(extension)) {
      foundFileExtension = item.fileExtension;
    }
  });

  return foundFileExtension;
};

export const createFileType = (
  droppedFiles: File[],
  folderName: string,
  records?
): CreateFileType => {
  const knownRecords = records ? [...records] : [];

  let hasDuplicatedFiles = false;
  const files: FileType[] = droppedFiles.map((file: File) => {
    const item = {
      name: file.name,
      oldFileName: file.name,
      size: file.size.toString(),
      extension: getFileExtension(file.type),
      status:
        getFileExtension(file.type) !== 'JPG' || file.size > 52428800
          ? FileStatusSettings.failed.value
          : FileStatusSettings.pendingUploading.value,
      file: file,
      selected: false,
      folderName: folderName as FolderName,
      lastModified: file.lastModified,
    };
    let duplicateFile = knownRecords.find(((x: any) => x.name === item.name && x.folderName === folderName));
    let i = 1;
    while (duplicateFile) {
      hasDuplicatedFiles = true;
      const splitName = item.name.split('.');
      let fileName = `${splitName[0]} (${i}).${splitName[1]}`;
      i++;
      duplicateFile = knownRecords.find(((x: any) => x.name === fileName && x.folderName === folderName));

      if (!duplicateFile) {
        // create new file to have access to file prototype.
        const file = new File([item.file], fileName, {
          type: item.file.type,
        });
        item.name = fileName;
        item.file = file;
        knownRecords.push(item);
      }
    }

    return item;
  });

  return {
    hasDuplicatedFiles,
    files: files,
  };
};

// create file type list from gql response
// preserve file blob even if we need to update the file types from gql
export const updateFilesWithGQLFiles = (
  gqlFiles: GQLFile[],
  olderFileTypes: FileType[] | undefined
) => {
  // preserve file blob even if we need to update the file types from gql
  const fileTypes: FileType[] = gqlFiles.map((gqlFile: GQLFile) => {
    // find file type with same record id
    const oldFileType: FileType | undefined = olderFileTypes?.find((fileType: FileType) => {
      let gqlFileStatus: any = gqlFile.status;
      const gqlFileExtension = getFileExtensionForGqlFile(gqlFile);

      if (gqlFileExtension !== 'JPG' || Number(gqlFile.size) > 52428800) {
        gqlFileStatus = FileStatusSettings.failed.value;
      }

      if (fileType.id === gqlFile?.id) {
        return true;
      }
      if (
        fileType.folderName === gqlFile.folderName &&
        fileType.name === gqlFile.fileName &&
        fileType.status === gqlFileStatus &&
        fileType.file
      ) {
        return true;
      }
      return false;
    });

    const extension = oldFileType ? oldFileType.extension : getFileExtensionForGqlFile(gqlFile);

    let status: FileStatus = gqlFile.status as FileStatus;

    if (gqlFile.status === FileStatusSettings.uploaded.value) {
      status = FileStatusSettings.uploaded.value;
    } else if (gqlFile.status === FileStatusSettings.validated.value) {
      status = FileStatusSettings.validated.value;
    } else if (gqlFile.status === FileStatusSettings.canceled.value) {
      status = FileStatusSettings.canceled.value;
    } else if (extension !== 'JPG' || Number(gqlFile.size) > 52428800) {
      if (!oldFileType) {
        status = FileStatusSettings.canceled.value;
      } else {
        status = FileStatusSettings.failed.value;
      }
    } else if (!oldFileType?.file) {
      status = FileStatusSettings.canceled.value;
    } else if (oldFileType.status === FileStatusSettings.paused.value) {
      status = FileStatusSettings.paused.value;
    }

    return {
      name: gqlFile.fileName || '',
      size: gqlFile.size,
      extension: extension,
      status: status,
      selected: false,
      folderName: gqlFile.folderName as FolderName,
      file: status === FileStatusSettings.canceled.value ? undefined : oldFileType?.file,
      oldFileName: oldFileType?.oldFileName,
      id: gqlFile.id || undefined,
      path: gqlFile.path || undefined,
      activeAt: gqlFile?.activeAt ? gqlFile.activeAt : oldFileType?.activeAt,
      uploadedAt: gqlFile?.uploadedAt ? gqlFile.uploadedAt : oldFileType?.uploadedAt,
    };
  });

  return fileTypes;
};
