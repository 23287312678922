// ca
import { Radar } from 'ca-react-component-lib';

// hooks
import { useFileStatus } from 'src/hooks';
import { useMemo } from 'react';

// types
import { FileStatusProps } from './types';

const { Box, Typography } = Radar;

export const FileStatus = ({ status }: FileStatusProps) => {
  const { getFileStatusSetting } = useFileStatus();

  const fileUploadSettings = useMemo(
    () => getFileStatusSetting(status),
    [getFileStatusSetting, status]
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {fileUploadSettings.icon}
      <Typography
        sx={{ whiteSpace: 'nowrap' }}
        variant={'buttonMedium'}
        color={fileUploadSettings.textColor}
      >
        {fileUploadSettings.label}
      </Typography>
    </Box>
  );
};
