import { Radar } from 'ca-react-component-lib';
import { useMemo } from 'react';
import { FileTypeIconProps } from './types';

const { FontAwesome, useTheme } = Radar;

const FileTypeIcon = ({ fileExtension }: FileTypeIconProps) => {
  const theme = useTheme();

  const iconState = useMemo(() => {
    if (fileExtension === 'PDF') return 'file-pdf';
    if (fileExtension === 'JPG' || fileExtension === 'PNG') return 'file-image';
    if (fileExtension === 'MP3') return 'file-music';
    if (fileExtension === 'MP4') return 'file-video';
    if (fileExtension === 'JSON') return 'file-code';
    if (fileExtension === 'CSV') return 'file-csv';
    return 'file';
  }, [fileExtension]);

  return <FontAwesome icon={iconState} size={theme.size.icon.huge} />;
};

export { FileTypeIcon };
