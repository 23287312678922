import { KEYS } from './types';

const { localStorage } = window;

export const setData = (key: string, value: string) => localStorage.setItem(key, value);

export const getData = (key: string) => localStorage.getItem(key);

export const clearData = (key: string) => localStorage.removeItem(key);

export { KEYS };
