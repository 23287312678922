import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.UploadRecord;

const getUploadRecords = (state: ApplicationState) => _getData(state).uploadRecords;

const getUploadRecordId = (state: ApplicationState) => _getData(state).uploadRecordId;

// create upload record
const getCreateUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).createUploadRecordLoading;

const getCreateUploadRecordSuccess = (state: ApplicationState) =>
  _getData(state).createUploadRecordSuccess;

const getCreateUploadRecordError = (state: ApplicationState) =>
  _getData(state).createUploadRecordError;

// start upload record
const getStartUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).startUploadRecordLoading;

const getStartUploadRecordError = (state: ApplicationState) =>
  _getData(state).startUploadRecordError;

// delete upload record
const getDeleteUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).deleteUploadRecordLoading;

const getDeleteUploadRecordSuccess = (state: ApplicationState) =>
  _getData(state).deleteUploadRecordSuccess;

const getDeleteUploadRecordError = (state: ApplicationState) =>
  _getData(state).deleteUploadRecordError;

// get upload records
const getFetchUploadRecordsLoading = (state: ApplicationState) =>
  _getData(state).fetchUploadRecordsLoading;

const getFetchUploadRecordsError = (state: ApplicationState) =>
  _getData(state).fetchUploadRecordsError;

// get upload record
const getFetchUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).fetchUploadRecordLoading;

const getFetchUploadRecordSuccess = (state: ApplicationState) =>
  _getData(state).fetchUploadRecordSuccess;

const getFetchUploadRecordError = (state: ApplicationState) =>
  _getData(state).fetchUploadRecordError;

// pause upload record
const getPauseUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).pauseUploadRecordLoading;

const getPauseUploadRecordError = (state: ApplicationState) =>
  _getData(state).pauseUploadRecordError;

// cancel upload record
const getCancelUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).cancelUploadRecordLoading;

const getCancelUploadRecordError = (state: ApplicationState) =>
  _getData(state).cancelUploadRecordError;

// complete upload record
const getCompleteUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).completeUploadRecordLoading;

const getCompleteUploadRecordError = (state: ApplicationState) =>
  _getData(state).completeUploadRecordError;

// get upload records filter
const getUploaderFilter = (state: ApplicationState) => _getData(state).uploaderFilter;

// send files upload record
const getSendFilesUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).sendFilesUploadRecordLoading;

const getSendFilesUploadRecordError = (state: ApplicationState) =>
  _getData(state).sendFilesUploadRecordError;

// rename upload record
const getRenameUploadRecordLoading = (state: ApplicationState) =>
  _getData(state).renameUploadRecordLoading;

const getRenameUploadRecordSuccess = (state: ApplicationState) =>
  _getData(state).renameUploadRecordSuccess;

const getRenameUploadRecordError = (state: ApplicationState) =>
  _getData(state).renameUploadRecordError;

const selectors = {
  getUploadRecords,
  getUploadRecordId,

  // create upload record
  getCreateUploadRecordLoading,
  getCreateUploadRecordSuccess,
  getCreateUploadRecordError,

  // start upload record
  getStartUploadRecordLoading,
  getStartUploadRecordError,

  // delete upload record
  getDeleteUploadRecordLoading,
  getDeleteUploadRecordSuccess,
  getDeleteUploadRecordError,

  // get upload records
  getFetchUploadRecordsLoading,
  getFetchUploadRecordsError,

  // get upload record
  getFetchUploadRecordLoading,
  getFetchUploadRecordSuccess,
  getFetchUploadRecordError,

  // pause upload record
  getPauseUploadRecordLoading,
  getPauseUploadRecordError,

  // cancel upload record
  getCancelUploadRecordLoading,
  getCancelUploadRecordError,

  // complete upload record
  getCompleteUploadRecordLoading,
  getCompleteUploadRecordError,

  // get upload records filter
  getUploaderFilter,

  // send files upload record
  getSendFilesUploadRecordLoading,
  getSendFilesUploadRecordError,

  // rename upload record
  getRenameUploadRecordLoading,
  getRenameUploadRecordSuccess,
  getRenameUploadRecordError,
};

export default selectors;
