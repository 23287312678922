// apollo
import { gql, ApolloClient } from '@apollo/client';

// graphql
import { FileListInput, UpdateFilesStatusResponse } from 'src/__generated__/graphql';

const CANCEL_UPLOAD_FILES = gql(`
  mutation cancelFiles($params: FileListInput!) {
    cancelFiles(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      files {
        fileName
        folderName
        id
        path
        size
        status
        activeAt
        uploadedAt
      }
    }
  }
`);

const mutationCancelUploadFiles = async (client: ApolloClient<object>, params: FileListInput) => {
  try {
    const response = await client.mutate<
      { cancelFiles: UpdateFilesStatusResponse }, // mutation response
      { params: FileListInput } // mutation input
    >({
      mutation: CANCEL_UPLOAD_FILES,
      variables: { params },
    });

    if (!response?.data?.cancelFiles.status.errorCode && response?.data?.cancelFiles) {
      return response.data.cancelFiles;
    } else {
      throw new Error(response?.data?.cancelFiles.status.errorDetails?.message as string);
    }
  } catch (error) {
    throw error;
  }
};

export default mutationCancelUploadFiles;
