import { useNavigate } from 'react-router';

// CA
import { Radar } from 'ca-react-component-lib';

// hooks
import { useGlobalUploadWidget, useUploadRecord } from 'src/hooks';

// components
import SummaryButton from './SummaryButton';

// routes
import { MAIN_PATHS } from 'src/routes/paths';

// utils
import Label from './Label';

// Radar DS
const { Box } = Radar;

const Header = () => {
  const navigate = useNavigate();
  const { expanded, setExpanded } = useGlobalUploadWidget();
  const { uploadRecords } = useUploadRecord();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Label />
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        {uploadRecords.length > 0 && (
          <SummaryButton
            tooltipTitle={expanded ? 'Minimize' : 'Maximize'}
            icon={expanded ? 'minus' : 'chevron-up'}
            iconType={expanded ? 'solid' : 'regular'}
            onClick={() => setExpanded(!expanded)}
          />
        )}
        <SummaryButton
          tooltipTitle={'Open Uploads'}
          icon={'arrow-up-right-from-square'}
          onClick={() => navigate(MAIN_PATHS.upload)}
        />
      </Box>
    </Box>
  );
};

export default Header;
