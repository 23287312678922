import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// graphql
// TODO: uncomment when yarn compile works properly.
// import { gql } from 'src/__generated__/gql';
import { UploadFilesResponse, UploadFileListInput, File } from 'src/__generated__/graphql';

// store
import actions from 'src/store/UploadRecord/actions';

// types
import { FileType } from 'src/types/FileTypes';
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';

const SEND_UPLOAD_FILE_LIST = gql(`
  mutation sendUploadFileList($params: UploadFileListInput!) {
    sendUploadFileList(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      signedUrl{
        url
        fields {
          contentType
          key
          awsAccessKeyId
          policy
          signature
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        id
        path
        status
        size
        folderName
        fileName
        activeAt
        uploadedAt
      }
    
    }
  }
`);

const useSendFilesUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const sendFilesUploadRecord = useCallback(
    (
      uploadRecord: UploadRecord,
      filesToAdd: FileType[]
    ) => {
      const handleSendFilesUploadRecord = async (
      ) =>
      {
        try {
          if (!uploadRecord?.upload?.id) {
            console.error('Tried to send file to invalid upload.');
            return;
          }

          dispatch(actions.setSendFilesUploadRecordLoading(true));

          const params: UploadFileListInput = {
            uploadId: uploadRecord.upload.id,
            files: filesToAdd.map((file: FileType) => ({
              path: `${file.folderName}/${file.name}`,
              size: file.file?.size.toString(),
            })),
          };

          const response = await client.mutate<
              { sendUploadFileList: UploadFilesResponse }, // mutation response
              { params: UploadFileListInput } // mutation input
            >({
              mutation: SEND_UPLOAD_FILE_LIST,
              variables: { params },
            });

          if (
            !response?.data?.sendUploadFileList.status?.errorCode &&
              response?.data?.sendUploadFileList
          ) {
            let fileTypes: FileType[] = updateFilesWithGQLFiles(
                response?.data?.sendUploadFileList.files as File[],
                [...uploadRecord.files, ...filesToAdd]
            );

            const newUploadRecord: UploadRecord = {
              files: fileTypes,
              status: response?.data?.sendUploadFileList.upload?.status as UploadStatus,
              upload: response?.data?.sendUploadFileList.upload,
              signedUrl: response?.data?.sendUploadFileList.signedUrl,
            };

            dispatch(actions.setUploadRecord(newUploadRecord));
          } else {
            const error = new Error(
                response?.data?.sendUploadFileList?.status?.errorDetails?.message as string
            );

            dispatch(actions.setSendFilesUploadRecordError(error));
          }
        } catch (error) {
          dispatch(actions.setSendFilesUploadRecordError(error));
        } finally {
          dispatch(actions.setSendFilesUploadRecordLoading(false));
        }
      };

      handleSendFilesUploadRecord();
    },
    [client, dispatch]
  );

  return {
    sendFilesUploadRecord,
  };
};

export default useSendFilesUploadRecord;
