// components
import { Canceled, Completed, Paused, Active, Failed, PendingUploading, Created } from './controls';

// types

import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';
import { UploadProgressControlHandlersType } from './types';

const CreatedHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus) => status === UploadStatusSettings.created.value,
  renderControls: (uploadRecord) => <Created uploadRecord={uploadRecord} />,
};

const ActiveHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus) => status === UploadStatusSettings.active.value,
  renderControls: (uploadRecord) => <Active uploadRecord={uploadRecord} />,
};

const FailedHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus, failedFiles?: number, pendingUploadingFiles?: number) =>
    status === UploadStatusSettings.failed.value && !!(failedFiles && !pendingUploadingFiles),
  renderControls: (uploadRecord) => <Failed uploadRecord={uploadRecord} />,
};

const CompletedHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus) => status === UploadStatusSettings.completed.value,
  renderControls: (uploadRecord) => <Completed uploadRecord={uploadRecord} />,
};

const PausedHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus) => status === UploadStatusSettings.paused.value,
  renderControls: (uploadRecord) => <Paused uploadRecord={uploadRecord} />,
};

const CanceledHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus) => status === UploadStatusSettings.canceled.value,
  renderControls: (uploadRecord) => <Canceled uploadRecord={uploadRecord} />,
};

const PendingUploadingHandler: UploadProgressControlHandlersType = {
  isMatched: (status: UploadStatus, failedFiles?: number, pendingUploadingFiles?: number) =>
    status === UploadStatusSettings.pendingUploading.value ||
    !!(failedFiles && pendingUploadingFiles),
  renderControls: (uploadRecord) => <PendingUploading uploadRecord={uploadRecord} />,
};

const UploadProgressControlHandlers = [
  ActiveHandler,
  FailedHandler,
  CompletedHandler,
  PausedHandler,
  CanceledHandler,
  PendingUploadingHandler,
  CreatedHandler,
];

export default UploadProgressControlHandlers;
