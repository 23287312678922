import ButtonVariant from './ButtonVariant';
import IconButtonVariant from './IconButtonVariant';
import { UploadControlButtonProps, UploadControlButtonVariants } from './types';

import { Radar } from 'ca-react-component-lib';

const { useTheme } = Radar;

export const UploadControlButton = ({
  component = 'icon-button',
  variant,
  onClick,
  buttonLabel,
  loading,
}: UploadControlButtonProps) => {
  const theme = useTheme();
  return (
    <>
      {component === 'icon-button' ? (
        <IconButtonVariant
          icon={UploadControlButtonVariants(theme)[variant].iconButtonIcon}
          tooltip={UploadControlButtonVariants(theme)[variant].tooltip}
          onClick={onClick}
        />
      ) : (
        <ButtonVariant
          icon={UploadControlButtonVariants(theme)[variant].buttonIcon}
          onClick={onClick}
          buttonLabel={buttonLabel}
          isCancel={variant === 'cancel'}
          loading={loading}
        />
      )}
    </>
  );
};
