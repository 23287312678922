import { useMemo } from 'react';

// types
import { UploadProgressControlProps } from './types';
import { FileStatusSettings } from 'src/types/FileStatus';

// constants
import UploadProgressControlHandlers from './constants';

// utils
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';
import getUploadRecordFilesCountByStatus from 'src/utils/getUploadRecordFilesCountByStatus';

export const UploadProgressControl = ({ uploadRecord }: UploadProgressControlProps) => {
  const failedFiles = useMemo(
    () => getUploadRecordFilesCountByStatus(FileStatusSettings.failed.value, uploadRecord),
    [uploadRecord]
  );

  const pendingUploadingFiles = useMemo(
    () =>
      getUploadRecordFilesCountByStatus(FileStatusSettings.pendingUploading.value, uploadRecord),
    [uploadRecord]
  );

  const renderControls = () =>
    UploadProgressControlHandlers.map((uploadProgressControl, index: number) => {
      if (
        uploadProgressControl.isMatched(
          getUploadRecordStatusFromFiles(uploadRecord),
          failedFiles,
          pendingUploadingFiles
        )
      ) {
        return <div key={index}>{uploadProgressControl.renderControls(uploadRecord)}</div>;
      } else return null;
    });

  return <>{renderControls()}</>;
};
