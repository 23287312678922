import { FolderName } from 'src/types/Folder';
import { FileType } from 'src/types/FileTypes';
import { sortFilesToFolders } from './utils';

export type SortedFoldersState = {
  accessroad: {
    name: FolderName;
    files: FileType[];
  };
  drone: {
    name: FolderName;
    files: FileType[];
  };
  facade: {
    name: FolderName;
    files: FileType[];
  };
  hotspot: {
    name: FolderName;
    files: FileType[];
  };
  panorama: {
    name: FolderName;
    files: FileType[];
  };
  rooftop: {
    name: FolderName;
    files: FileType[];
  };
  tower: {
    name: FolderName;
    files: FileType[];
  };
};

export const sortedFolders = (files?: FileType[]): SortedFoldersState => ({
  accessroad: {
    name: 'accessroad' as FolderName,
    files: sortFilesToFolders(files, 'accessroad'),
  },
  drone: {
    name: 'drone' as FolderName,
    files: sortFilesToFolders(files, 'drone'),
  },
  facade: {
    name: 'facade' as FolderName,
    files: sortFilesToFolders(files, 'facade'),
  },
  hotspot: {
    name: 'hotspot' as FolderName,
    files: sortFilesToFolders(files, 'hotspot'),
  },
  panorama: {
    name: 'panorama' as FolderName,
    files: sortFilesToFolders(files, 'panorama'),
  },
  rooftop: {
    name: 'rooftop' as FolderName,
    files: sortFilesToFolders(files, 'rooftop'),
  },
  tower: {
    name: 'tower' as FolderName,
    files: sortFilesToFolders(files, 'tower'),
  },
});
