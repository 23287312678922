// This file name is "FileType", not "File", because already exist File type in node.

// type
import { FileStatus } from './FileStatus';
import { FolderName } from './Folder';

/**
 * - name: name of the file, build when fetch or add files
 * - extension: extension of the file, build when fetch or add files
 * - status: status of the file, build when fetch or add files
 * - selected: useful to handle for UI operations
 * - size: size in bytes
 * - folderName: the folder name where this file should be
 * - file: File type from node. It represents the "blob" of the file ready to upload to s3
 * - path: s3 path where should be uploaded
 * - activeAt: time stamp when upload started
 * - uploadedAt: time stamp when upload finished
 * - progress: from 0 to 1. 0 not started and 1 uploaded
 * - sizeUploaded: when uploading, size in bytes that already uploaded
 * - timeRemaining: when uploading, eta remaining to finish
 * - averageSpeed: when uploading, average of the speed
 * - currentSpeed: when uploading, current speed
 */
export type FileType = {
  name: string;
  extension: FileExtension;
  status: FileStatus;
  selected: boolean;
  size: string;
  folderName: FolderName;
  file?: File;
  id?: string;
  path?: string;
  oldFileName?: string;
  fileName?: string;
  activeAt?: string;
  uploadedAt?: string;
  progress?: number;
  sizeUploaded?: number;
  timeRemaining?: number;
  averageSpeed?: number;
  currentSpeed?: number;
};

export type CreateFileType = {
  hasDuplicatedFiles: boolean;
  file?: FileType | undefined;
  files?: FileType[];
};

export type FileExtension = 'PDF' | 'PNG' | 'JPG' | 'MP3' | 'MP4' | 'JSON' | 'CSV' | 'unknown';

export type FileExtensionHandler = {
  isFileExtensionMatched: (fileExtension: string) => boolean;
  fileExtension: FileExtension;
};

export const FileTypesHandler: FileExtensionHandler[] = [
  {
    isFileExtensionMatched: (fileExtension: string) =>
      fileExtension === 'PDF' || fileExtension === 'pdf',
    fileExtension: 'PDF',
  },
  {
    isFileExtensionMatched: (fileExtension: string) =>
      fileExtension === 'jpg' || fileExtension === 'jpeg',
    fileExtension: 'JPG',
  },
  {
    isFileExtensionMatched: (fileExtension: string) => fileExtension === 'png',
    fileExtension: 'PNG',
  },
  {
    isFileExtensionMatched: (fileExtension: string) => fileExtension === 'mp4',
    fileExtension: 'MP4',
  },
  {
    isFileExtensionMatched: (fileExtension: string) => fileExtension === 'mp3',
    fileExtension: 'MP3',
  },
  {
    isFileExtensionMatched: (fileExtension: string) => fileExtension === 'csv',
    fileExtension: 'CSV',
  },
  {
    isFileExtensionMatched: (fileExtension: string) => fileExtension === 'json',
    fileExtension: 'JSON',
  },
];
