// CA
import { Radar } from 'ca-react-component-lib';

// components
import { UploadIcon } from 'src/components';

// Radar DS
const { Typography, Box, useTheme } = Radar;

const Created = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
      <UploadIcon variant={'defaultWhite'} />
      <Typography variant="h6" color={theme.palette.common.white}>
        Uploads
      </Typography>
    </Box>
  );
};

export default Created;
