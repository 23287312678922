import api from './api';

import { LoginRequestResponse } from './types';

export const login = async (email: string, password: string): Promise<LoginRequestResponse> => {
  try {
    const response = await api.authenticate(email, password);
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
