export enum UploadFilesActionType {
  // uploaded upload files
  SET_UPLOADED_UPLOAD_FILES_LOADING = 'UploadFiles@SET_UPLOADED_UPLOAD_FILES_LOADING',
  SET_UPLOADED_UPLOAD_FILES_ERROR = 'UploadFiles@SET_UPLOADED_UPLOAD_FILES_ERROR',

  // delete upload files
  SET_DELETE_UPLOAD_FILES_LOADING = 'UploadRecord@SET_DELETE_UPLOAD_FILES_LOADING',
  SET_DELETE_UPLOAD_FILES_ERROR = 'UploadRecord@SET_DELETE_UPLOAD_FILES_ERROR',

  // replace upload files
  SET_REPLACE_UPLOAD_FILES_LOADING = 'UploadRecord@SET_REPLACE_UPLOAD_FILES_LOADING',
  SET_REPLACE_UPLOAD_FILES_ERROR = 'UploadRecord@SET_REPLACE_UPLOAD_FILES_ERROR',

  // pause upload files
  SET_PAUSE_UPLOAD_FILES_LOADING = 'UploadFiles@SET_PAUSE_UPLOAD_FILES_LOADING',
  SET_PAUSE_UPLOAD_FILES_ERROR = 'UploadFiles@SET_PAUSE_FILES_ERROR',

  // cancel upload files
  SET_CANCEL_UPLOAD_FILES_LOADING = 'UploadFiles@SET_CANCEL_UPLOAD_FILES_LOADING',
  SET_CANCEL_UPLOAD_FILES_SUCCESS = 'UploadFiles@SET_CANCEL_UPLOAD_FILES_SUCCESS',
  SET_CANCEL_UPLOAD_FILES_ERROR = 'UploadFiles@SET_CANCEL_UPLOAD_FILES_ERROR',

  CLEAR = 'UploadFiles@CLEAR',
}

export type UploadFilesState = {
  // uploaded upload files
  uploadedUploadFilesLoading: boolean;
  uploadedUploadFilesError?: Error;

  // delete upload files
  deleteUploadFilesLoading: boolean;
  deleteUploadFilesError?: Error;

  // replace upload files
  replaceUploadFilesLoading: boolean;
  replaceUploadFilesError?: Error;

  // pause upload files
  pauseUploadFilesLoading: boolean;
  pauseUploadFilesError?: Error;

  // cancel upload files
  cancelUploadFilesLoading?: boolean;
  cancelUploadFilesSuccess?: boolean;
  cancelUploadFilesError?: Error;
};

export type UploadFilesPayload = {
  uploadingRecordsIds?: string[];

  // uploaded upload files
  uploadedUploadFilesLoading?: boolean;
  uploadedUploadFilesError?: Error;

  // delete upload files
  deleteUploadFilesLoading?: boolean;
  deleteUploadFilesError?: Error;

  // replace upload files
  replaceUploadFilesLoading?: boolean;
  replaceUploadFilesError?: Error;

  // pause upload files
  pauseUploadFilesLoading?: boolean;
  pauseUploadFilesError?: Error;

  // cancel upload files
  cancelUploadFilesLoading?: boolean;
  cancelUploadFilesSuccess?: boolean;
  cancelUploadFilesError?: Error;
};

export type UploadFilesAction = {
  payload?: UploadFilesPayload;
  type: UploadFilesActionType;
};

export type UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => UploadFilesState;
