// graphql
import { UploadStatus as GQLUploadStatus } from 'src/__generated__/graphql';

// components
import { UploadIcon } from 'src/components';

// types
import { UploadIconSize } from 'src/components/UploadIcon/types';

export type UploadStatus =
  | 'Failed'
  | GQLUploadStatus.Created
  | GQLUploadStatus.Active
  | GQLUploadStatus.Canceled
  | GQLUploadStatus.Paused
  | GQLUploadStatus.PendingUploading
  | GQLUploadStatus.Completed;

export const UploadStatusSettings = {
  created: {
    icon: null,
    textColor: (theme) => theme.palette.text.disabled,
    menuTextColor: '#162231',
    label: 'Created',
    value: GQLUploadStatus.Created as UploadStatus,
  },
  completed: {
    icon: <UploadIcon variant="complete" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.success.main,
    menuTextColor: '#2E7D32',
    label: 'Completed',
    value: GQLUploadStatus.Completed as UploadStatus,
  },
  active: {
    icon: <UploadIcon variant="loading" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.primary.main,
    menuTextColor: '#162231',
    label: 'Uploading',
    value: GQLUploadStatus.Active as UploadStatus,
  },
  failed: {
    icon: <UploadIcon variant="failed" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.error.main,
    menuTextColor: '#D32F2F',
    label: 'Failed',
    value: 'Failed' as UploadStatus,
  },
  pendingUploading: {
    icon: null,
    textColor: (theme) => theme.palette.secondary.main,
    menuTextColor: '#0288D1',
    label: 'Ready To Upload',
    value: GQLUploadStatus.PendingUploading as UploadStatus,
  },
  paused: {
    icon: null,
    textColor: (theme) => theme.palette.primary.shade[500],
    menuTextColor: '#9747FF',
    label: 'Paused',
    value: GQLUploadStatus.Paused as UploadStatus,
  },
  canceled: {
    icon: null,
    textColor: (theme) => theme.palette.primary.shade[500],
    menuTextColor: '#9E9E9E',
    label: 'Canceled',
    value: GQLUploadStatus.Canceled as UploadStatus,
  },
};
