// CA
import { Radar } from 'ca-react-component-lib';

// hooks
import { useUploadRecord, useUploader } from 'src/hooks';
import { useMemo } from 'react';

// components
import { UploadIcon } from 'src/components';

// types
import { UploadStatusSettings } from 'src/types/UploadStatus';

// Radar DS
const { Typography, Box, useTheme } = Radar;

const Active = () => {
  const theme = useTheme();
  const { uploadRecords } = useUploadRecord();
  const { isUploading } = useUploader();

  const progress = useMemo(() => {
    let index = 0;
    let progress = 0;

    for (const uploadRecord of uploadRecords) {
      if (uploadRecord.status === UploadStatusSettings.active.value) {
        progress = progress + (uploadRecord.progressRelative ?? 0);
        index = index + 1;
      }
    }

    progress = progress / index;

    return Math.floor(progress);
  }, [uploadRecords]);

  return (
    <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
      <UploadIcon variant={isUploading ? 'loading' : 'defaultWhite'} />
      <Typography variant="h6" color={theme.palette.common.white}>
        {progress}% progress...
      </Typography>
    </Box>
  );
};

export default Active;
