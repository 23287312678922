import { SortedFoldersState } from 'src/pages/Upload/constants';
import { FileStatus } from 'src/types/FileStatus';
import { FileType } from 'src/types/FileTypes';
import { FolderType } from 'src/types/Folder';

const filterFoldersFilesByStatus = (folders: SortedFoldersState, status: FileStatus) => {
  let filteredFolders: FolderType[] = [];
  Object.keys(folders).forEach((folderKey) => {
    const { files, name } = folders[folderKey];
    const filteredFiles: FileType[] = files.filter((file: FileType) => file.status === status);
    if (filteredFiles.length > 0) {
      filteredFolders.push({
        name,
        files: filteredFiles,
      });
    }
  });
  return filteredFolders;
};

export default filterFoldersFilesByStatus;
