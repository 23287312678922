// saga
import { put, call, takeLatest } from 'redux-saga/effects';

// services
import actions from './actions';
import * as siteServices from 'src/services/site';

// types
import { SiteActionType, SiteAction } from './types';
import { SiteListMember } from 'src/types/Site';

export function* fetchSiteListMember({ payload }: SiteAction) {
  if (!payload || !payload.siteListMemberId) return;
  const { siteListMemberId } = payload;
  try {
    yield put(actions.setSiteListMemberLoading(true));
    const response: any = yield call(siteServices.fetchSiteListMember, siteListMemberId);
    yield put(actions.setSiteListMember(response.site));
  } catch (e) {
    yield put(actions.setSiteListMemberError(e as Error));
  } finally {
    yield put(actions.setSiteListMemberLoading(false));
  }
}

export function* updateAssessment({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId || !payload.action) return;
  const { assessmentId, action } = payload;
  try {
    const response: any = yield call(siteServices.updateAssessment, assessmentId, action);
  } catch (e) {
    yield put(actions.setUpdateAssessmentError(e as Error));
  }
}

const sagas = [
  takeLatest(SiteActionType.FETCH_SITE_LIST_MEMBER, fetchSiteListMember),
  takeLatest(SiteActionType.UPDATE_ASSESSMENT, updateAssessment)
];

export default sagas;
