// apollo
import { ApolloClient, gql } from '@apollo/client';

// graphql
import { Upload, UploadResponse } from 'src/__generated__/graphql';

const COMPLETE_UPLOAD = gql(`
  mutation completeUpload($uploadId: String!) {
    completeUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const mutationCompleteUploadRecord = async (
  client: ApolloClient<object>,
  uploadId: string
): Promise<Upload> => {
  try {
    const response = await client.mutate<
      { completeUpload: UploadResponse }, // mutation response
      { uploadId: string } // mutation input
    >({
      mutation: COMPLETE_UPLOAD,
      variables: { uploadId },
    });

    if (!response?.data?.completeUpload.status.errorCode) {
      if (response.data?.completeUpload.upload) {
        return response.data?.completeUpload.upload;
      } else {
        throw new Error('Received invalid GraphQL upload response.');
      }
    } else {
      const error = new Error(response.data.completeUpload.status.errorDetails?.message as string);
      throw error;
    }
  } catch (e) {
    throw e;
  }
};

export default mutationCompleteUploadRecord;
