import { Radar } from 'ca-react-component-lib';
import { OptionCardProps } from './types';

const { Typography, Button, FontAwesome, useTheme } = Radar;

const OptionCard = ({ label, active, icon, onClick, iconType, disabled = false }: OptionCardProps) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={(theme) => ({
        py: 4,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${active ? theme.palette.primary.light : theme.palette.common.black}`,
        borderRadius: 2,
        gap: 1,
        backgroundColor: active ? theme.palette.primary.shade[300] : 'transparent',
        '&:hover': {
          backgroundColor: theme.palette.primary.shade[100],
        },
      })}
    >
      <FontAwesome icon={icon} type={iconType} size={theme.size.icon.huge} />
      <Typography sx={{ whiteSpace: 'nowrap' }}>{label}</Typography>
    </Button>
  );
};

export default OptionCard;
