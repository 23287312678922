import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Uploader;

const getUploaderFolders = (state: ApplicationState) => _getData(state).uploaderFolders;

const selectors = {
  getUploaderFolders,
};

export default selectors;
