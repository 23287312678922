// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUploadRecord } from 'src/hooks';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// store
import actions from 'src/store/UploadFiles/actions';
import uploadRecordActions from 'src/store/UploadRecord/actions';
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';

// graphql
// import { gql } from 'src/__generated__/gql';
import { UploadFilesReplaceInput, UploadFilesResponse, File } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';
import { FileType } from 'src/types/FileTypes';
import { FileStatusSettings } from 'src/types/FileStatus';

const REPLACE_UPLOAD_FILES = gql(`
  mutation replaceFiles($params: UploadFilesReplaceInput!) {
    replaceFiles(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        fileName
        folderName
        id
        path
        size
        status
        activeAt
        uploadedAt
      }
      signedUrl {
        fields {
          awsAccessKeyId
          contentType
          key
          policy
          signature
        }
        url
      }
    }
  }
`);

const useReplaceUploadFiles = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);
  const { uploadRecord } = useUploadRecord();

  const replaceUploadFiles = useCallback(
    ({
      uploadId,
      selectedFilesIds,
      newFiles,
      folderName,
    }: {
      uploadId: string;
      selectedFilesIds: { id: string }[];
      newFiles: FileType[];
      folderName: string;
    }) => {
      const handleReplaceUploadFiles = async () => {
        try {
          dispatch(actions.setReplaceUploadFilesLoading(true));

          const newFilesArr = newFiles.map((file: FileType) => ({
            path: `${folderName}/${file.name}`,
            size: file.file?.size.toString(),
          }));
          const params: UploadFilesReplaceInput = {
            files: [...newFilesArr, ...selectedFilesIds],
            uploadId: uploadId,
          };

          const response = await client.mutate<
            { replaceFiles: UploadFilesResponse }, // mutation response
            { params: UploadFilesReplaceInput } // mutation input
          >({
            mutation: REPLACE_UPLOAD_FILES,
            variables: { params },
          });

          if (!response?.data?.replaceFiles.status?.errorCode && response?.data?.replaceFiles) {
            let fileTypes: FileType[] = updateFilesWithGQLFiles(
              response?.data?.replaceFiles?.files as File[],
              uploadRecord?.files
            );

            const updatedFiles = fileTypes.map((file: FileType) => {
              let updatedFile: FileType | undefined;

              for (let i = 0; i < newFiles?.length; i++) {
                if (
                  file.status === FileStatusSettings.uploaded.value ||
                  file.status === FileStatusSettings.validated.value
                )
                  break;
                if (file.name === newFiles[i]?.name && file.folderName === folderName) {
                  updatedFile = { ...file, file: newFiles[i].file, status: newFiles[i].status };
                  return updatedFile;
                }
              }

              return updatedFile ? updatedFile : file;
            });

            const updatedUploadRecord: UploadRecord = {
              files: updatedFiles,
              status: response?.data?.replaceFiles?.upload?.status as UploadStatus,
              upload: response?.data?.replaceFiles.upload,
              signedUrl: response?.data?.replaceFiles.signedUrl,
            };

            const updatedUploadRecords = uploadRecords.map((upload: UploadRecord) =>
              upload?.upload?.id === updatedUploadRecord.upload?.id ? updatedUploadRecord : upload
            );
            dispatch(uploadRecordActions.setUploadRecords(updatedUploadRecords));
          } else {
            const error = new Error(
              response?.data?.replaceFiles?.status?.errorDetails?.message as string
            );

            dispatch(actions.setReplaceUploadFilesError(error));
          }
        } catch (error) {
          dispatch(actions.setReplaceUploadFilesError(error));
        } finally {
          dispatch(actions.setReplaceUploadFilesLoading(false));
        }
      };

      handleReplaceUploadFiles();
    },
    [dispatch, client, uploadRecord?.files, uploadRecords]
  );

  return {
    replaceUploadFiles,
  };
};

export default useReplaceUploadFiles;
