import React, { createContext, useState } from 'react';

// type
import { ShareDialogContextProps, ShareDialogProviderProps, ShareSettings } from './types';

const initialState: ShareDialogContextProps = {
  open: false,
  setOpen: () => {},
  setSettings: () => {},
  settings: undefined,
};

const ShareDialogContext = createContext(initialState);

const ShareDialogProvider = ({ children }: ShareDialogProviderProps) => {
  const [open, setOpen] = useState(initialState.open);
  const [settings, setSettings] = useState<ShareSettings | undefined>(initialState.settings);

  return (
    <ShareDialogContext.Provider
      value={{
        open,
        settings,
        setOpen: (open: boolean, settings?: ShareSettings) => {
          setOpen(open);
          setSettings(settings);
        },
        setSettings,
      }}
    >
      {children}
    </ShareDialogContext.Provider>
  );
};

export { ShareDialogContext, ShareDialogProvider };
