import { useCallback } from 'react';
import { useNavigate as useNavigateRouter } from 'react-router-dom';

// hooks
import useUploadRecord from './useUploadRecord';

// routes
import { MAIN_PATHS } from 'src/routes/paths';

// types
import { FolderName, Folders } from 'src/types/Folder';
import { UploadRecord } from 'src/types/UploadRecord';
import { FileType } from 'src/types/FileTypes';
import { FileStatusSettings } from 'src/types/FileStatus';

const getFolderNameWithMostRecentlyUploadedFile = (fileTypes: FileType[]): FolderName => {
  const uploadedFileTypes: FileType[] = fileTypes.filter(
    (fileType: FileType) =>
      fileType.status === FileStatusSettings.uploaded.value ||
      fileType.status === FileStatusSettings.validated.value
  );
  let mostRecentFileType: FileType | undefined;

  for (const uploadedFileType of uploadedFileTypes) {
    if (!mostRecentFileType || uploadedFileType.uploadedAt! > mostRecentFileType.uploadedAt!) {
      mostRecentFileType = uploadedFileType;
    }
  }

  if (mostRecentFileType?.folderName) {
    return mostRecentFileType?.folderName;
  }

  // Fallback value.
  return Folders.accessroad.name;
};

const useNavigate = () => {
  const navigateRouter = useNavigateRouter();
  const { uploadRecords } = useUploadRecord();

  const navigateTo = useCallback(
    (uploadId: string | undefined) => {
      if (!uploadId) {
        console.log(`Tried to navigate to invalid upload page ${uploadId}`);
        return;
      }

      const uploadRecord: UploadRecord | undefined = uploadRecords.find(
        (uploadRecord: UploadRecord) => uploadRecord.upload?.id === uploadId
      );

      if (!uploadRecord) {
        console.log(`Tried to navigate to invalid upload page ${uploadId}`);
        return;
      }

      const folderName: FolderName = getFolderNameWithMostRecentlyUploadedFile(uploadRecord.files);

      navigateRouter(`${MAIN_PATHS.upload}/${uploadId}/${folderName}`);
    },
    [navigateRouter, uploadRecords]
  );

  return {
    navigateTo,
  };
};

export default useNavigate;
