import request from '../request';

// endpoints
import endpoints from './endpoints';

const api = {
  // This is the profile companies.
  fetchUserCompanies: async () =>
    request(`${endpoints.getUserCompanies}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
