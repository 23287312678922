import { FileType } from './FileTypes';

export type FolderType = {
  name: FolderName;
  files: FileType[];
};

export type FolderName =
  | 'accessroad'
  | 'drone'
  | 'facade'
  | 'hotspot'
  | 'panorama'
  | 'rooftop'
  | 'tower';

export const Folders = {
  accessroad: {
    name: 'accessroad' as FolderName,
    label: 'Accessroad',
  },
  drone: {
    name: 'drone' as FolderName,
    label: 'Drone',
  },
  facade: {
    name: 'facade' as FolderName,
    label: 'Facade',
  },
  hotspot: {
    name: 'hotspot' as FolderName,
    label: 'Hotspot',
  },
  panorama: {
    name: 'panorama' as FolderName,
    label: 'Panorama',
  },
  rooftop: {
    name: 'rooftop' as FolderName,
    label: 'Rooftop',
  },
  tower: {
    name: 'tower' as FolderName,
    label: 'Tower',
  },
};
