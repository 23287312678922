export type PageSearchQueryResultResponse = {
  page_size: number;
  previous_page_cursor: string;
  next_page_cursor: string;
  total_count: number;
};

export type SitePageSearchQueryResultDataResponse = {
  access_code: any;
  access_instructions: any;
  address_city?: string;
  address_line1?: string;
  address_state?: string;
  aoi_code?: string;
  assessments?: any[];
  asset_class_id?: string;
  asset_class_name?: string;
  client_asset_id?: string;
  company_market?: string;
  elevation?: number;
  county_name?: number;
  date_created?: string;
  elevation_resolution?: string;
  flood_zone_designation?: string;
  id?: string;
  last_update_user_id?: string;
  latitude?: number;
  longitude?: number;
  mkt_id?: string;
  name?: string;
  owner_id?: string;
  owner_name?: string;
  owner_site_id?: string;
  ownership_type_id?: string;
  ownership_type_name?: string;
  postal_code?: string;
  region?: string;
  short_name?: string;
  site_user_roles?: string;
  site_list_member_id: string;
  status_id?: any[];
  structure_height_agl?: number;
  structure_type_id?: string;
  structure_type_name?: string;
  tenant_id?: string;
  tenant_name?: string;
};

export enum QC_RECORD_STATUS {
  PASSED = 'passed',
  FAILED = 'failed',
  UNKNOWM = 'unknown',
}

export type SiteAssessment = {
  clientSiteIdentifier?: string;
  date?: Date;
  powerProviderId?: string;
  fiberProviderId?: string;
  fiberHandHoleUri?: string;
  statusId?: string;
  storyUrl?: string;
  tenantId?: string;
  tilesetUrl?: string;
  modelGeoReferenced?: boolean;
  modelCenterAtOrigin?: boolean;
  id?: string;
  siteListMemberId?: string;
  dataReceived?: string;
  dataType?: string;
  aerotriangulationStart?: string;
  aerotriangulationEnd?: string;
  reconstructionStart?: string;
  reconstructionEnd?: string;
  cadprepFileStart?: string;
  cadprepFileEnd?: string;
  uploadedInternally?: string;
  uploadedToClientSite?: string;
  lastUserIdUpdated?: string;
  cadprepFileUrl?: string;
  threeMXLink?: string;
  clientId?: string;
  uavoperatorId?: string;
  cadprepUploadedInternally?: string;
  processingStatus?: string;
  storage?: string;
  dataTypeId?: string;
  processingWorkerId?: string;
  processingWorkerHostname?: string;
  processingWorkerHostnameIp?: string;
  processingWorkerHostnameExtIp?: string;
  images?: number;
  thumbnails?: number;
  thumbnailsFailed?: number;
  processingCodeVersion?: string;
  lastLogId?: string;
  lastLogTimestamp?: string;
  action?: string;
  settings?: any;
  storageFolder?: string;
  storageBucket?: string;
  virtualTourUrl?: string;
  assessmentTag?: string;
  viewerUrl?: string;
  lastQcRecordStatus?: QC_RECORD_STATUS;
  droneVendorCompanyId?: string;
  exif?: boolean;
  createdBy?: string;
  qcForms?: string;
  dateCreated?: Date;
  uavOperatorName?: string;
  flightAnalysisReportUrl?: string;
  files?: any[];
  vendor?: any;
  setting?: any;
  title?: string;
  description?: string;
};

export type SitePageSearchQueryResultData = {
  addressCity?: string;
  addressLine1?: string;
  addressState?: string;
  aoiCode?: string;
  assetClassName?: string;
  companyMarket?: string;
  companySiteId?: string;
  elevation?: number;
  elevationResolution?: string;
  floodZoneDesignation?: string;
  id?: string;
  lastUpdateUserId?: string;
  latitude?: number;
  longitude?: number;
  marketId?: string;
  ownerId?: string;
  ownerName?: string;
  ownerSiteId?: string;
  siteListMemberId: string;
  region?: string;
  siteAssessments?: SiteAssessment[];
  structureHeightAgl?: number;
  structureTypeName?: string;
  tenantId?: string;
  tenantName?: string;
  mktId?: string;
};

export type SitePageSearchQueryResultResponse = PageSearchQueryResultResponse & {
  sites: SitePageSearchQueryResultDataResponse[];
};
