import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';

// CA
import { Radar } from 'ca-react-component-lib';

// components
import ProfilePopover from './ProfilePopover';

// router
import { MAIN_PATHS } from 'src/routes/paths';

const { AppHeader, Box } = Radar;

const DefaultLayout = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        color: 'black',
        overflow: 'auto',
      }}
    >
      <AppHeader
        title={'Uploader'}
        onLogoClick={() => navigate(MAIN_PATHS.upload)}
        onClick={(path) => navigate(path)}
        rightComponents={<ProfilePopover />}
        appHeaderMenu={[
          {
            label: 'Uploads',
            path: MAIN_PATHS.upload,
          },
        ]}
        sx={{ mx: 6 }}
      />
      <Box
        sx={{
          marginTop: `${64}px`,
          width: '100%',
          px: 12,
          overflow: 'auto',
          overflowY: 'overlay',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DefaultLayout;
