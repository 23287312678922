import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { FileListInput, File, UpdateFilesStatusResponse } from 'src/__generated__/graphql';

// reducer
import actions from 'src/store/UploadFiles/actions';
import uploadRecordsActions from 'src/store/UploadRecord/actions';

// api
import mutationCancelUploadFiles from '../api/mutationCancelUploadFiles';
import mutationCompleteUploadRecord from '../../uploadRecord/api/mutationCompletedUploadRecord';
import mutationCancelUploadRecord from '../../uploadRecord/api/mutationCancelUploadRecord';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatusSettings } from 'src/types/UploadStatus';

const useCancelUploadFiles = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const cancelMultipleUploadFiles = useCallback(
    (params: FileListInput[], uploadRecords: UploadRecord[]) => {
      const handleCancelFiles = async () => {
        try {
          dispatch(actions.setCancelUploadFilesLoading(true));

          for (const fileListInput of params) {
            const response: UpdateFilesStatusResponse = await mutationCancelUploadFiles(
              client,
              fileListInput
            );

            if (!response?.status?.errorCode) {
              const uploadRecord: UploadRecord | undefined = uploadRecords.find(
                (uploadRecord: UploadRecord) => uploadRecord.upload?.id === fileListInput.uploadId
              );

              if (!uploadRecord) {
                return;
              }

              let updatedUploadRecord: UploadRecord = {
                ...uploadRecord,
                files: updateFilesWithGQLFiles(response?.files as File[], uploadRecord?.files),
              };

              const newStatus = getUploadRecordStatusFromFiles(updatedUploadRecord);

              if (uploadRecord.status !== newStatus && updatedUploadRecord.upload?.id) {
                if (newStatus === UploadStatusSettings.completed.value) {
                  await mutationCompleteUploadRecord(client, updatedUploadRecord.upload.id);
                  updatedUploadRecord.status = UploadStatusSettings.completed.value;
                } else if (newStatus === UploadStatusSettings.canceled.value) {
                  await mutationCancelUploadRecord(client, updatedUploadRecord.upload.id);
                  updatedUploadRecord.status = UploadStatusSettings.canceled.value;
                }
              }

              dispatch(uploadRecordsActions.setUploadRecord(updatedUploadRecord));
            }
          }
        } catch (error) {
          dispatch(actions.setCancelUploadFilesError(error));
        } finally {
          dispatch(actions.setCancelUploadFilesLoading(false));
        }
      };
      handleCancelFiles();
    },
    [client, dispatch]
  );

  const cancelUploadFiles = useCallback(
    (params: FileListInput, uploadRecords: UploadRecord[]) => {
      const handleCancelFiles = async () => {
        try {
          dispatch(actions.setCancelUploadFilesLoading(true));

          const response: UpdateFilesStatusResponse = await mutationCancelUploadFiles(
            client,
            params
          );

          if (!response?.status?.errorCode) {
            const uploadRecord: UploadRecord | undefined = uploadRecords.find(
              (uploadRecord: UploadRecord) => uploadRecord.upload?.id === params.uploadId
            );

            if (!uploadRecord) {
              return;
            }

            let updatedUploadRecord: UploadRecord = {
              ...uploadRecord,
              files: updateFilesWithGQLFiles(response?.files as File[], uploadRecord?.files),
            };

            const newStatus = getUploadRecordStatusFromFiles(updatedUploadRecord);

            if (uploadRecord.status !== newStatus && updatedUploadRecord.upload?.id) {
              if (newStatus === UploadStatusSettings.completed.value) {
                await mutationCompleteUploadRecord(client, updatedUploadRecord.upload.id);
                updatedUploadRecord.status = UploadStatusSettings.completed.value;
              } else if (newStatus === UploadStatusSettings.canceled.value) {
                await mutationCancelUploadRecord(client, updatedUploadRecord.upload.id);
                updatedUploadRecord.status = UploadStatusSettings.canceled.value;
              }
            }

            dispatch(uploadRecordsActions.setUploadRecord(updatedUploadRecord));
            dispatch(actions.setCancelUploadFilesSuccess(true));
            dispatch(actions.setCancelUploadFilesSuccess(false));
          } else {
            const error = new Error(response?.status?.errorDetails?.message as string);
            dispatch(actions.setCancelUploadFilesError(error));
          }
        } catch (error) {
          dispatch(actions.setCancelUploadFilesError(error));
        } finally {
          dispatch(actions.setCancelUploadFilesLoading(false));
        }
      };
      handleCancelFiles();
    },
    [client, dispatch]
  );

  return {
    cancelMultipleUploadFiles,
    cancelUploadFiles,
  };
};

export default useCancelUploadFiles;
