const apiUrl = process.env.REACT_APP_V2_API_ENDPOINT;

const endpoints = {
  getSite: `${apiUrl}/api/site/`,
  getSiteListMemberSearch: `${apiUrl}/api/site/`,
  getSiteListMember: `${apiUrl}/api/assessment/`,
  updateAssessment: `${apiUrl}/api/assessment/`,
  createSiteAssessmentStaging: `${apiUrl}/api/assessment/`,
};

export default endpoints;
