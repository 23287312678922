import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

//ca
import { Radar } from 'ca-react-component-lib';

// components
import { DeleteUploadDialogContent } from './DeleteUploadDialogContent';

// hooks
import { useDeleteDialog, useUploadRecord } from 'src/hooks';

// paths
import { MAIN_PATHS } from 'src/routes/paths';

const { CustomDialog, LoadingButton } = Radar;

export const DeleteUploadDialog = () => {
  const { open, setOpen, settings } = useDeleteDialog();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { deleteUploadRecord, deleteUploadRecordLoading, deleteUploadRecordSuccess } =
    useUploadRecord();

  const handleDeleteUpload = useCallback(() => {
    if (settings && settings.id) {
      deleteUploadRecord(settings.id);
    }
  }, [settings, deleteUploadRecord]);

  useEffect(() => {
    if (deleteUploadRecordSuccess) {
      setOpen(false, undefined);
      if (pathname.split('/').some((item) => item === 'upload')) {
        navigate(MAIN_PATHS.upload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUploadRecordSuccess]);

  return (
    <CustomDialog
      title={'Delete Upload'}
      open={open}
      showCloseButton={true}
      disableCloseButton={deleteUploadRecordLoading}
      disableEscapeKeyDown={deleteUploadRecordLoading}
      content={
        <DeleteUploadDialogContent
          title={settings?.name || ''}
          totalFilesCount={settings?.totalFilesCount || 0}
        />
      }
      closeButtonText="Cancel"
      buttons={
        <LoadingButton
          loading={deleteUploadRecordLoading}
          sx={{ mt: 12 }}
          variant={'contained'}
          color={'error'}
          onClick={handleDeleteUpload}
        >
          Yes, Delete
        </LoadingButton>
      }
      onClose={() => setOpen(false)}
    />
  );
};
