import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/client';

// hooks
import { useProfile } from 'src/hooks';

// reducer
import actions from 'src/store/UploadRecord/actions';
import { UploadRecord } from 'src/types/UploadRecord';

// api
import queryUploadRecords from '../api/queryUploadRecords';
import queryUploadRecord from '../api/queryUploadRecord';
import mutationCancelUploadRecord from '../api/mutationCancelUploadRecord';

// types
import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';

// utils
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';

const cancelUploadRecord = async (client, uploadId, uploadRecord: UploadRecord) => {
  try {
    await mutationCancelUploadRecord(client, uploadId);
  } catch (e) {
    console.error(`Silent error message ${uploadRecord.upload?.name}:`, e);
  }
};

const updateWithStatus = (uploadRecord: UploadRecord, status: UploadStatus) => ({
  ...uploadRecord,
  status,
});

const handleSynchronizeUploadStatusByFiles = async (
  uploadRecords: UploadRecord[],
  client
): Promise<UploadRecord[]> => {
  const updatedUploadRecords: UploadRecord[] = [];

  for (const uploadRecord of uploadRecords) {
    const { upload } = uploadRecord;

    if (!upload) {
      continue;
    }

    const totalFilesCount = upload.totalFilesCount || 0;
    const uploadedFilesCount = upload.uploadedFilesCount || 0;
    let hasChange = false;

    if (uploadRecord.status === UploadStatusSettings.completed.value) {
      if (totalFilesCount !== 0 && uploadedFilesCount === 0) {
        await cancelUploadRecord(client, upload.id, uploadRecord);
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.canceled.value)
        );
        hasChange = true;
      }
    }
    if (uploadRecord.status === UploadStatusSettings.active.value) {
      if (uploadedFilesCount !== 0) {
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.completed.value)
        );
      } else {
        await cancelUploadRecord(client, upload.id, uploadRecord);
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.canceled.value)
        );
      }
      hasChange = true;
    }
    if (uploadRecord.status === UploadStatusSettings.paused.value) {
      if (uploadedFilesCount !== 0) {
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.completed.value)
        );
      } else {
        await cancelUploadRecord(client, upload.id, uploadRecord);
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.canceled.value)
        );
      }
      hasChange = true;
    }
    if (uploadRecord.status === UploadStatusSettings.pendingUploading.value) {
      if (uploadedFilesCount !== 0) {
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.completed.value)
        );
      } else {
        await cancelUploadRecord(client, upload.id, uploadRecord);
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.canceled.value)
        );
      }
      hasChange = true;
    }
    if (uploadRecord.status === UploadStatusSettings.created.value) {
      if (uploadedFilesCount !== 0) {
        updatedUploadRecords.push(
          updateWithStatus(uploadRecord, UploadStatusSettings.completed.value)
        );
        hasChange = true;
      }
    }
    if (!hasChange) {
      updatedUploadRecords.push(uploadRecord);
    }
  }

  return updatedUploadRecords;
};

const useGetUploadRecords = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { profileId } = useProfile();

  const getUploadRecords = useCallback(
    (uploadId: string | undefined) => {
      const handleGetUploadRecords = async () => {
        try {
          dispatch(actions.setFetchUploadRecordsLoading(true));

          // fetch upload record by id
          let newUploadRecord: UploadRecord | undefined = undefined;
          if (uploadId) {
            newUploadRecord = await queryUploadRecord(client, uploadId);
          }

          // update upload records if previously fetched a new upload record by id.
          let uploadRecords: UploadRecord[] = await queryUploadRecords(client, profileId);
          uploadRecords = uploadRecords.map((uploadRecord: UploadRecord) => {
            if (newUploadRecord && uploadRecord.upload?.id === uploadId) {
              return newUploadRecord;
            }
            return uploadRecord;
          });

          // when application close unexpected, the application have no time to update or finish some upload.
          // we have to update the upload records based on the files.
          // uploadRecords = await handleSynchronizeUploadStatusByFiles(uploadRecords, client);

          uploadRecords = uploadRecords.map((uploadRecord: UploadRecord) => {
            uploadRecord.status = getUploadRecordStatusFromFiles(uploadRecord);
            return uploadRecord;
          });

          dispatch(actions.setUploadRecords(uploadRecords));
        } catch (e) {
          dispatch(actions.setFetchUploadRecordsError(e));
        } finally {
          dispatch(actions.setFetchUploadRecordsLoading(false));
        }
      };

      handleGetUploadRecords();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [client, profileId, dispatch]
  );

  return {
    getUploadRecords,
  };
};

export default useGetUploadRecords;
