// hooks
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import siteActions from 'src/store/Site/actions';

// selectors
import siteSelectors from 'src/store/Site/selectors';

const useSite = () => {
  const siteListMember = useSelector(siteSelectors.getSiteListMember);
  const siteListMemberLoading = useSelector(siteSelectors.getSiteListMemberLoading);
  const siteListMemberError = useSelector(siteSelectors.getSiteListMemberError);

  const updateAssessmentError = useSelector(siteSelectors.getUpdateAssessmentError);

  const dispatch = useDispatch();

  const fetchSiteListMember = useCallback(
    (siteListMemberId: string) => {
      dispatch(siteActions.fetchSiteListMember(siteListMemberId));
    },
    [dispatch]
  );

  const updateAssessment = useCallback(
    (assessmentId: string, action: string) => {
      dispatch(siteActions.updateAssessment(assessmentId, action));
    },
    [dispatch]
  );

  const clearState = useCallback(() => {
    dispatch(siteActions.clearState());
  }, [dispatch]);

  return {
    siteListMember,
    siteListMemberLoading,
    siteListMemberError,
    fetchSiteListMember,
    updateAssessment,
    updateAssessmentError,
    clearState,
  };
};

export default useSite;
