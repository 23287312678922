import request from '../request';
import endpoints from './endpoints';

const api = {
  fetchSite: async (siteId: string) =>
    request(`${endpoints.getSite}${siteId}/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
  fetchSiteListMemberSearch: async (siteSearchQuery: any) =>
    request(`${endpoints.getSiteListMemberSearch}?Page=1&Search=${siteSearchQuery?.text}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  fetchSiteListMember: async (siteListMemberId: string) =>
    request(`${endpoints.getSiteListMember}${siteListMemberId}/site/`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),

  createSiteAssessmentStaging: async (payload: any, siteId: string) =>
    request(`${endpoints.createSiteAssessmentStaging}${siteId}/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
  updateAssessment: async (payload: any, assessmentId: string) =>
    request(`${endpoints.updateAssessment}${assessmentId}/`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }),
};

export default api;
