// ca
import { Radar } from 'ca-react-component-lib';

//components
import { UploadControlButton, UploadIcon, UploadProgressBar } from 'src/components';

//types
import { ControlsProps } from '../types';
import { FileStatusSettings } from 'src/types/FileStatus';

// hooks
import { useIgnoreConfirmationDialog, useUploader } from 'src/hooks';
import { useCallback, useMemo } from 'react';

// utils
import getUploadRecordFilesCountByStatus from 'src/utils/getUploadRecordFilesCountByStatus';
import filterFoldersFilesByStatus from 'src/utils/filterFoldersFilesByStatus';
import { sortedFolders } from 'src/pages/Upload/constants';
import { getUploadRecordProgress } from "src/store/UploadRecord/util";

// Radar DS
const { useTheme, Stack, Box } = Radar;

const PendingUploading = ({ uploadRecord }: ControlsProps) => {
  const theme = useTheme();
  const { startUploaderFolder } = useUploader();
  const { setOpen, setSettings } = useIgnoreConfirmationDialog();

  const failedFiles = useMemo(
    () => getUploadRecordFilesCountByStatus(FileStatusSettings.failed.value, uploadRecord),
    [uploadRecord]
  );

  const handleStartUploader = useCallback(() => {
    startUploaderFolder(uploadRecord);
  }, [startUploaderFolder, uploadRecord]);

  const handleStartUploadClick = () => {
    if (failedFiles > 0) {
      const sortedFolder = sortedFolders(uploadRecord?.files);
      const foldersWithFailedFiles = filterFoldersFilesByStatus(
        sortedFolder,
        FileStatusSettings.failed.value
      );

      setOpen(true);
      setSettings({
        failedFilesCount: failedFiles,
        folders: foldersWithFailedFiles,
        uploadRecord: uploadRecord,
      });
    } else {
      handleStartUploader();
      setOpen(false);
    }
  };

  const progress = getUploadRecordProgress(uploadRecord)?.progress;

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.text.disabled,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <UploadIcon variant="folder" />
          {uploadRecord?.upload?.name || ''}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          Ready
          <UploadControlButton variant="start" onClick={() => handleStartUploadClick()} />
        </Box>
      </Box>
      <UploadProgressBar progress={progress} barColor={''} />
    </Stack>
  );
};

export default PendingUploading;
