// ca
import { Radar } from 'ca-react-component-lib';

// hooks
import { useNavigate } from 'react-router';

// components
import { UploadControlButton, UploadIcon, UploadProgressBar } from 'src/components';

// types
import { ControlsProps } from '../types';
import { Folders } from 'src/types/Folder';

// routes
import { MAIN_PATHS } from 'src/routes/paths';

// Radar DS
const { useTheme, Stack, Box } = Radar;

const Failed = ({ uploadRecord }: ControlsProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.text.disabled,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <UploadIcon variant="failed" />
          {uploadRecord?.upload?.name}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          Failed
          <UploadControlButton
            variant="info"
            onClick={() => {
              navigate(
                `${MAIN_PATHS.upload}/${uploadRecord?.upload?.id}/${Folders.accessroad.name}`
              );
            }}
          />
        </Box>
      </Box>
      <UploadProgressBar
        progress={uploadRecord.progress || 0}
        barColor={theme.palette.primary.shade[200]}
      />
    </Stack>
  );
};

export default Failed;
