import { SitePageSearchQueryResultData } from 'src/services/site/types';

export enum SiteSearchQueryActionType {
  SET_QUICK_SEARCH_LOADING = 'SiteSearchQuery@SET_QUICK_SEARCH_LOADING',
  SET_QUICK_SEARCH_QUERY = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY',
  SET_QUICK_SEARCH_QUERY_RESULT = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY_RESULT',
  SET_QUICK_SEARCH_QUERY_CLEAR = 'SiteSearchQuery@SET_QUICK_SEARCH_QUERY_CLEAR',
}

export type SiteSearchQueryState = {
  isQuickSearchLoading: boolean;
  quickSearchQueryResult?: any;
};

export type SiteSearchQueryPayload = {
  isQuickSearchLoading?: boolean;
  quickSearchQuery?: any;
  quickSearchQueryResult?: any;
};

export type SiteSearchQueryAction = {
  payload?: SiteSearchQueryPayload;
  type: SiteSearchQueryActionType;
};

export type PageSearchQueryResult = {
  from: number;
  page: number;
  perPage: number;
  to: number;
  total: number;
};

export type SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => SiteSearchQueryState;

export type SitePageSearchQueryResult = PageSearchQueryResult & {
  data: SitePageSearchQueryResultData[];
};
