// ca
import { Radar } from 'ca-react-component-lib';

// types
import { FileType } from 'src/types/FileTypes';
import { UploadRecord } from 'src/types/UploadRecord';
import { FileStatusSettings } from 'src/types/FileStatus';

// components
import { Folder } from 'src/components/Folder';
import InvalidFileRow from './InvalidFileRow';

// constants
import { HEIGHT_OF_FIVE_ROWS_AND_GAPS, WIDTH_OF_FILE_ROW, WIDTH_OF_FOLDER_ROW } from './constants';

// hooks
import {
  useIgnoreConfirmationDialog,
  useUploader,
  useUploadFiles,
  useUploadRecord,
} from 'src/hooks';
import { useCallback, useEffect } from 'react';

// utils
import createFileInput from 'src/utils/createFileInput';

// radar
const { CustomDialog, Stack, Typography, useTheme, Box, Scrollbar, LoadingButton } = Radar;

export const IgnoreConfirmationDialog = () => {
  const theme = useTheme();
  const { settings, setOpen, open } = useIgnoreConfirmationDialog();
  const { cancelUploadFilesLoading, cancelUploadFilesSuccess, cancelUploadFiles } =
    useUploadFiles();
  const { uploadRecords } = useUploadRecord();
  const { startUploaderFolder } = useUploader();

  const handleContinueButtonClick = useCallback(() => {
    if (settings?.uploadRecord && settings?.uploadRecord?.upload?.id) {
      cancelUploadFiles(
        {
          uploadId: settings?.uploadRecord?.upload?.id,
          files: createFileInput(
            settings.uploadRecord?.files.filter(
              (fileType: FileType) => fileType.status === FileStatusSettings.failed.value
            )
          ),
        },
        uploadRecords
      );
    }
  }, [cancelUploadFiles, settings, uploadRecords]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (cancelUploadFilesSuccess) {
      const uploadRecordToStart = uploadRecords.find(
        (ur: UploadRecord) => ur.upload?.id === settings?.uploadRecord?.upload?.id
      );
      if (uploadRecordToStart) {
        startUploaderFolder(uploadRecordToStart);
      }
      setOpen(false, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelUploadFilesSuccess]);

  return (
    <CustomDialog
      title="Ignore incorrect files?"
      content={
        <Stack sx={{ mt: 10 }}>
          <Stack
            display={'inline'}
            sx={{ maxWidth: `${WIDTH_OF_FOLDER_ROW}px`, mx: 'auto' }}
            textAlign={'center'}
          >
            <Typography display={'inline'} textAlign={'center'}>
              Are you sure you want to ignore and remove
            </Typography>
            <br />
            <Typography display={'inline'} textAlign={'center'} color={theme.palette.error.main}>
              {` ${settings?.failedFilesCount} invalid file${
                settings?.failedFilesCount && settings?.failedFilesCount > 1 ? 's' : ''
              } `}
            </Typography>
            <Typography display={'inline'} textAlign={'center'}>
              that {settings?.failedFilesCount && settings?.failedFilesCount > 1 ? 'were ' : 'was '}
              selected?
            </Typography>
          </Stack>
          {settings?.folders && (
            <Scrollbar
              sx={{
                width: `${
                  settings.folders?.length === 1 ? WIDTH_OF_FILE_ROW : WIDTH_OF_FOLDER_ROW
                }px`,
                maxHeight: `${HEIGHT_OF_FIVE_ROWS_AND_GAPS}px`,
                overflowY: 'auto',
                overflowX: 'hidden',
                mx: 'auto',
                mb: 12,
                mt: 5,
              }}
            >
              <Stack gap={2}>
                {settings?.folders.length === 1 &&
                  settings?.folders[0].files.map((file: FileType) => (
                    <Box
                      key={file.id}
                      sx={{
                        width: `${WIDTH_OF_FILE_ROW}px`,
                        pr: settings?.folders && settings?.folders[0]?.files.length > 5 ? 4 : 0,
                      }}
                    >
                      <InvalidFileRow file={file} />
                    </Box>
                  ))}
                {settings?.folders.length > 1 &&
                  settings?.folders.map((folder, key) => (
                    <Box
                      key={key}
                      sx={{
                        width: `${WIDTH_OF_FOLDER_ROW}px`,
                        pr: settings?.folders && settings?.folders?.length > 5 ? 4 : 0,
                      }}
                    >
                      <Folder label={`${folder.name} (${folder.files.length})`} active />
                    </Box>
                  ))}
              </Stack>
            </Scrollbar>
          )}
        </Stack>
      }
      open={open}
      onClose={() => handleClose()}
      showCloseButton
      buttons={
        <LoadingButton
          variant="contained"
          onClick={() => handleContinueButtonClick()}
          loading={cancelUploadFilesLoading}
        >
          Yes Continue Uploading
        </LoadingButton>
      }
    />
  );
};
