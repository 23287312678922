import { takeLatest, put, call } from 'redux-saga/effects';

// service
import { fetchSiteListMemberSearch } from 'src/services/site';

// reducer
import {
  SiteSearchQueryAction,
  SiteSearchQueryActionType,
  SitePageSearchQueryResult,
} from './types';

// actions
import actions from './actions';

export function* setQuickSearchQuery({ payload }: SiteSearchQueryAction) {
  yield put(actions.setQuickSearchLoading(true));

  try {
    const sitePageSearchQueryResult: SitePageSearchQueryResult = yield call(
      fetchSiteListMemberSearch,
      payload!.quickSearchQuery
    );

    yield put(actions.setQuickSearchQueryResult(sitePageSearchQueryResult));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(actions.setQuickSearchLoading(false));
  }
}

const sagas = [takeLatest(SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY, setQuickSearchQuery)];

export default sagas;
