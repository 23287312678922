import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';
import { Cancel } from './controlButtons';
import { ControlButtonsHandlerType } from './types';

export const WIDGET_WIDTH = 375;
export const ACCORDION_SUMMARY_HEIGHT = 43;
export const CONTROLS_BOX_MAX_HEIGHT = 192; // equals to 4 UploadProgressControl with spacing between

// TODO: uncomment when Resume All functionality is implemented
// const ResumeButtonHandler: ControlButtonsHandlerType = {
//   isIncluded: (statuses: UploadStatus[]) => statuses.includes(UploadStatusSettings.paused.value),
//   renderButton: () => <Resume />,
// };

// TODO: uncomment when Pause All functionality is implemented
// const PauseButtonHandler: ControlButtonsHandlerType = {
//   isIncluded: (statuses: UploadStatus[]) => statuses.includes(UploadStatusSettings.active.value),
//   renderButton: () => <Pause />,
// };

const CancelButtonHandler: ControlButtonsHandlerType = {
  isIncluded: (statuses: UploadStatus[]) =>
    statuses.includes(UploadStatusSettings.active.value) ||
    statuses.includes(UploadStatusSettings.paused.value) ||
    statuses.includes(UploadStatusSettings.failed.value),
  renderButton: () => <Cancel />,
};

export const ControlButtonsHandler: ControlButtonsHandlerType[] = [
  // ResumeButtonHandler,
  // PauseButtonHandler,
  CancelButtonHandler,
];
