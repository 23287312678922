function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT = '';

export const MAIN_PATHS = {
  login: path(ROOT, '/login'),
  upload: path(ROOT, '/upload'),
  unknown: path(ROOT, '/404'),
};
