import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// graphql
import { FileListInput, UpdateFilesStatusResponse } from 'src/__generated__/graphql';

// reducer
import actions from 'src/store/UploadFiles/actions';
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';
import uploadRecordActions from 'src/store/UploadRecord/actions';

// types
import { FileStatusSettings } from 'src/types/FileStatus';
import { UploadRecord } from 'src/types/UploadRecord';

const PAUSE_UPLOAD_FILES = gql(`
  mutation pauseFiles($params: FileListInput!) {
    pauseFiles(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      files {
        fileName
        folderName
        id
        path
        size
        status
        activeAt
        uploadedAt
      }
    }
  }
`);

const usePauseUploadFiles = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);

  const pauseFilesUploadFiles = useCallback(
    (params: FileListInput) => {
      const handlePauseFiles = async () => {
        try {
          dispatch(actions.setPauseUploadFilesLoading(true));

          const response = await client.mutate<
            { pauseFiles: UpdateFilesStatusResponse }, // mutation response
            { params: FileListInput } // mutation input
          >({
            mutation: PAUSE_UPLOAD_FILES,
            variables: { params },
          });

          if (!response?.data?.pauseFiles.status.errorCode && response?.data?.pauseFiles) {
            let updatedUploadRecord: UploadRecord | undefined = uploadRecords.find(
              (uploadRecord) => uploadRecord.upload?.id === params.uploadId
            );
            if (updatedUploadRecord) {
              const fileTypes = updatedUploadRecord.files.map((fileType) => ({
                ...fileType,
                status: FileStatusSettings.paused.value,
              }));

              updatedUploadRecord = {
                ...updatedUploadRecord,
                files: fileTypes,
              };

              const updatedUploadRecords: UploadRecord[] = uploadRecords.map(
                (uploadRecord: UploadRecord) =>
                  updatedUploadRecord &&
                  uploadRecord?.upload?.id === updatedUploadRecord?.upload?.id
                    ? updatedUploadRecord
                    : uploadRecord
              );
              dispatch(uploadRecordActions.setUploadRecords(updatedUploadRecords));
            }
          } else {
            const error = new Error(
              response?.data?.pauseFiles.status.errorDetails?.message as string
            );

            dispatch(actions.setPauseUploadFilesError(error));
          }
        } catch (error) {
          dispatch(actions.setPauseUploadFilesError(error));
        } finally {
          dispatch(actions.setPauseUploadFilesLoading(false));
        }
      };
      handlePauseFiles();
    },
    [client, dispatch, uploadRecords]
  );

  return {
    pauseFilesUploadFiles,
  };
};

export default usePauseUploadFiles;
