/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation deleteFiles($params: FileListInput!) {\n    deleteFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n": types.DeleteFilesDocument,
    "\n  mutation pauseFiles($params: FileListInput!) {\n    pauseFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n    }\n  }\n": types.PauseFilesDocument,
    "\n  mutation replaceFiles($params: UploadFilesReplaceInput!) {\n    replaceFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n": types.ReplaceFilesDocument,
    "\n  mutation createUpload($upload: UploadInput!) {\n    createUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n": types.CreateUploadDocument,
    "\n  mutation DeleteUpload($uploadId: String!) {\n    deleteUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n": types.DeleteUploadDocument,
    "\n  query getUpload($uploadId: String!) {\n    getUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        size\n        status\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n": types.GetUploadDocument,
    "\n  mutation pauseUpload($uploadId: String!) {\n    pauseUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n": types.PauseUploadDocument,
    "\n  mutation renameUpload($upload: UploadInput) {\n    renameUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n": types.RenameUploadDocument,
    "\n  mutation sendUploadFileList($params: UploadFileListInput!) {\n    sendUploadFileList(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      signedUrl{\n        url\n        fields {\n          contentType\n          key\n          awsAccessKeyId\n          policy\n          signature\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        status\n        size\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n    \n    }\n  }\n": types.SendUploadFileListDocument,
    "\n  mutation startUpload($uploadId: String!) {\n    startUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n": types.StartUploadDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteFiles($params: FileListInput!) {\n    deleteFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteFiles($params: FileListInput!) {\n    deleteFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation pauseFiles($params: FileListInput!) {\n    pauseFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation pauseFiles($params: FileListInput!) {\n    pauseFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation replaceFiles($params: UploadFilesReplaceInput!) {\n    replaceFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation replaceFiles($params: UploadFilesReplaceInput!) {\n    replaceFiles(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        fileName\n        folderName\n        id\n        path\n        size\n        status\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createUpload($upload: UploadInput!) {\n    createUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createUpload($upload: UploadInput!) {\n    createUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUpload($uploadId: String!) {\n    deleteUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUpload($uploadId: String!) {\n    deleteUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getUpload($uploadId: String!) {\n    getUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        size\n        status\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUpload($uploadId: String!) {\n    getUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        size\n        status\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n      signedUrl {\n        fields {\n          awsAccessKeyId\n          contentType\n          key\n          policy\n          signature\n        }\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation pauseUpload($uploadId: String!) {\n    pauseUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation pauseUpload($uploadId: String!) {\n    pauseUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation renameUpload($upload: UploadInput) {\n    renameUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation renameUpload($upload: UploadInput) {\n    renameUpload(upload: $upload) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation sendUploadFileList($params: UploadFileListInput!) {\n    sendUploadFileList(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      signedUrl{\n        url\n        fields {\n          contentType\n          key\n          awsAccessKeyId\n          policy\n          signature\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        status\n        size\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n    \n    }\n  }\n"): (typeof documents)["\n  mutation sendUploadFileList($params: UploadFileListInput!) {\n    sendUploadFileList(params: $params) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      signedUrl{\n        url\n        fields {\n          contentType\n          key\n          awsAccessKeyId\n          policy\n          signature\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n      files {\n        id\n        path\n        status\n        size\n        folderName\n        fileName\n        activeAt\n        uploadedAt\n      }\n    \n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation startUpload($uploadId: String!) {\n    startUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation startUpload($uploadId: String!) {\n    startUpload(uploadId: $uploadId) {\n      status {\n        errorCode\n        errorDetails {\n          message\n        }\n      }\n      upload {\n        id\n        externalId\n        groupId\n        name\n        userId\n        status\n        totalFilesCount\n        extraContent\n        lastModified\n        pendingUploadingFilesCount\n        canceledFilesCount\n        pausedFilesCount\n        uploadedFilesCount\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;