import { User } from 'src/types/User';
import { LabelValue } from 'src/types/Root';

export enum ProfileActionType {
  SET_PROFILE_USER = 'Profile@SET_PROFILE_USER',
  SET_COMPANIES = 'Profile@SET_COMPANIES',
  SET_LOADING = 'Profile@SET_LOADING',
  SET_ERROR = 'Profile@SET_ERROR',
  CLEAR = 'Profile@CLEAR',
}

export type ProfileState = {
  userData: User;
  companies: LabelValue[];
  loading: boolean;
  error?: Error;
};

export type ProfilePayload = {
  userData?: User;
  loading?: boolean;
  companies?: LabelValue[];
  error?: Error;
};

export type ProfileAction = {
  payload?: ProfilePayload;
  type: ProfileActionType;
};

export type ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ProfileState;
