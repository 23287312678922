import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import { DefaultLayout } from 'src/layouts';

// components
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import { LoadingScreen } from 'src/components';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '',
      element: (
        <AuthGuard>
          <DefaultLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/upload" replace /> },
        { path: '/upload', element: <Uploads /> },
        { path: '/upload/:uploadId/:folderName', element: <Upload /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS
const NotFound = Loadable(lazy(() => lazyRetry(() => import('src/pages/Page404'), 'NotFound')));
const Uploads = Loadable(lazy(() => lazyRetry(() => import('src/pages/Uploads'), 'Uploads')));
const Upload = Loadable(lazy(() => lazyRetry(() => import('src/pages/Upload'), 'Upload')));
const Login = Loadable(lazy(() => lazyRetry(() => import('src/pages/Login'), 'Login')));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport, name): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
      );

      // try to import the component
      componentImport()
        .then((component) => {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
          resolve(component);
        })
        .catch((error) => {
          if (!hasRefreshed) {
            // not been refreshed yet
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
            return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
        });
    } catch (error) {
      reject(error); // Default error behaviour as already tried refresh
    }
  });
