import {
  SiteSearchQueryAction,
  SiteSearchQueryActionType,
  SitePageSearchQueryResult,
} from './types';

const actions = {
  setQuickSearchLoading: (isQuickSearchLoading: boolean): SiteSearchQueryAction => ({
    payload: { isQuickSearchLoading },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_LOADING,
  }),

  setQuickSearchQuery: (quickSearchQuery: any): SiteSearchQueryAction => ({
    payload: { quickSearchQuery },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY,
  }),

  setQuickSearchQueryResult: (
    quickSearchQueryResult: SitePageSearchQueryResult
  ): SiteSearchQueryAction => ({
    payload: { quickSearchQueryResult },
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_RESULT,
  }),

  setQuickSearchQueryClear: (): SiteSearchQueryAction => ({
    type: SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_CLEAR,
  }),
};

export default actions;
