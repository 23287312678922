import { useCallback } from 'react';

// ca
import { Radar } from 'ca-react-component-lib';

// constants
import { MINIMUM_FIELD_WIDTH } from './constants';

// hooks
import { useUploadRecord } from 'src/hooks';

// graphql
import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';

// Radar
const {
  Box,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  // DatePicker,
  // AdapterMoment,
  // LocalizationProvider,
} = Radar;

export const UploadFilters = () => {
  const { setUploaderFilter, uploaderFilter } = useUploadRecord();

  const filteredUploadStatus = Object.values(UploadStatusSettings).filter(
    (status) => status.label !== UploadStatusSettings.failed.label
  );

  const getRenderValue = useCallback((selected) => {
    if (selected.length > 0) {
      return selected
        .map((uploadStatus: UploadStatus) => {
          if (uploadStatus === UploadStatusSettings.created.value) {
            return UploadStatusSettings.created.label;
          } else if (uploadStatus === UploadStatusSettings.pendingUploading.value) {
            return UploadStatusSettings.pendingUploading.label;
          } else if (uploadStatus === UploadStatusSettings.active.value) {
            return UploadStatusSettings.active.label;
          } else if (uploadStatus === UploadStatusSettings.paused.value) {
            return UploadStatusSettings.paused.label;
          } else if (uploadStatus === UploadStatusSettings.completed.value) {
            return UploadStatusSettings.completed.label;
          } else if (uploadStatus === UploadStatusSettings.canceled.value) {
            return UploadStatusSettings.canceled.label;
          }
          return uploadStatus;
        })
        .join(', ');
    }

    return 'All';
  }, []);

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <TextField
        label="Search"
        onChange={(e) =>
          setUploaderFilter({
            ...uploaderFilter,
            search: e.target.value === '' ? undefined : e.target.value,
          })
        }
        inputProps={{ placeholder: 'Search' }}
        InputLabelProps={{ shrink: true }}
      />
      <FormControl sx={{ width: `${MINIMUM_FIELD_WIDTH}px` }} size={'small'}>
        <InputLabel shrink={true}>Status</InputLabel>
        <Select
          label="Status"
          variant="outlined"
          notched
          displayEmpty
          multiple
          defaultValue={[]}
          renderValue={(selected) => getRenderValue(selected)}
          onChange={(e) => {
            if (e.target.value.length <= 0) {
              setUploaderFilter({ ...uploaderFilter, status: undefined });
            } else {
              setUploaderFilter({
                ...uploaderFilter,
                status: e.target.value,
              });
            }
          }}
        >
          {filteredUploadStatus.map((status, i) => (
            <MenuItem
              key={i}
              value={status.value}
              sx={{
                '&.Mui-selected': { backgroundColor: 'unset !important' },
                p: 0,
              }}
            >
              <Checkbox checked={uploaderFilter?.status?.includes(status.value) || false} />
              <ListItemText
                sx={{
                  span: {
                    color: `${status.menuTextColor} !important`,
                  },
                }}
                primary={status.label}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* TODO: uncomment when filtering by date functionality's implemented */}
      {/* <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disableFuture
          label="Creation Date"
          value={uploaderFilter?.creationDate}
          format={'MM-DD-YYYY'}
          onChange={(newValue: any) => {
            setUploaderFilter({ ...uploaderFilter, creationDate: newValue._d });
          }}
          renderInput={({ inputProps, ...restParams }: any) => (
            <TextField
              {...restParams}
              inputProps={{
                ...inputProps,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: `${MINIMUM_FIELD_WIDTH}px` }}
            />
          )}
        />
      </LocalizationProvider> */}
      {/* TODO: uncomment when filtering by company functionality's implemented */}
      {/* <FormControl sx={{ width: `${MINIMUM_FIELD_WIDTH}px` }} size={'small'}>
        <InputLabel>Company</InputLabel>
        <Select
          label="Company"
          variant="outlined"
          defaultValue={0}
          onChange={(e) => setUploaderFilter({ ...uploaderFilter, company: e.target.value })}
        >
          <MenuItem value={0} />
          <MenuItem value={1}>All</MenuItem>
          <MenuItem value={2}>Complete</MenuItem>
          <MenuItem value={3}>Failed</MenuItem>
        </Select>
      </FormControl> */}
      {/* TODO: uncomment when filtering by asset functionality's implemented */}
      {/* <FormControl sx={{ width: `${MINIMUM_FIELD_WIDTH}px` }} size={'small'}>
        <InputLabel>Asset</InputLabel>
        <Select
          label="Asset"
          variant="outlined"
          defaultValue={0}
          onChange={(e) => setUploaderFilter({ ...uploaderFilter, asset: e.target.value })}
        >
          <MenuItem value={''} />
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>Complete</MenuItem>
          <MenuItem value={2}>Failed</MenuItem>
        </Select>
      </FormControl> */}
    </Box>
  );
};
