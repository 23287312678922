import { Radar } from 'ca-react-component-lib';

import { UploadAlertProps } from './types';
import { UploadAlertTypes } from 'src/types/UploadAlert';

import { useUploadSettingsDialog } from 'src/hooks';

const { Alert } = Radar;

export const UploadAlert = ({
  severity,
  title,
  description,
  variant,
  alertType,
  action,
}: UploadAlertProps) => {
  const { setOpen } = useUploadSettingsDialog();

  return (
    <Alert
      severity={
        UploadAlertTypes[alertType]?.severity ? UploadAlertTypes[alertType]?.severity : severity
      }
      title={UploadAlertTypes[alertType]?.title ? UploadAlertTypes[alertType]?.title : title}
      description={
        UploadAlertTypes[alertType]?.description
          ? UploadAlertTypes[alertType]?.description
          : description
      }
      action={action}
      onClick={() => (alertType === 'complete' ? setOpen(true) : undefined)}
      variant={
        UploadAlertTypes[alertType]?.variant ? UploadAlertTypes[alertType]?.variant : variant
      }
    />
  );
};
