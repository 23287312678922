import { useLocation } from 'react-router';
import { useMemo } from 'react';

// types
import { FolderName, Folders } from 'src/types/Folder';

const useUrlQueryParams = () => {
  const { pathname } = useLocation();

  const uploadId = useMemo(() => {
    const params = pathname.split('/');

    if (params.length === 0) {
      return undefined;
    }
    if (!params[1]) {
      return undefined;
    }
    if (params[1] !== 'upload') {
      return undefined;
    }
    if (params[2]) {
      return params[2];
    }
  }, [pathname]);

  const folderName: FolderName = useMemo(() => {
    const params = pathname.split('/');

    if (params.length === 0) {
      return Folders.accessroad.name;
    }
    if (!params[1]) {
      return Folders.accessroad.name;
    }
    if (params[1] !== 'upload') {
      return Folders.accessroad.name;
    }
    if (params[3] && Object.values(Folders).some((folder) => folder.name === params[3])) {
      return params[3] as FolderName;
    }

    return Folders.accessroad.name;
  }, [pathname]);

  return {
    uploadId,
    folderName,
  };
};

export default useUrlQueryParams;
