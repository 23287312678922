import { LabelHandlersType } from './types';
import { UploadRecord } from 'src/types/UploadRecord';
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';
import { UploadStatusSettings } from 'src/types/UploadStatus';

// CA
import Created from './Created';
import Active from './Active';

const CreatedHandler: LabelHandlersType = {
  isMatched: (uploadRecords: UploadRecord[]) => uploadRecords.length === 0,
  renderLabel: () => <Created />,
};

const ActiveHandler: LabelHandlersType = {
  isMatched: (uploadRecords: UploadRecord[]) =>
    uploadRecords.filter(
      (uploadRecord: UploadRecord) =>
        getUploadRecordStatusFromFiles(uploadRecord) === UploadStatusSettings.active.value
    ).length > 0,
  renderLabel: () => <Active />,
};

const CompletedHandler: LabelHandlersType = {
  isMatched: (uploadRecords: UploadRecord[]) => uploadRecords.length > 0,
  renderLabel: () => <Created />,
};

export const LabelHandlers = [CreatedHandler, ActiveHandler, CompletedHandler];
