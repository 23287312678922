import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// reducer
import actions from 'src/store/UploadRecord/actions';
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// graphql
import { UploadInput, UploadResponse } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import useUploadRecordUtils from './useUploadRecordUtils';

const RENAME_UPLOAD = gql(`
  mutation renameUpload($upload: UploadInput) {
    renameUpload(upload: $upload) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const useRenameUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);
  const { updateUploadRecordsByUpload } = useUploadRecordUtils();

  const renameUploadRecord = useCallback(
    (params) => {
      const handleRenameUpload = async () => {
        try {
          dispatch(actions.setRenameUploadRecordLoading(true));

          const editingUploadRecord = uploadRecords.find(
            (ur: UploadRecord) => ur.upload?.id === params.uploadId
          );

          if (!editingUploadRecord) {
            console.error('Error on rename upload');
            dispatch(
              actions.setRenameUploadRecordError(new Error('Something went wrong, try again.'))
            );
            return;
          }

          const response = await client.mutate<
            { renameUpload: UploadResponse }, // mutation response
            { upload: UploadInput } // mutation input
          >({
            mutation: RENAME_UPLOAD,
            variables: {
              upload: {
                externalId: editingUploadRecord?.upload?.externalId as string,
                groupId: editingUploadRecord?.upload?.groupId as string,
                name: params.name,
                userId: editingUploadRecord?.upload?.userId as string,
                uploadId: params.uploadId,
              },
            },
          });

          if (!response.data?.renameUpload.status.errorCode) {
            const editedUploadRecord = uploadRecords.find(
              (ur: UploadRecord) => ur.upload?.id === response.data?.renameUpload.upload?.id
            );

            if (editedUploadRecord) {
              updateUploadRecordsByUpload(response.data?.renameUpload.upload, uploadRecords);

              dispatch(actions.setRenameUploadRecordSuccess(true));
              dispatch(actions.setRenameUploadRecordSuccess(undefined));
            }
          } else {
            const error = new Error(
              response?.data?.renameUpload.status.errorDetails?.message as string
            );

            console.error('Error on rename upload', error);

            dispatch(
              actions.setRenameUploadRecordError(new Error('Something went wrong, try again.'))
            );
          }
        } catch (error) {
          dispatch(actions.setRenameUploadRecordError(error));
        } finally {
          dispatch(actions.setRenameUploadRecordLoading(false));
        }
      };

      handleRenameUpload();
    },
    [client, dispatch, updateUploadRecordsByUpload, uploadRecords]
  );

  return {
    renameUploadRecord,
  };
};

export default useRenameUploadRecord;
