import { useMemo, useState, useEffect } from 'react';

import { useAuth } from 'src/hooks';

// ca
import { Radar } from 'ca-react-component-lib';

// Radar DS
const {
  useTheme,
  Stack,
  Typography,
  useForm,
  yupResolver,
  Yup,
  InputAdornment,
  IconButton,
  FontAwesome,
  LoadingButton,
  Alert,
  FormProvider,
  RHFTextField,
  CircularProgress,
} = Radar;

export const LoginForm = () => {
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const { login, isLoading, error } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = useMemo(
    () => ({
      remember: true,
      email: '',
      password: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    login(data.email, data.password);
  };

  useEffect(() => {
    if (error) {
      reset();
      if (error?.message?.includes('Email')) {
        setError('afterSubmit', error);
      } else {
        setError('afterSubmit', {
          name: 'Error',
          message: 'The credentials are incorrect, please try again.',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const errorMessage = useMemo(
    () => (errors?.afterSubmit?.message ? errors?.afterSubmit.message : ''),
    [errors]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" color={theme.palette.common.black}>
        Sign in to Consilience{' '}
      </Typography>
      <Stack spacing={4} sx={{ mt: 6 }}>
        {errorMessage && <Alert severity="error" title={errorMessage} />}
        <RHFTextField
          name="email"
          id="email"
          label="Email address"
          size="medium"
          InputProps={{
            autoComplete: 'username',
          }}
        />
        <RHFTextField
          name="password"
          label="Password"
          size="medium"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            autoComplete: 'current-password',
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <FontAwesome
                    icon={showPassword ? 'eye' : 'eye-slash'}
                    type="solid"
                    size={theme.size.icon.large}
                    color={theme.palette.text.secondary}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingPosition="start"
        startIcon={isLoading ? <CircularProgress size={16} /> : <></>}
        sx={{ backgroundColor: theme.palette.primary.dark, mt: 6 }}
      >
        {isLoading ? 'Authenticating' : 'Login'}
      </LoadingButton>
    </FormProvider>
  );
};
