import { ApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { File, UploadFilesResponse } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';
import { FileType } from 'src/types/FileTypes';
import { FileStatusSettings } from 'src/types/FileStatus';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';
import createFileInput from 'src/utils/createFileInput';
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';

// api
import mutationCancelUploadFiles from '../../uploadFiles/api/mutationCancelUploadFiles';

export const GET_UPLOAD = gql(`
  query getUpload($uploadId: String!) {
    getUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        id
        path
        size
        status
        folderName
        fileName
        activeAt
        uploadedAt
      }
      signedUrl {
        fields {
          awsAccessKeyId
          contentType
          key
          policy
          signature
        }
        url
      }
    }
  }
`);

const queryUploadRecord = async (
  client: ApolloClient<object>,
  uploadId: string
): Promise<UploadRecord> => {
  try {
    const response = await client.query<{ getUpload: UploadFilesResponse }, { uploadId: String }>({
      query: GET_UPLOAD,
      variables: { uploadId: uploadId },
    });
    if (!response.data.getUpload.status.errorCode) {
      let fileTypes: FileType[] = updateFilesWithGQLFiles(
        response?.data?.getUpload?.files as File[],
        []
      );

      let newUploadRecord: UploadRecord = {
        files: fileTypes,
        status: response?.data?.getUpload?.upload?.status as UploadStatus,
        upload: response?.data?.getUpload.upload,
        signedUrl: response?.data?.getUpload.signedUrl,
      };

      newUploadRecord.status = getUploadRecordStatusFromFiles(newUploadRecord);

      const params = {
        uploadId: uploadId,
        files: createFileInput(
          fileTypes.filter((fileType) => fileType.status === FileStatusSettings.canceled.value)
        ),
      };

      await mutationCancelUploadFiles(client, params);

      return newUploadRecord;
    } else {
      const error = new Error(response.data.getUpload.status.errorDetails?.message as string);
      throw error;
    }
  } catch (e) {
    throw e;
  }
};

export default queryUploadRecord;
