// components
import Create from './variants/Create';
import Edit from './variants/Edit';

// types
import { DialogContentHandlersType } from './types';
import { UploadSettingsVariantType } from 'src/provider/UploadSettingsDialogProvider/types';

const EditHandler: DialogContentHandlersType = {
  isMatched: (variant: UploadSettingsVariantType) => variant === 'edit',
  renderVariantContent: () => <Edit />,
};

const CreateHandler: DialogContentHandlersType = {
  isMatched: (variant: UploadSettingsVariantType) => variant === 'create',
  renderVariantContent: () => <Create />,
};

const DialogContentHandlers = [EditHandler, CreateHandler];

export default DialogContentHandlers;
