import { useDispatch } from 'react-redux';
import { Radar } from 'ca-react-component-lib';
import moment from 'moment';

// reducer
import actions from 'src/store/UploadRecord/actions';

// hooks
import { useSite, useSiteSearchQuery, useUploadSettingsDialog } from 'src/hooks';

// constants
import { LastQCRecordStatuses } from './constants';

// utils
import { getAutocompleteText } from 'src/utils/getAutocompleteText';

// types
import { SiteAssessment } from 'src/types/Site';

const {
  Autocomplete,
  Typography,
  Box,
  TextField,
  InputAdornment,
  CircularProgress,
  useTheme,
  Stack,
  Tooltip,
} = Radar;

const AssessmentSelection = ({ search }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isQuickSearchLoading } = useSiteSearchQuery();
  const { siteListMemberLoading, siteListMember } = useSite();
  const { settings, setSettings } = useUploadSettingsDialog();

  return (
    <Autocomplete
      id="assessment"
      noOptionsText={getAutocompleteText(search, isQuickSearchLoading)}
      getOptionLabel={(option) => {
        if (option && option.assessmentTag) {
          return `${option.assessmentTag} (${moment(new Date(option.dateCreated)).format(
            'D MMM, YYYY'
          )})`;
        }
        return '';
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: LastQCRecordStatuses[option.lastQcRecordStatus].textColor(theme),
              }}
            >
              {option.assessmentTag}{' '}
              {`(${moment(new Date(option.dateCreated)).format('D MMM, YYYY')})`}
            </Typography>
            {LastQCRecordStatuses[option.lastQcRecordStatus].label && (
              <Tooltip title={LastQCRecordStatuses[option.lastQcRecordStatus].label}>
                <Box
                  sx={{
                    borderRadius: '50%',
                    height: '8px',
                    width: '8px',
                    backgroundColor:
                      LastQCRecordStatuses[option.lastQcRecordStatus].textColor(theme),
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        </li>
      )}
      renderInput={(restParams) => (
        <TextField
          {...restParams}
          placeholder="None"
          label="Capture to Replace"
          InputLabelProps={{ shrink: true }}
          onChange={() => dispatch(actions.setCreateUploadRecordError(undefined))}
          InputProps={{
            ...restParams.InputProps,
            endAdornment: (
              <>
                {isQuickSearchLoading || siteListMemberLoading ? (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={theme.size.icon.huge} />{' '}
                  </InputAdornment>
                ) : (
                  restParams.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
      value={
        settings?.assessment ||
        siteListMember?.siteAssessments?.find(
          (assessment: SiteAssessment) => assessment.id === settings?.externalId
        ) ||
        null
      }
      options={settings?.asset?.siteAssessments ?? []}
      onChange={(_: any, newValue: any) => {
        dispatch(actions.setCreateUploadRecordError(undefined));
        setSettings({
          ...settings,
          assessment: newValue,
        });
      }}
      isOptionEqualToValue={(option, value) => option === value}
      disabled={!settings?.asset || settings.variant === 'edit'}
    />
  );
};

export default AssessmentSelection;
