import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.UploadFiles;

const getUploadedUploadFilesLoading = (state: ApplicationState) =>
  _getData(state).uploadedUploadFilesLoading;

const getUploadedUploadFilesError = (state: ApplicationState) =>
  _getData(state).uploadedUploadFilesError;

const getDeleteUploadFilesLoading = (state: ApplicationState) =>
  _getData(state).deleteUploadFilesLoading;

const getDeleteUploadFilesError = (state: ApplicationState) =>
  _getData(state).deleteUploadFilesError;

const getReplaceUploadFilesLoading = (state: ApplicationState) =>
  _getData(state).replaceUploadFilesLoading;

const getReplaceUploadFilesError = (state: ApplicationState) =>
  _getData(state).replaceUploadFilesError;

const getPauseUploadFilesLoading = (state: ApplicationState) =>
  _getData(state).pauseUploadFilesLoading;

const getPauseUploadFilesError = (state: ApplicationState) => _getData(state).pauseUploadFilesError;

const getCancelUploadFilesLoading = (state: ApplicationState) =>
  _getData(state).cancelUploadFilesLoading;

const getCancelUploadFilesSuccess = (state: ApplicationState) =>
  _getData(state).cancelUploadFilesSuccess;

const getCancelUploadFilesError = (state: ApplicationState) =>
  _getData(state).cancelUploadFilesError;

const selectors = {
  getUploadedUploadFilesLoading,
  getUploadedUploadFilesError,
  getDeleteUploadFilesLoading,
  getDeleteUploadFilesError,
  getReplaceUploadFilesLoading,
  getReplaceUploadFilesError,
  getCancelUploadFilesError,
  getCancelUploadFilesSuccess,
  getCancelUploadFilesLoading,
  getPauseUploadFilesError,
  getPauseUploadFilesLoading,
};

export default selectors;
