import { UploadIconVariantType } from 'src/types/UploadIcons';

export type UploadIconProps = {
  variant?: UploadIconVariantType;
  size?: UploadIconSize;
};

export enum UploadIconSize {
  SMALL = 20,
  MEDIUM = 24,
}
