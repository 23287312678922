import { useCallback, useEffect, useMemo } from 'react';

// graphql
import { UploadInput } from 'src/__generated__/graphql';

import { createSiteAssessmentStaging } from 'src/services/site';

// ca
import { Radar } from 'ca-react-component-lib';

// components
import DialogContent from './DialogContent';

//actions
import uploadActions from 'src/store/UploadRecord/actions';

// hooks
import { useUploadRecord, useUploadSettingsDialog, useProfile, useNavigate } from 'src/hooks';
import { useDispatch } from 'react-redux';

// Radar DS
const { Stack, CustomDialog, LoadingButton, KeyDownHandler } = Radar;

export const UploadSettingsDialog = () => {
  const { navigateTo } = useNavigate();
  const { profileId } = useProfile();
  const dispatch = useDispatch();

  const { open, setOpen, settings } = useUploadSettingsDialog();
  const {
    createUploadRecordLoading,
    createUploadRecord,
    createUploadRecordSuccess,
    renameUploadRecord,
    renameUploadRecordLoading,
    renameUploadRecordSuccess,
  } = useUploadRecord();

  const buttonText = useCallback(() => {
    if (settings?.variant === 'start') {
      return 'Start Uploading';
    } else if (settings?.variant === 'create') {
      return 'Continue';
    } else {
      return 'Save Changes';
    }
  }, [settings?.variant]);

  const disabled = useMemo(() => {
    if (settings?.variant === 'edit') {
      return !settings.uploadName;
    }
    if (!settings?.asset) {
      return true;
    }
    if (!settings?.uploadName) {
      return true;
    }
    if (settings?.assessment && !settings.capture) {
      return false;
    }
    if (!settings?.assessment && settings.capture) {
      return false;
    }
    return true;
  }, [settings]);

  // handle create upload record.
  const handleCreateUploadRecord = useCallback(async () => {
    if (settings?.uploadName && settings.asset?.id) {
      const name = settings?.uploadName;
      let externalId = settings?.assessment?.id || '';
      const groupId = settings.asset?.id;
      const siteListMemberId = settings.asset?.siteListMemberId;
      const asset = settings.asset?.companySiteId;
      const capture = settings?.capture;

      if (capture && siteListMemberId && groupId) {
        const assessmentId = await createSiteAssessmentStaging(siteListMemberId, capture, name);
        externalId = assessmentId;
      }
      const upload: UploadInput = {
        name: name,
        externalId: externalId,
        groupId: groupId,
        userId: profileId,
        extraContent: JSON.stringify(`asset:${asset}`),
      };

      createUploadRecord(upload);
    }
  }, [settings, createUploadRecord, profileId]);

  const handleEditUploadRecord = useCallback(() => {
    if (settings?.uploadName && settings?.uploadId) {
      const upload = {
        name: settings?.uploadName,
        uploadId: settings?.uploadId,
      };

      renameUploadRecord(upload);
    }
  }, [settings?.uploadName, settings?.uploadId, renameUploadRecord]);

  // When create upload record successful or failed.
  useEffect(() => {
    // When upload record created with success.

    if (createUploadRecordSuccess) {
      // reset.
      setOpen(false, undefined);

      navigateTo(createUploadRecordSuccess.id);
    }
  }, [createUploadRecordSuccess, navigateTo, setOpen]);

  // When rename upload record successful
  useEffect(() => {
    if (renameUploadRecordSuccess) {
      // reset
      setOpen(false, undefined);
    }
  }, [renameUploadRecordSuccess, setOpen]);

  const handleCloseDialog = () => {
    setOpen(false);
    dispatch(uploadActions.setCreateUploadRecordError(undefined));
  };

  return (
    <>
      <KeyDownHandler disabled={!open} keyCode={'Enter'} onKeyDown={handleCreateUploadRecord} />
      <CustomDialog
        title={'Upload Settings'}
        open={open}
        closeButtonText="Cancel"
        disableCloseButton={createUploadRecordLoading || renameUploadRecordLoading}
        disableEscapeKeyDown={createUploadRecordLoading || renameUploadRecordLoading}
        onClose={handleCloseDialog}
        content={
          <Stack sx={{ py: 8, px: 4, width: '100%' }} spacing={4}>
            <DialogContent />
          </Stack>
        }
        buttons={
          <LoadingButton
            disabled={disabled}
            variant="contained"
            onClick={
              (settings?.variant === 'create' && handleCreateUploadRecord) ||
              (settings?.variant === 'edit' && handleEditUploadRecord)
            }
            loading={createUploadRecordLoading || renameUploadRecordLoading}
          >
            {buttonText()}
          </LoadingButton>
        }
      />
    </>
  );
};
