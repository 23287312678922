import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// reducer
import store from "src/store";
import actions from 'src/store/UploadRecord/actions';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { UploadResponse, Upload } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';

const DELETE_UPLOAD: any = gql(`
  mutation DeleteUpload($uploadId: String!) {
    deleteUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const useDeleteUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const deleteUploadRecord = useCallback(
    (uploadId: string) => {
      const handleDeleteUpload = async () => {
        try {
          dispatch(actions.setDeleteUploadRecordLoading(true));

          const response = await client.mutate<
            { deleteUpload: UploadResponse }, // mutation response
            { uploadId: String } // mutation input
          >({
            mutation: DELETE_UPLOAD,
            variables: { uploadId },
          });

          if (!response.data?.deleteUpload.status.errorCode) {
            const upload: Upload = response.data?.deleteUpload.upload as Upload;
            
            const uploadRecord: UploadRecord | undefined = store.getState().UploadRecord.uploadRecords.find(
              (uploadRecord: UploadRecord) => uploadRecord.upload?.id === upload.id
            );

            if (!uploadRecord) {
              return;
            }
            
            dispatch(actions.setUploadRecord(uploadRecord, "REMOVE"));
            dispatch(actions.setDeleteUploadRecordSuccess(upload));
            dispatch(actions.setDeleteUploadRecordSuccess(undefined));
          } else {
            const error = new Error(
              response?.data?.deleteUpload.status.errorDetails?.message as string
            );

            dispatch(actions.setDeleteUploadRecordError(error));
          }
        } catch (error) {
          dispatch(actions.setDeleteUploadRecordError(error));
        } finally {
          dispatch(actions.setDeleteUploadRecordLoading(false));
        }
      };

      handleDeleteUpload();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, dispatch
    ]
  );

  return {
    deleteUploadRecord,
  };
};

export default useDeleteUploadRecord;
