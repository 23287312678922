import React from 'react';

import { Radar } from 'ca-react-component-lib';

// types
import { NewUploadButtonProps } from './types';

// radar
const { NewItemButton } = Radar;

export const NewUploadButton = ({ onClick }: NewUploadButtonProps) => (
  <NewItemButton itemName="upload" onClick={onClick} />
);
