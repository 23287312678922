import { useMemo } from 'react';

// ca
import { Radar } from 'ca-react-component-lib';

// types
import { UploadStatusProps } from './types';
import { UploadStatusSettings } from 'src/types/UploadStatus';

const { Box, Typography } = Radar;

export const UploadStatus = ({ status }: UploadStatusProps) => {
  const uploadStatusSettings = useMemo(() => {
    const uploadStatusSettings = Object.values(UploadStatusSettings).find(
      (uploadStatusSetting) => uploadStatusSetting.value === status
    );

    return uploadStatusSettings || UploadStatusSettings.created;
  }, [status]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {uploadStatusSettings.icon}
      <Typography variant={'buttonMedium'} color={uploadStatusSettings.textColor}>
        {uploadStatusSettings.label}
      </Typography>
    </Box>
  );
};
