import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// reducer
import actions from 'src/store/UploadRecord/actions';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { Upload, UploadInput, UploadResponse } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatusSettings } from 'src/types/UploadStatus';

const CREATE_UPLOAD = gql(`
  mutation createUpload($upload: UploadInput!) {
    createUpload(upload: $upload) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const useCreateUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const createUploadRecord = useCallback(
    (upload: UploadInput) => {
      const handleCreateUpload = async () => {
        try {
          dispatch(actions.setCreateUploadRecordLoading(true));

          const response = await client.mutate<
            { createUpload: UploadResponse }, // mutation response
            { upload: UploadInput } // mutation input
          >({
            mutation: CREATE_UPLOAD,
            variables: { upload },
          });

          if (!response.data?.createUpload.status.errorCode) {
            const upload: Upload = response.data?.createUpload.upload as Upload;

            const uploadRecord: UploadRecord = {
              files: [],
              status: UploadStatusSettings.created.value,
              upload: upload,
              signedUrl: undefined,
            };

            dispatch(actions.setUploadRecord(uploadRecord));
            dispatch(actions.setCreateUploadRecordSuccess(upload));
            dispatch(actions.setCreateUploadRecordSuccess(undefined));
          } else {
            const error = new Error(
              response?.data?.createUpload.status.errorDetails?.message as string
            );

            console.error('Error on create upload', error);

            dispatch(actions.setCreateUploadRecordError(error));
          }
        } catch (error) {
          console.error('Error on graphql create upload', error);
          dispatch(
            actions.setCreateUploadRecordError(new Error('Something went wrong, try again later.'))
          );
        } finally {
          dispatch(actions.setCreateUploadRecordLoading(false));
        }
      };

      handleCreateUpload();
    },
    [client, dispatch]
  );

  return {
    createUploadRecord,
  };
};

export default useCreateUploadRecord;
