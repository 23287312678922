import React, { createContext, useState } from 'react';

// type
import { DeleteDialogContextProps, DeleteDialogProviderProps, DeleteSettings } from './types';

const initialState: DeleteDialogContextProps = {
  open: false,
  setOpen: () => { },
  setSettings: () => { },
  settings: undefined,
};

const DeleteDialogContext = createContext(initialState);

const DeleteDialogProvider = ({ children }: DeleteDialogProviderProps) => {
  const [open, setOpen] = useState(initialState.open);
  const [settings, setSettings] = useState<DeleteSettings | undefined>(initialState.settings);

  return (
    <DeleteDialogContext.Provider
      value={{
        open,
        settings,
        setOpen: (open: boolean, currentSettings?: DeleteSettings) => {
          setOpen(open);

          // if we are going to close
          if (!open) {
            setTimeout(() => {
              setSettings(initialState.settings);
            }, 1000);
          } else {
            setSettings(currentSettings);
          }
        },
        setSettings,
      }}
    >
      {children}
    </DeleteDialogContext.Provider>
  );
};

export { DeleteDialogContext, DeleteDialogProvider };
