import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';

// reducer
import actions from 'src/store/UploadRecord/actions';

// graphql
import { Upload } from 'src/__generated__/graphql';

const useUploadRecordUtils = () => {
  const dispatch = useDispatch();

  /**
   * Update upload records by GQL Upload type.
   *
   * Used by:
   * - usePauseUploaderRecord
   */
  const updateUploadRecordsByUpload = useCallback(
    (newUpload: Upload | undefined | null, uploadRecords: UploadRecord[]) => {
      if (!newUpload) {
        console.error('Response Upload object undefined.');
        return;
      }

      dispatch(
        actions.setUploadRecords(
          uploadRecords.map((uploadRecord: UploadRecord) => {
            if (uploadRecord.upload?.id === newUpload.id) {
              return {
                ...uploadRecord,
                files: uploadRecord.files,
                upload: newUpload,
                status: newUpload.status as UploadStatus,
              };
            } else {
              return uploadRecord;
            }
          })
        )
      );
    },
    [dispatch]
  );

  return {
    updateUploadRecordsByUpload,
  };
};

export default useUploadRecordUtils;
