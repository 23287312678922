import { put, takeLatest, call } from 'redux-saga/effects';

// actions
import authenticationActions from './actions';
import profileActions from 'src/store/Profile/actions';
import uploadRecordActions from 'src/store/UploadRecord/actions';

// services
import * as authServices from 'src/services/auth';
import * as userServices from 'src/services/user';
import * as profileServices from 'src/services/profile';

// storage
import {
  setAuthToken,
  setAuthRefreshToken,
  clearAuthToken,
  clearAuthRefreshToken,
  getAuthToken,
} from 'src/storage/AuthToken';

// types
import { LoginRequestResponse } from 'src/services/auth/types';
import { AuthenticationAction, AuthenticationActionType, AuthenticationError } from './types';
import { User } from 'src/types/User';
import { LabelValue } from 'src/types/Root';

function* updateProfile() {
  try {
    const userData: User = yield call(userServices.fetchProfile);
    const companies: LabelValue[] = yield call(profileServices.fetchCompanies);

    yield put(uploadRecordActions.watcherSetUploadRecord());
    yield put(profileActions.setProfileUser(userData));
    yield put(profileActions.setCompanies(companies));
  } catch (e) {
    throw e;
  }
}

export function* login({ payload }: AuthenticationAction) {
  if (!payload) {
    return;
  }

  const { email, password } = payload;

  if (!email || !password) {
    return;
  }

  yield put(authenticationActions.setLoading(true));
  try {
    // request login
    const loginRequestResponse: LoginRequestResponse = yield call(
      authServices.login,
      email,
      password
    );

    // set auth refresh token
    yield call(setAuthRefreshToken, loginRequestResponse.auth_data.refresh_token);

    // set auth token
    yield call(setAuthToken, loginRequestResponse.auth_data.access_token);

    // update profile
    yield call(updateProfile);

    // set authenticated to true
    yield put(authenticationActions.setAuthenticated());
  } catch ({ message }) {
    yield put(authenticationActions.setError({ message, name: AuthenticationError.LOGIN }));
  } finally {
    yield put(authenticationActions.setLoading(false));
  }
}

export function* initialize() {
  try {
    const authToken: string = yield call(getAuthToken);

    if (authToken) {
      // set authenticated to true
      yield put(authenticationActions.setAuthenticated());

      // update profile
      yield call(updateProfile);
    }

    yield put(authenticationActions.setInitialized(true));
  } catch (e) {
    // It is possible that on update profile auth token is invalid.
    // Then it is necessary to logout.
    yield call(logout);
    yield put(authenticationActions.setError(new Error('Session expired')));
  }
  yield put(authenticationActions.setInitialized(true));
}

export function* logout() {
  // clear auth token
  yield call(clearAuthToken);
  yield call(clearAuthRefreshToken);

  // clear state
  yield put(authenticationActions.clearState());

  // if reached here, then the application is already.
  // Because of the clear above, it is necessary to set initialized to true.
  yield put(authenticationActions.setInitialized(true));
}

const sagas = [
  takeLatest(AuthenticationActionType.LOGIN, login),
  takeLatest(AuthenticationActionType.LOGOUT, logout),
  takeLatest(AuthenticationActionType.INITIALIZE, initialize),
];

export default sagas;
