import {
  UploadFilesAction,
  UploadFilesActionType,
  UploadFilesPayload,
  UploadFilesReducer,
  UploadFilesState,
} from './types';

export const initialState: UploadFilesState = {
  // uploaded upload files
  uploadedUploadFilesLoading: false,
  uploadedUploadFilesError: undefined,

  // delete upload files
  deleteUploadFilesLoading: false,
  deleteUploadFilesError: undefined,

  // replace upload files
  replaceUploadFilesLoading: false,
  replaceUploadFilesError: undefined,

  // pause upload files
  pauseUploadFilesLoading: false,
  pauseUploadFilesError: undefined,

  // cancel upload files
  cancelUploadFilesLoading: false,
  cancelUploadFilesSuccess: undefined,
  cancelUploadFilesError: undefined,
};

const setUploadedUploadFilesLoading: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  fetchUploadFilesLoading: !!payload?.uploadedUploadFilesLoading,
});

const setUploadedUploadFilesError: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  fetchUploadFilesError: payload ? payload?.uploadedUploadFilesError : undefined,
});

const setDeleteUploadFilesLoading: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  deleteUploadFilesLoading: !!payload?.deleteUploadFilesLoading,
});

const setDeleteUploadFilesError: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  deleteUploadFilesError: payload ? payload?.deleteUploadFilesError : undefined,
});

const setReplaceUploadFilesLoading: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  replaceUploadFilesLoading: !!payload?.replaceUploadFilesLoading,
});

const setReplaceUploadFilesError: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  replaceUploadFilesError: payload ? payload?.replaceUploadFilesError : undefined,
});

const setCancelUploadFilesLoading: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  cancelUploadFilesLoading: !!payload?.cancelUploadFilesLoading,
});

const setCancelUploadFilesSuccess: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  cancelUploadFilesSuccess: !!payload?.cancelUploadFilesSuccess,
});

const setCancelUploadFilesError: UploadFilesReducer = (
  state: UploadFilesState,
  payload?: UploadFilesPayload
) => ({
  ...state,
  cancelUploadFilesError: payload ? payload?.cancelUploadFilesError : undefined,
});

const clearState: UploadFilesReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<UploadFilesActionType, UploadFilesReducer>([
  [UploadFilesActionType.SET_UPLOADED_UPLOAD_FILES_LOADING, setUploadedUploadFilesLoading],
  [UploadFilesActionType.SET_UPLOADED_UPLOAD_FILES_ERROR, setUploadedUploadFilesError],
  [UploadFilesActionType.SET_DELETE_UPLOAD_FILES_LOADING, setDeleteUploadFilesLoading],
  [UploadFilesActionType.SET_DELETE_UPLOAD_FILES_ERROR, setDeleteUploadFilesError],
  [UploadFilesActionType.SET_REPLACE_UPLOAD_FILES_LOADING, setReplaceUploadFilesLoading],
  [UploadFilesActionType.SET_REPLACE_UPLOAD_FILES_ERROR, setReplaceUploadFilesError],
  [UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_LOADING, setCancelUploadFilesLoading],
  [UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_SUCCESS, setCancelUploadFilesSuccess],
  [UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_ERROR, setCancelUploadFilesError],

  [UploadFilesActionType.CLEAR, clearState],
]);

const reducer = (state: UploadFilesState = initialState, action: UploadFilesAction) => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);

  return state;
};

export default reducer;
