import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Profile;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const getUserData = (state: ApplicationState) => _getData(state).userData;

const getCompanies = (state: ApplicationState) => _getData(state).companies;

const getFirstName = (state: ApplicationState) => getUserData(state).firstName;

const getProfileId = (state: ApplicationState) => getUserData(state).id;

const getLastName = (state: ApplicationState) => getUserData(state).lastName;

const getDisplayName = (state: ApplicationState) =>
  `${getFirstName(state)} ${getLastName(state)}`;

const selectors = {
  getUserData,
  getCompanies,
  getProfileId,
  getError,
  isLoading,
  getDisplayName,
};

export default selectors;
