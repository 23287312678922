import { useCallback } from 'react';

// reducer
import actions from 'src/store/UploadRecord/actions';
import store from 'src/store';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { UploadResponse } from 'src/__generated__/graphql';

// hooks
import { useDispatch } from 'react-redux';

// types
import { UploadRecord } from 'src/types/UploadRecord';

const ACTIVE_UPLOAD = gql(`
  mutation startUpload($uploadId: String!) {
    startUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const useStartUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const startUploadRecord = useCallback(
    (
      uploadId: string
    ) => {
      const handleStartUpload = async () => {
        try {
          dispatch(actions.setStartUploadRecordLoading(true));

          const response = await client.mutate<
            { startUpload: UploadResponse }, // mutation response
            { uploadId: string } // mutation input
          >({
            mutation: ACTIVE_UPLOAD,
            variables: { uploadId },
          });

          if (!response?.data?.startUpload.status.errorCode) {
            const uploadRecord: UploadRecord | undefined = store
              .getState()
              .UploadRecord.uploadRecords.find(
                (uploadRecord: UploadRecord) => uploadRecord.upload?.id === uploadId
              );

            if (uploadRecord) {
              dispatch(
                actions.setUploadRecord({
                  ...uploadRecord,
                  upload: response.data?.startUpload.upload,
                })
              );
            }
          } else {
            const error = new Error(
              response?.data?.startUpload.status.errorDetails?.message as string
            );

            dispatch(actions.setStartUploadRecordError(error));
          }
        } catch (error) {
          dispatch(actions.setStartUploadRecordError(error));
        } finally {
          dispatch(actions.setStartUploadRecordLoading(false));
        }
      };

      handleStartUpload();
    },
    [client, dispatch]
  );

  return {
    startUploadRecord,
  };
};

export default useStartUploadRecord;
