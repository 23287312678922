import { Radar } from 'ca-react-component-lib';

// component
import { FileTypeIcon } from '../FileTypeIcon';
import { FileStatus } from 'src/components/FileStatus';

// constants
import { FILE_NAME_MAX_WIDTH } from './constants';

// types
import { InvalidFileRowProps } from './types';

const { Stack, useTheme, Typography } = Radar;

const InvalidFileRow = ({ file }: InvalidFileRowProps) => {
  const theme = useTheme();
  const { extension, name, size, status } = file;

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        pl: 4,
        pr: 12,
        height: `${theme.size.component.large}px`,
        borderRadius: 2,
        backgroundColor: theme.palette.error.shade[100],
      }}
    >
      <Stack flexDirection="row" gap={8}>
        <FileTypeIcon fileExtension={extension} />
        <Typography
          variant="body2"
          sx={{
            width: FILE_NAME_MAX_WIDTH,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
        <Typography variant="body2" color={theme.palette.error.main}>
          {size}
        </Typography>
      </Stack>
      <FileStatus status={status} />
    </Stack>
  );
};

export default InvalidFileRow;
