// graphql
import { FileStatus as GQLFileStatus } from 'src/__generated__/graphql';

// components
import { UploadIcon } from 'src/components';
import { UploadIconSize } from 'src/components/UploadIcon/types';

export type FileStatus =
  | 'Failed'
  | GQLFileStatus.Active
  | GQLFileStatus.Canceled
  | GQLFileStatus.Paused
  | GQLFileStatus.PendingUploading
  | GQLFileStatus.Uploaded
  | GQLFileStatus.PendingValidation
  | GQLFileStatus.Validated;
// | GQLFileStatus.Corrupted TODO: will be implemented in another task.

export const FileStatusSettings = {
  uploaded: {
    icon: <UploadIcon variant="complete" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.success.main,
    label: 'Completed',
    value: GQLFileStatus.Uploaded as FileStatus,
  },
  validated: {
    icon: <UploadIcon variant="complete" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.success.main,
    label: 'Completed',
    value: GQLFileStatus.Validated as FileStatus,
  },
  active: {
    icon: <UploadIcon variant="loading" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.primary.main,
    label: 'Uploading',
    value: GQLFileStatus.Active as FileStatus,
  },
  failed: {
    icon: <UploadIcon variant="failed" size={UploadIconSize.SMALL} />,
    textColor: (theme) => theme.palette.error.main,
    label: 'Failed',
    value: 'Failed' as FileStatus,
  },
  pendingUploading: {
    icon: null,
    textColor: (theme) => theme.palette.secondary.main,
    label: 'Ready To Upload',
    value: GQLFileStatus.PendingUploading as FileStatus,
  },
  paused: {
    icon: null,
    textColor: (theme) => theme.palette.primary.shade[500],
    label: 'Paused',
    value: GQLFileStatus.Paused as FileStatus,
  },
  canceled: {
    icon: null,
    textColor: (theme) => theme.palette.primary.shade[500],
    label: 'Canceled',
    value: GQLFileStatus.Canceled as FileStatus,
  },
};
