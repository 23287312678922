import { SiteListMember } from 'src/types/Site';

export enum SiteActionType {
  SET_SITE_LIST_MEMBER_LOADING = 'Site@SET_SITE_LIST_MEMBER_LOADING',
  FETCH_SITE_LIST_MEMBER = 'Site@FETCH_SITE_LIST_MEMBER',
  SET_SITE_LIST_MEMBER = 'Site@SET_SITE_LIST_MEMBER',
  SET_SITE_LIST_MEMBER_ERROR = 'Site@SET_SITE_LIST_MEMBER_ERROR',

  UPDATE_ASSESSMENT = 'Site@UPDATE_ASSESSMENT',
  SET_UPDATE_ASSESSMENT_ERROR = 'Site@SET_UPDATE_ASSESSMENT_ERROR',

  SET_ERROR = 'Site@SET_ERROR',
  CLEAR = 'Site@CLEAR',
}

export type SiteState = {
  siteListMember?: SiteListMember;
  siteListMemberLoading: boolean;
  siteListMemberError?: Error;

  updateAssessmentError?: Error;
};

export type SitePayload = {
  siteListMemberId?: string;
  siteListMember?: SiteListMember;
  siteListMemberLoading?: boolean;
  siteListMemberError?: Error;

  assessmentId?: string;
  action?: string;
  updateAssessmentError?: Error;
};

export type SiteAction = {
  payload?: SitePayload;
  type: SiteActionType;
};

export type SiteReducer = (state: SiteState, payload?: SitePayload) => SiteState;
