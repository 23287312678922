import { UploadMenuType } from './types';

export const UploadMenus = {
  Share: {
    label: 'Share',
    value: 'share' as UploadMenuType,
    icon: 'share-from-square',
  },
  Setting: {
    label: 'Settings',
    value: 'settings' as UploadMenuType,
    icon: 'gear',
  },
  Delete: {
    label: 'Delete',
    value: 'delete' as UploadMenuType,
    icon: 'trash',
  },
};
