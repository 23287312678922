import { ApolloClient, gql } from '@apollo/client';

// graphql
// import { gql } from 'src/__generated__/gql';
import { Upload, UploadListResponse } from 'src/__generated__/graphql';

// reducer
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';

// utils
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';

export const GET_UPLOADS = gql(`
  query getUploads($userId: String!) {
    getUploads(userId: $userId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      uploads {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const queryUploadRecords = async (
  client: ApolloClient<object>,
  profileId: string
): Promise<UploadRecord[]> => {
  try {
    const response = await client.query<{ getUploads: UploadListResponse }, { userId: String }>({
      query: GET_UPLOADS,
      variables: { userId: profileId },
    });

    if (!response.data.getUploads.status.errorCode) {
      const uploads: Upload[] = (response?.data?.getUploads.uploads as Upload[]) || [];

      const uploadRecords: UploadRecord[] = uploads.map((upload: Upload) => {
        let uploadRecord: UploadRecord = {
          upload,
          files: [],
          status: upload.status as UploadStatus,
          signedUrl: undefined,
        };

        uploadRecord.status = getUploadRecordStatusFromFiles(uploadRecord);
        return uploadRecord;
      });

      return uploadRecords;
    } else {
      const error = new Error(response.data.getUploads.status.errorDetails?.message as string);
      throw error;
    }
  } catch (e) {
    throw e;
  }
};

export default queryUploadRecords;
