// CA
import { Radar } from 'ca-react-component-lib';

// types
import { SummaryButtonProps } from './types';

// Radar DS
const { useTheme, FontAwesome, IconButton, Tooltip } = Radar;

const SummaryButton = ({
  tooltipTitle,
  icon,
  iconType = 'regular',
  onClick,
}: SummaryButtonProps) => {
  const theme = useTheme();
  return (
    <Tooltip arrow title={tooltipTitle} placement="top">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onClick} sx={{ p: 1 }}>
          <FontAwesome
            icon={icon}
            type={iconType}
            color={theme.palette.primary.shade[400]}
            size={theme.size.icon.huge}
          />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default SummaryButton;
