// ca
import { Radar, debounce } from 'ca-react-component-lib';

// reducer
import actions from 'src/store/UploadRecord/actions';

// components
import OptionCard from 'src/components/UploadSettingsDialog/OptionCard';
import { UploadAlert } from 'src/components/UploadAlert';
import AssessmentSelection from './AssessmentSelection';

// hooks
import { useUploadRecord, useSiteSearchQuery, useUploadSettingsDialog } from 'src/hooks';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';

// utils
import { getAutocompleteText } from 'src/utils/getAutocompleteText';
import CapturesSelection from './CapturesSelection';

// Radar DS
const {
  TextField,
  Stack,
  Autocomplete,
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
  useTheme,
} = Radar;

const Create = () => {
  const { settings, setSettings } = useUploadSettingsDialog();
  const {
    setQuickSearchLoading,
    setQuickSearchQuery,
    setQuickSearchQueryClear,
    quickSearchQueryResult,
    isQuickSearchLoading,
  } = useSiteSearchQuery();
  const { createUploadRecordLoading, createUploadRecordError } = useUploadRecord();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [captureOption, setCaptureOption] = useState<'new' | 'replace' | undefined>('new');
  const [search, setSearch] = useState('');

  const handleSearch = useCallback(
    (value: string) => {
      setQuickSearchLoading(true);

      if (value) {
        setSearch(value);
        dispatch(actions.setCreateUploadRecordError(undefined));
        debounce(() => {
          setQuickSearchQuery({
            text: value,
          });
        }, 800);
      } else {
        setQuickSearchQueryClear();
        setSearch('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setQuickSearchLoading]
  );

  return (
    <Stack sx={{ gap: 4 }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <OptionCard
          label="New Capture"
          icon="circle-plus"
          onClick={() => {
            setCaptureOption('new');
            setSettings({
              ...settings,
              assessment: undefined,
            });
          }}
          active={captureOption === 'new'}
        />
        <OptionCard
          label="Replace Capture"
          icon="rotate"
          iconType={'solid'}
          onClick={() => {
            setCaptureOption('replace');
            setSettings({
              ...settings,
              capture: undefined,
            });
          }}
          active={captureOption === 'replace'}
          disabled
        />
      </Box>
      {captureOption && (
        <Stack sx={{ gap: 4 }}>
          <Autocomplete
            id="asset"
            noOptionsText={getAutocompleteText(search, isQuickSearchLoading)}
            getOptionLabel={(option) =>
              option && option.companySiteId ? option.companySiteId : ''
            }
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.companySiteId}>
                <Box>
                  <Typography variant="body2">{option.companySiteId}</Typography>
                </Box>
              </li>
            )}
            renderInput={(restParams) => (
              <TextField
                {...restParams}
                placeholder="None"
                label="Asset"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleSearch(e.currentTarget.value)}
                InputProps={{
                  ...restParams.InputProps,
                  endAdornment: (
                    <>
                      {isQuickSearchLoading ? (
                        <InputAdornment position="end">
                          <CircularProgress color="inherit" size={theme.size.icon.huge} />{' '}
                        </InputAdornment>
                      ) : (
                        restParams.InputProps.endAdornment
                      )}
                    </>
                  ),
                }}
              />
            )}
            value={settings?.asset || null}
            options={(quickSearchQueryResult && quickSearchQueryResult.data) ?? []}
            onChange={(_: any, newValue: any) => {
              dispatch(actions.setCreateUploadRecordError(undefined));
              setSettings({
                ...settings,
                asset: newValue,
                uploadName: newValue?.companySiteId
                  ? `Upload #${newValue.companySiteId}`
                  : 'Upload',
                assessment: undefined,
              });
            }}
            isOptionEqualToValue={(option, value) =>
              value.companySiteId === option.companySiteId || option === ''
            }
            disabled={createUploadRecordLoading}
          />
          {captureOption === 'replace' ? (
            <AssessmentSelection search={search} />
          ) : (
            <CapturesSelection />
          )}

          <TextField
            inputProps={{ placeholder: 'Upload #Asset', sx: { flex: 1 } }}
            label="Upload Name"
            InputLabelProps={{ shrink: true }}
            value={settings?.uploadName || ''}
            disabled={createUploadRecordLoading}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(actions.setCreateUploadRecordError(undefined));
              setSettings({
                ...settings,
                uploadName: event.target.value,
              });
            }}
          />

          {createUploadRecordError && (
            <Box sx={{ textAlign: 'center' }}>
              <UploadAlert
                alertType="failed"
                description={createUploadRecordError.message}
                severity="error"
              />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Create;
