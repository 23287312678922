import {
  SiteSearchQueryAction,
  SiteSearchQueryActionType,
  SiteSearchQueryReducer,
  SiteSearchQueryPayload,
  SiteSearchQueryState,
} from './types';

export const initialState: SiteSearchQueryState = {
  isQuickSearchLoading: false,
  quickSearchQueryResult: undefined,
};

const setQuickSearchLoading: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  isQuickSearchLoading:
    payload && payload.isQuickSearchLoading !== undefined
      ? payload.isQuickSearchLoading
      : state.isQuickSearchLoading,
});

const setQuickSearchQueryResult: SiteSearchQueryReducer = (
  state: SiteSearchQueryState,
  payload?: SiteSearchQueryPayload
) => ({
  ...state,
  quickSearchQueryResult:
    payload && payload.quickSearchQueryResult
      ? payload.quickSearchQueryResult
      : state.quickSearchQueryResult,
});

const setQuickSearchQueryClear: SiteSearchQueryReducer = (state: SiteSearchQueryState) => ({
  ...state,
  isQuickSearchLoading: false,
  quickSearchQuery: {
    page: 1,
    text: '',
  },
  quickSearchQueryResult: undefined,
});

const reducerMap = new Map<SiteSearchQueryActionType, SiteSearchQueryReducer>([
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_LOADING, setQuickSearchLoading],
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_RESULT, setQuickSearchQueryResult],
  [SiteSearchQueryActionType.SET_QUICK_SEARCH_QUERY_CLEAR, setQuickSearchQueryClear],
]);

const reducer = (
  state: SiteSearchQueryState = initialState,
  action: SiteSearchQueryAction
): SiteSearchQueryState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
