// ca
import { Radar } from 'ca-react-component-lib';

// components
import { ShareDialogContent } from './ShareDialogContent';

// hooks
import { useEffect, useMemo, useState } from 'react';
import { useShareDialog } from 'src/hooks';

const { Stack, Button, CustomDialog } = Radar;

export const ShareDialog = () => {
  const { open, setOpen, settings, setSettings } = useShareDialog();
  const [copied, setCopied] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    if (isCopying) {
      setTimeout(() => {
        setCopied(true);
      }, 1000);
    }
  }, [isCopying]);

  const link = useMemo(() => 'app.consilienceanalytics.com/upload/' + settings?.id, [settings?.id]);

  const handleCloseShareDialog = () => {
    setOpen(false);
    setTimeout(() => {
      setSettings(undefined);
      setIsCopying(false);
      setCopied(false);
    }, 1000);
  };

  return (
    <CustomDialog
      title={!copied && !isCopying ? 'Share Project' : undefined}
      open={open}
      showCloseButton={false}
      content={
        <ShareDialogContent
          copied={copied}
          link={link}
          isCopying={isCopying}
          title={settings?.name ?? ''}
        />
      }
      buttons={
        <Stack flexDirection={'row'} gap={2} sx={{ mt: 12 }}>
          {!isCopying && (
            <>
              <Button variant="grey" onClick={() => setOpen(false)}>
                Cancel
              </Button>

              {!copied && (
                <Button
                  variant={'contained'}
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    setIsCopying(true);
                  }}
                >
                  Copy
                </Button>
              )}
            </>
          )}
          {copied && (
            <Button variant="grey" onClick={handleCloseShareDialog}>
              Close
            </Button>
          )}
        </Stack>
      }
      onClose={() => setOpen(false)}
    />
  );
};
