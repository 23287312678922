import { FileStatusSettings } from 'src/types/FileStatus';
import { FileType } from 'src/types/FileTypes';

export const sortFilesToFolders = (files?: FileType[], folderName?: string) => {
  if (!files) return [] as FileType[];
  const sortedFiles = files.filter((file: FileType) => file.folderName === folderName);

  return sortedFiles;
};

export const getSelectedFiles = (files: FileType[]) => {
  const selectedFilesToReplace = files.filter(
    (file: FileType) => file.selected || file.status === FileStatusSettings.failed.value
  );

  return selectedFilesToReplace;
};

export const createSelectedFilesIds = (files: FileType[]) => {
  const selectedFiles = getSelectedFiles(files);
  const selectedFilesIds = selectedFiles.map((file: FileType) => ({
    id: file.id as string,
  }));

  return selectedFilesIds;
};
