import api from './api';

export const fetchCompanies = async (): Promise<any> => {
  try {
    const response = await api.fetchUserCompanies();
    return response?.companies?.map((item: any) => ({
      label: item.abbreviation,
      value: item.id,
    }));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const profileServices = {
  fetchCompanies,
};

export default profileServices;
