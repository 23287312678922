import React, { createContext, useState } from 'react';
import { useSite, useSiteSearchQuery } from 'src/hooks';

// type
import {
  UploadSettings,
  UploadSettingsDialogContextProps,
  UploadSettingsDialogProviderProps,
} from './types';

const initialState: UploadSettingsDialogContextProps = {
  open: false,
  setOpen: () => {},
  setSettings: () => {},
  settings: {
    variant: 'create',
    uploadName: undefined,
    uploadId: undefined,
    asset: undefined,
    assessment: undefined,
    groupId: undefined,
    externalId: undefined,
    capture: undefined,
  },
};

const UploadSettingsDialogContext = createContext(initialState);

const UploadSettingsDialogProvider = ({ children }: UploadSettingsDialogProviderProps) => {
  const [open, setOpen] = useState(initialState.open);
  const [settings, setSettings] = useState<UploadSettings | undefined>(initialState.settings);
  const { clearState: clearSiteState } = useSite();
  const { setQuickSearchQueryClear } = useSiteSearchQuery();

  return (
    <UploadSettingsDialogContext.Provider
      value={{
        open,
        settings,
        setSettings,
        setOpen: (open: boolean, currentSettings?: UploadSettings) => {
          setOpen(open);

          // if we are going to close
          if (!open) {
            setTimeout(() => {
              setSettings(initialState.settings);
              clearSiteState();
              setQuickSearchQueryClear();
            }, 500);
          } else {
            setSettings(currentSettings);
          }
        },
      }}
    >
      {children}
    </UploadSettingsDialogContext.Provider>
  );
};

export { UploadSettingsDialogContext, UploadSettingsDialogProvider };
