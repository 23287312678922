import { SiteAction, SiteActionType, SiteReducer, SitePayload, SiteState } from './types';

export const initialState: SiteState = {
  siteListMember: undefined,
  siteListMemberLoading: false,
  siteListMemberError: undefined,

  updateAssessmentError: undefined,
};

const setSiteListMemberLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  siteListMemberLoading: !!payload?.siteListMemberLoading,
});

const setSiteListMember: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  siteListMember: (payload && payload.siteListMember) ?? undefined,
});

const setSiteListMemberError: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  siteListMemberError: payload ? payload.siteListMemberError : undefined,
});

const setUpdateAssessmentError: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  updateAssessmentError: payload ? payload.updateAssessmentError : undefined,
});

const clearState: SiteReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<SiteActionType, SiteReducer>([
  [SiteActionType.SET_SITE_LIST_MEMBER_LOADING, setSiteListMemberLoading],
  [SiteActionType.SET_SITE_LIST_MEMBER, setSiteListMember],
  [SiteActionType.SET_SITE_LIST_MEMBER_ERROR, setSiteListMemberError],
  [SiteActionType.CLEAR, clearState],

  [SiteActionType.SET_UPDATE_ASSESSMENT_ERROR, setUpdateAssessmentError],
]);

const reducer = (state: SiteState = initialState, action: SiteAction): SiteState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
