import { User } from 'src/types/User';
import { ProfileAction, ProfileActionType } from './types';
import { LabelValue } from 'src/types/Root';

const actions = {
  setProfileUser: (userData: User): ProfileAction => ({
    payload: { userData },
    type: ProfileActionType.SET_PROFILE_USER,
  }),

  setCompanies: (companies: LabelValue[]): ProfileAction => ({
    payload: { companies },
    type: ProfileActionType.SET_COMPANIES,
  }),

  setLoading: (loading: boolean): ProfileAction => ({
    payload: { loading },
    type: ProfileActionType.SET_LOADING,
  }),

  setError: (error: Error | undefined): ProfileAction => ({
    payload: { error },
    type: ProfileActionType.SET_ERROR,
  }),

  clearState: (): ProfileAction => ({
    type: ProfileActionType.CLEAR,
  }),
};

export default actions;
