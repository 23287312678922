import request from '../request';
import endpoints from './endpoints';

const api = {
  authenticate: async (email: string, password: string) =>
    request(endpoints.login, {
      method: 'POST',
      body: JSON.stringify({
        user_credentials: {
          email: email,
          password: password,
        },
      }),
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
