import {
  UploadRecordAction,
  UploadRecordActionType,
  UploadRecordPayload,
  UploadRecordReducer,
  UploadRecordState,
} from './types';

export const initialState: UploadRecordState = {
  uploadRecords: [],
  
  // get upload records
  fetchUploadRecordsLoading: false,
  fetchUploadRecordSuccess: false,
  fetchUploadRecordsError: undefined,

  // get upload record
  fetchUploadRecordLoading: false,
  fetchUploadRecordError: undefined,

  // create upload record
  createUploadRecordLoading: false,
  createUploadRecordSuccess: undefined,
  createUploadRecordError: undefined,

  // start upload record
  startUploadRecordLoading: false,
  startUploadRecordError: undefined,

  // pause upload record
  pauseUploadRecordLoading: false,
  pauseUploadRecordError: undefined,

  // cancel upload record
  cancelUploadRecordLoading: false,
  cancelUploadRecordError: undefined,

  // complete upload record
  completeUploadRecordLoading: false,
  completeUploadRecordError: undefined,

  // delete upload record
  deleteUploadRecordLoading: false,
  deleteUploadRecordSuccess: undefined,
  deleteUploadRecordError: undefined,

  // upload records filter
  uploaderFilter: undefined,

  // send files upload record
  sendFilesUploadRecordLoading: false,
  sendFilesUploadRecordError: undefined,

  // rename upload record
  renameUploadRecordLoading: false,
  renameUploadRecordSuccess: undefined,
  renameUploadRecordError: undefined,
};

const setUploadRecords: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  uploadRecords: payload?.uploadRecords ?? state.uploadRecords,
});

const updateUploadRecords: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  uploadRecords: payload?.uploadRecords ?? state.uploadRecords,
});

const setFetchUploadRecordsLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  fetchUploadRecordsLoading: !!payload?.fetchUploadRecordsLoading,
});

const setFetchUploadRecordsError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  fetchUploadRecordsError: payload ? payload?.fetchUploadRecordsError : undefined,
});

const setFetchUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  fetchUploadRecordLoading: !!payload?.fetchUploadRecordLoading,
});

const setFetchUploadRecordSuccess: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  fetchUploadRecordSuccess: !!payload?.fetchUploadRecordSuccess,
});

const setFetchUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  fetchUploadRecordError: payload ? payload?.fetchUploadRecordError : undefined,
});

const setUploadRecordId: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  uploadRecordId: payload && payload.uploadRecordId ? payload.uploadRecordId : state.uploadRecordId,
});

// send files upload record
const setSendFilesUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  sendFilesUploadRecordLoading: !!payload?.sendFilesUploadRecordLoading,
});

const setSendFilesUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  sendFilesUploadRecordError: payload ? payload?.sendFilesUploadRecordError : undefined,
});

// create upload record
const setCreateUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  createUploadRecordLoading: !!payload?.createUploadRecordLoading,
});

const setCreateUploadRecordSuccess: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  createUploadRecordSuccess: payload ? payload?.createUploadRecordSuccess : undefined,
});

const setCreateUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  createUploadRecordError: payload ? payload?.createUploadRecordError : undefined,
});

// start upload record
const setStartUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  startUploadRecordLoading: !!payload?.startUploadRecordLoading,
});

const setStartUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  startUploadRecordError: payload ? payload?.startUploadRecordError : undefined,
});

// delete upload record
const setDeleteUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  deleteUploadRecordLoading: !!payload?.deleteUploadRecordLoading,
});

const setDeleteUploadRecordSuccess: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  deleteUploadRecordSuccess: payload ? payload?.deleteUploadRecordSuccess : undefined,
});

const setDeleteUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  deleteUploadRecordError: payload ? payload?.deleteUploadRecordError : undefined,
});

// pause upload record
const setPauseUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  pauseUploadRecordLoading: !!payload?.pauseUploadRecordLoading,
});

const setPauseUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  pauseUploadRecordError: payload ? payload?.pauseUploadRecordError : undefined,
});

// cancel upload record
const setCancelUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  cancelUploadRecordLoading: !!payload?.cancelUploadRecordLoading,
});

const setCancelUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  cancelUploadRecordError: payload ? payload?.cancelUploadRecordError : undefined,
});

// complete upload record
const setCompleteUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  completeUploadRecordLoading: !!payload?.completeUploadRecordLoading,
});

const setCompleteUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  completeUploadRecordError: payload ? payload?.completeUploadRecordError : undefined,
});

// rename upload record
const setRenameUploadRecordLoading: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  renameUploadRecordLoading: !!payload?.renameUploadRecordLoading,
});

const setRenameUploadRecordSuccess: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  renameUploadRecordSuccess: !!payload?.renameUploadRecordSuccess,
});

const setRenameUploadRecordError: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  renameUploadRecordError: payload ? payload?.renameUploadRecordError : undefined,
});

// upload records filter
const setUploaderFilter: UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => ({
  ...state,
  uploaderFilter:
    payload &&
    (payload.uploaderFilter?.search !== undefined || payload.uploaderFilter?.status !== undefined)
      ? payload.uploaderFilter
      : undefined,
});

const clearState: UploadRecordReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<UploadRecordActionType, UploadRecordReducer>([
  [UploadRecordActionType.SET_UPLOAD_RECORDS, setUploadRecords],
  [UploadRecordActionType.UPDATE_UPLOAD_RECORDS, updateUploadRecords],
  [UploadRecordActionType.SET_UPLOAD_RECORD_ID, setUploadRecordId],

  // send files to upload record
  [UploadRecordActionType.SET_SEND_FILES_UPLOAD_RECORD_LOADING, setSendFilesUploadRecordLoading],
  [UploadRecordActionType.SET_SEND_FILES_UPLOAD_RECORD_ERROR, setSendFilesUploadRecordError],

  // fetch upload records
  [UploadRecordActionType.SET_FETCH_UPLOAD_RECORDS_LOADING, setFetchUploadRecordsLoading],
  [UploadRecordActionType.SET_FETCH_UPLOAD_RECORDS_ERROR, setFetchUploadRecordsError],

  // fetch upload record
  [UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_LOADING, setFetchUploadRecordLoading],
  [UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_SUCCESS, setFetchUploadRecordSuccess],
  [UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_ERROR, setFetchUploadRecordError],

  // create upload record
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_LOADING, setCreateUploadRecordLoading],
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_SUCCESS, setCreateUploadRecordSuccess],
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_ERROR, setCreateUploadRecordError],

  // start upload record
  [UploadRecordActionType.SET_START_UPLOAD_RECORD_LOADING, setStartUploadRecordLoading],
  [UploadRecordActionType.SET_START_UPLOAD_RECORD_ERROR, setStartUploadRecordError],

  // delete upload record
  [UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_LOADING, setDeleteUploadRecordLoading],
  [UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_SUCCESS, setDeleteUploadRecordSuccess],
  [UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_ERROR, setDeleteUploadRecordError],

  // create upload record
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_LOADING, setCreateUploadRecordLoading],
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_SUCCESS, setCreateUploadRecordSuccess],
  [UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_ERROR, setCreateUploadRecordError],

  // pause upload record
  [UploadRecordActionType.SET_PAUSE_UPLOAD_RECORD_LOADING, setPauseUploadRecordLoading],
  [UploadRecordActionType.SET_PAUSE_UPLOAD_RECORD_ERROR, setPauseUploadRecordError],

  // cancel upload record
  [UploadRecordActionType.SET_CANCEL_UPLOAD_RECORD_LOADING, setCancelUploadRecordLoading],
  [UploadRecordActionType.SET_CANCEL_UPLOAD_RECORD_ERROR, setCancelUploadRecordError],

  // complete upload record
  [UploadRecordActionType.SET_COMPLETE_UPLOAD_RECORD_LOADING, setCompleteUploadRecordLoading],
  [UploadRecordActionType.SET_COMPLETE_UPLOAD_RECORD_ERROR, setCompleteUploadRecordError],

  // rename upload record
  [UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_LOADING, setRenameUploadRecordLoading],
  [UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_SUCCESS, setRenameUploadRecordSuccess],
  [UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_ERROR, setRenameUploadRecordError],

  // filter upload record
  [UploadRecordActionType.SET_UPLOADER_FILTER, setUploaderFilter],

  [UploadRecordActionType.CLEAR, clearState],
]);

const reducer = (state: UploadRecordState = initialState, action: UploadRecordAction) => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);

  return state;
};

export default reducer;
