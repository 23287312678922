/*
 * This is util is dedicated only to create the FileListInput and FileType type.
 * - FileListInput type represents the input of the mutations to change status of the files.
 * - FileType type represents the file itself.
 *
 * FileListInput Used by:
 * - mutation cancelFiles
 */

// graphql
import { FileListInput } from 'src/__generated__/graphql';

// types
import { FileType } from 'src/types/FileTypes';
import { FolderName } from 'src/types/Folder';
import { FileStatusSettings } from 'src/types/FileStatus';

// utils
import createFileInput from './createFileInput';

/**
 * Rules to be a cancelable file:
 * 1. File cannot be canceled
 * 2. File cannot be uploaded
 * 3. File cannot be validated
 */
const getCancelableFiles = (
  uploadId: string,
  fileTypes: FileType[],
  folderName: FolderName | undefined = undefined
) => {
  let cancelableFileTypes: FileType[] = [];

  if (folderName) {
    cancelableFileTypes = fileTypes.filter(
      (fileType: FileType) =>
        fileType.status !== FileStatusSettings.canceled.value &&
        fileType.status !== FileStatusSettings.uploaded.value &&
        fileType.status !== FileStatusSettings.validated.value &&
        !fileType.file &&
        folderName === fileType.folderName
    );
  } else {
    cancelableFileTypes = fileTypes.filter(
      (fileType: FileType) =>
        fileType.status !== FileStatusSettings.canceled.value &&
        fileType.status !== FileStatusSettings.uploaded.value &&
        fileType.status !== FileStatusSettings.validated.value &&
        !fileType.file
    );
  }

  const fileListInput: FileListInput = {
    uploadId: uploadId,
    files: createFileInput(cancelableFileTypes),
  };

  return {
    fileListInput,
    fileTypes,
  };
};

export default getCancelableFiles;
