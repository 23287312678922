import { Radar } from 'ca-react-component-lib';
import { IconButtonVariantProps } from './types';

const { IconButton, Tooltip } = Radar;

const IconButtonVariant = ({ onClick, icon, tooltip }: IconButtonVariantProps) => (
  <Tooltip title={tooltip} arrow placement={'top'}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        disableRipple
        sx={{ p: 0, '&hover': { background: 'transparent' } }}
        onClick={onClick}
      >
        {icon}
      </IconButton>
    </div>
  </Tooltip>
);

export default IconButtonVariant;
