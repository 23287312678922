// ca
import { Radar } from 'ca-react-component-lib';

// components
import { UploadIcon, UploadProgressBar } from 'src/components';

// types
import { ControlsProps } from '../types';

// Radar DS
const { useTheme, Stack, Box } = Radar;

const Canceled = ({ uploadRecord }: ControlsProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.text.disabled,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <UploadIcon variant="folder" />
          {uploadRecord?.upload?.name}
        </Box>
        Canceled
      </Box>
      <UploadProgressBar
        progress={uploadRecord.progress || 0}
        barColor={theme.palette.primary.shade[200]}
      />
    </Stack>
  );
};

export default Canceled;
