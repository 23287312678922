import { Radar } from 'ca-react-component-lib';
import { ProgresBarProps } from './types';

const { LinearProgress, useTheme } = Radar;

export const UploadProgressBar = ({ barColor, progress }: ProgresBarProps) => {
  const theme = useTheme();
  return (
    <LinearProgress
      variant="determinate"
      value={progress || 0}
      sx={{
        height: 3,
        backgroundColor: theme.palette.primary.shade[200],
        '.MuiLinearProgress-bar1Determinate': {
          backgroundColor: barColor,
        },
      }}
    />
  );
};
