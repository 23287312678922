import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// graphql
import { FileListInput, UploadFilesResponse } from 'src/__generated__/graphql';

// reducer
import Store from 'src/store';
import actions from 'src/store/UploadFiles/actions';
import uploadRecordActions from 'src/store/UploadRecord/actions';

// types
import { FileType } from 'src/types/FileTypes';
import { UploadRecord } from 'src/types/UploadRecord';
import { FileStatusSettings } from 'src/types/FileStatus';

// utils
import createFileInput from 'src/utils/createFileInput';

// TODO: resolve later. yarn compile not working properly.
// graphql
// import { gql } from 'src/__generated__/gql';

const UPLOADED_FILES = gql(`
  mutation uploadedFiles($params: FileListInput!) {
    uploadedFiles(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        fileName
        folderName
        id
        path
        size
        status
        activeAt
        uploadedAt
      }
      signedUrl {
        fields {
          awsAccessKeyId
          contentType
          key
          policy
          signature
        }
        url
      }
    }
  }
`);

const useUploadedUploadFiles = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const uploadedUploadFiles = useCallback(
    (uploadId: string | undefined, fileTypes: FileType[]) => {
      const handleUploadedUploadFile = async () => {
        try {
          if (fileTypes.length === 0 || !uploadId) {
            return;
          }

          dispatch(actions.setUploadedUploadFilesLoading(true));

          const response = await client.mutate<
            { uploadedFiles: UploadFilesResponse }, // mutation response
            { params: FileListInput } // mutation input
          >({
            mutation: UPLOADED_FILES,
            variables: {
              params: {
                uploadId: uploadId,
                files: createFileInput(fileTypes),
              },
            },
          });

          const uploadRecord: UploadRecord | undefined =
            Store.getState().UploadRecord.uploadRecords.find(
              (uploadRecord: UploadRecord) => uploadRecord.upload?.id === uploadId
            );

          if (!response.data?.uploadedFiles.status.errorCode && uploadRecord) {
            uploadRecord.files = uploadRecord.files.map((uploadFile) => {
              let newFile = fileTypes.find((file) => uploadFile.id === file.id);
              if (newFile) {
                return {
                  ...newFile,
                  status: FileStatusSettings.uploaded.value,
                };
              } else {
                return uploadFile;
              }
            });

            dispatch(uploadRecordActions.setUploadRecord(uploadRecord));
          } else {
            const error = new Error(
              response?.data?.uploadedFiles.status.errorDetails?.message as string
            );

            dispatch(actions.setUploadedUploadFilesError(error));
          }
        } catch (error) {
          dispatch(actions.setUploadedUploadFilesError(error));
        } finally {
          dispatch(actions.setUploadedUploadFilesLoading(false));
        }
      };

      handleUploadedUploadFile();
    },
    [client, dispatch]
  );

  return {
    uploadedUploadFiles,
  };
};

export default useUploadedUploadFiles;
