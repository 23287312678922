import { ApplicationState } from 'src/store/types';

const _getData = (state: ApplicationState) => state.Site;

const getSiteListMember = (state: ApplicationState) => _getData(state).siteListMember;

const getSiteListMemberLoading = (state: ApplicationState) => _getData(state).siteListMemberLoading;

const getSiteListMemberError = (state: ApplicationState) => _getData(state).siteListMemberError;

const getUpdateAssessmentError = (state: ApplicationState) => _getData(state).updateAssessmentError;

const selectors = {
  getSiteListMember,
  getSiteListMemberLoading,
  getSiteListMemberError,
  getUpdateAssessmentError,
};

export default selectors;
