import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import actions from 'src/store/SiteSearchQuery/actions';

// selectors
import SiteSearchQuerySelectors from 'src/store/SiteSearchQuery/selectors';

const useSiteSearchQuery = () => {
  const quickSearchQueryResult = useSelector(SiteSearchQuerySelectors.getQuickSearchQueryResult);
  const isQuickSearchLoading = useSelector(SiteSearchQuerySelectors.getQuickSearchLoading);

  const dispatch = useDispatch();

  const setQuickSearchQuery = useCallback(
    (searchQuery) => {
      dispatch(actions.setQuickSearchQuery(searchQuery));
    },
    [dispatch]
  );

  const setQuickSearchLoading = useCallback(
    (isQuickSearchLoading) => {
      dispatch(actions.setQuickSearchLoading(isQuickSearchLoading));
    },
    [dispatch]
  );

  const setQuickSearchQueryClear = useCallback(() => {
    dispatch(actions.setQuickSearchQueryClear());
  }, [dispatch]);

  return {
    isQuickSearchLoading,
    quickSearchQueryResult,
    setQuickSearchQuery,
    setQuickSearchLoading,
    setQuickSearchQueryClear,
  };
};

export default useSiteSearchQuery;
