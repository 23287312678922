import moment from 'moment';

// ca
import { Radar } from 'ca-react-component-lib';

// reducer
import actions from 'src/store/UploadRecord/actions';

// components
import { UploadAlert } from 'src/components/UploadAlert';

// hooks
import { useUploadRecord, useUploadSettingsDialog, useSite } from 'src/hooks';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// types
import { SiteAssessment } from 'src/types/Site';

// Radar DS
const {
  TextField,
  Stack,
  Autocomplete,
  Box,
  Typography,
  InputAdornment,
  CircularProgress,
  useTheme,
} = Radar;

const Edit = () => {
  const theme = useTheme();
  const { settings, setSettings } = useUploadSettingsDialog();
  const { fetchSiteListMember, siteListMemberLoading, siteListMember, siteListMemberError } =
    useSite();
  const { renameUploadRecordLoading, renameUploadRecordError } = useUploadRecord();
  const dispatch = useDispatch();

  useEffect(() => {
    if (settings?.externalId && !siteListMember && !siteListMemberError) {
      fetchSiteListMember(settings.externalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings?.variant]);

  return (
    <Stack sx={{ gap: 4 }}>
      <Autocomplete
        id="asset"
        getOptionLabel={(option) => (option && option.companySiteId ? option.companySiteId : '')}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.companySiteId}>
            <Box>
              <Typography variant="body2">{option.companySiteId}</Typography>
            </Box>
          </li>
        )}
        renderInput={(restParams) => (
          <TextField
            {...restParams}
            placeholder="None"
            label="Asset"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...restParams.InputProps,
              endAdornment: (
                <>
                  {siteListMemberLoading ? (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={theme.size.icon.huge} />
                    </InputAdornment>
                  ) : (
                    restParams.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={siteListMember || null}
        options={[]}
        isOptionEqualToValue={(option, value) =>
          value.companySiteId === option.companySiteId || option === ''
        }
        disabled
      />
      <Autocomplete
        id="assessment"
        getOptionLabel={(option) => {
          if (option && option.assessmentTag) {
            return `${option.assessmentTag} (${moment(new Date(option.dateCreated)).format(
              'D MMM, YYYY'
            )})`;
          }
          return '';
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {option.assessmentTag}{' '}
                {`(${moment(new Date(option.dateCreated)).format('D MMM, YYYY')})`}
              </Typography>
            </Box>
          </li>
        )}
        renderInput={(restParams) => (
          <TextField
            {...restParams}
            placeholder="None"
            label="Assessment"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...restParams.InputProps,
              endAdornment: (
                <>
                  {siteListMemberLoading ? (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={theme.size.icon.huge} />
                    </InputAdornment>
                  ) : (
                    restParams.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={
          siteListMember?.siteAssessments?.find(
            (assessment: SiteAssessment) => assessment.id === settings?.externalId
          ) || null
        }
        options={[]}
        isOptionEqualToValue={(option, value) => option === value}
        disabled
      />
      <TextField
        inputProps={{ placeholder: 'Upload #Asset', sx: { flex: 1 } }}
        label="Upload Name"
        InputLabelProps={{ shrink: true }}
        value={settings?.uploadName || ''}
        disabled={renameUploadRecordLoading}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(actions.setCreateUploadRecordError(undefined));
          setSettings({
            ...settings,
            uploadName: event.target.value,
          });
        }}
      />
      {renameUploadRecordError && (
        <Box sx={{ textAlign: 'center' }}>
          <UploadAlert
            alertType="failed"
            description={renameUploadRecordError.message}
            severity="error"
          />
        </Box>
      )}
      {siteListMemberError && (
        <Box sx={{ textAlign: 'center' }}>
          <UploadAlert
            alertType="failed"
            description="Couldn't load asset data."
            severity="error"
          />
        </Box>
      )}
    </Stack>
  );
};

export default Edit;
