import { useCallback } from 'react';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { UploadResponse } from 'src/__generated__/graphql';

// hooks
import { useDispatch } from 'react-redux';
import useUploadRecordUtils from './useUploadRecordUtils';

// actions
import actions from 'src/store/UploadRecord/actions';
import { UploadRecord } from '../../../types/UploadRecord';

const PAUSE_UPLOAD = gql(`
  mutation pauseUpload($uploadId: String!) {
    pauseUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const usePauseUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { updateUploadRecordsByUpload } = useUploadRecordUtils();

  const pauseUploadRecord = useCallback(
    (uploadId: string, uploadRecords: UploadRecord[]) => {
      const handlePauseUpload = async () => {
        try {
          dispatch(actions.setPauseUploadRecordLoading(true));

          const response = await client.mutate<
            { pauseUpload: UploadResponse }, // mutation response
            { uploadId: string } // mutation input
          >({
            mutation: PAUSE_UPLOAD,
            variables: { uploadId },
          });

          if (!response?.data?.pauseUpload.status.errorCode) {
            updateUploadRecordsByUpload(response.data?.pauseUpload.upload, uploadRecords);
          } else {
            const error = new Error(
              response?.data?.pauseUpload?.status?.errorDetails?.message as string
            );

            dispatch(actions.setPauseUploadRecordError(error));
          }
        } catch (error) {
          dispatch(actions.setPauseUploadRecordError(error));
        } finally {
          dispatch(actions.setPauseUploadRecordLoading(false));
        }
      };

      handlePauseUpload();
    },
    [client, dispatch, updateUploadRecordsByUpload]
  );

  return {
    pauseUploadRecord,
  };
};

export default usePauseUploadRecord;
