import { useCallback } from 'react';

// hooks
import { useUploadSettingsDialog } from 'src/hooks';

// constants
import DialogContentHandlers from './constants';

const DialogContent = () => {
  const { settings } = useUploadSettingsDialog();

  const renderContent = useCallback(
    () =>
      DialogContentHandlers.map((dialogContent, index: number) => {
        if (settings && settings.variant && dialogContent.isMatched(settings.variant)) {
          return <div key={index}>{dialogContent.renderVariantContent()}</div>;
        }
        return null;
      }),
    [settings]
  );

  return <>{renderContent()}</>;
};

export default DialogContent;
