import { useDispatch, useSelector } from 'react-redux';

// hooks
import useUploadRecord from './useUploadRecord';

// types
import { FileType } from 'src/types/FileTypes';
import { UploadRecord } from 'src/types/UploadRecord';

// reducer
import uploadFileSelectors from 'src/store/UploadFiles/selectors';
import actions from 'src/store/UploadRecord/actions';

// graphql
import {
  useDeleteUploadFiles,
  useReplaceUploadFiles,
  useCancelUploadFiles,
  usePauseUploadFiles,
  useUploadedUploadFiles,
} from 'src/services/uploadFiles/graphql';

const useUploadFiles = () => {
  const pauseUploadFilesLoading = useSelector(uploadFileSelectors.getPauseUploadFilesLoading);
  const pauseUploadFilesError = useSelector(uploadFileSelectors.getPauseUploadFilesError);

  const uploadedUploadFilesLoading = useSelector(uploadFileSelectors.getUploadedUploadFilesLoading);
  const uploadedUploadFilesError = useSelector(uploadFileSelectors.getUploadedUploadFilesError);

  const deletedUploadFilesLoading = useSelector(uploadFileSelectors.getDeleteUploadFilesLoading);
  const deletedUploadFilesError = useSelector(uploadFileSelectors.getDeleteUploadFilesError);

  const replaceUploadFilesLoading = useSelector(uploadFileSelectors.getReplaceUploadFilesLoading);
  const replaceUploadFilesError = useSelector(uploadFileSelectors.getReplaceUploadFilesError);

  const cancelUploadFilesLoading = useSelector(uploadFileSelectors.getCancelUploadFilesLoading);
  const cancelUploadFilesSuccess = useSelector(uploadFileSelectors.getCancelUploadFilesSuccess);
  const cancelUploadFilesError = useSelector(uploadFileSelectors.getCancelUploadFilesError);

  const dispatch = useDispatch();
  const { uploadedUploadFiles } = useUploadedUploadFiles();
  const { replaceUploadFiles } = useReplaceUploadFiles();
  const { deleteUploadFiles } = useDeleteUploadFiles();
  const { cancelUploadFiles, cancelMultipleUploadFiles } = useCancelUploadFiles();
  const { pauseFilesUploadFiles } = usePauseUploadFiles();

  const { uploadRecord } = useUploadRecord();

  const changeSelectFile = (filesToUpdate: FileType[]) => {
    if (!uploadRecord) {
      console.error('Upload record undefined to change select files.');
      return;
    }

    const updatedFiles = uploadRecord?.files.map((file: FileType) => {
      let updatedFile: FileType | undefined;

      for (let i = 0; i < filesToUpdate?.length; i++) {
        if (file?.id === filesToUpdate[i]?.id) {
          updatedFile = filesToUpdate[i];
          return updatedFile;
        }
      }

      return !updatedFile ? file : updatedFile;
    });

    const updatedUpload: UploadRecord = {
      ...uploadRecord,
      files: updatedFiles,
    };

    dispatch(actions.setUploadRecord(updatedUpload));
  };

  return {
    changeSelectFile,
    uploadedUploadFiles,
    replaceUploadFiles,
    deleteUploadFiles,
    cancelUploadFiles,
    cancelMultipleUploadFiles,
    pauseFilesUploadFiles,
    pauseUploadFilesError,
    pauseUploadFilesLoading,
    cancelUploadFilesError,
    cancelUploadFilesSuccess,
    cancelUploadFilesLoading,
    deletedUploadFilesError,
    deletedUploadFilesLoading,
    uploadedUploadFilesError,
    uploadedUploadFilesLoading,
    replaceUploadFilesError,
    replaceUploadFilesLoading,
  };
};

export default useUploadFiles;
