import { AuthenticationAction, AuthenticationActionType } from './types';

const actions = {
  login: (email: string, password: string): AuthenticationAction => ({
    payload: { email, password },
    type: AuthenticationActionType.LOGIN,
  }),
  setAuthenticated: (): AuthenticationAction => ({
    type: AuthenticationActionType.SET_AUTHENTICATED,
  }),
  setLoading: (loading: boolean): AuthenticationAction => ({
    payload: { loading },
    type: AuthenticationActionType.SET_LOADING,
  }),
  setError: (error: Error): AuthenticationAction => ({
    payload: { error },
    type: AuthenticationActionType.SET_ERROR,
  }),
  initialize: (): AuthenticationAction => ({
    type: AuthenticationActionType.INITIALIZE,
  }),
  setInitialized: (initialized: boolean): AuthenticationAction => ({
    payload: { initialized },
    type: AuthenticationActionType.SET_INITIALIZED,
  }),
  logout: (): AuthenticationAction => ({
    type: AuthenticationActionType.LOGOUT,
  }),
  clearState: (): AuthenticationAction => ({
    type: AuthenticationActionType.CLEAR,
  }),
};

export default actions;
