import UploadFolder from 'src/hooks/useUploader/UploaderFolder';
import { UploaderAction, UploaderActionType } from './types';

const actions = {
  setUploaderFolders: (uploaderFolders: UploadFolder[]): UploaderAction => ({
    payload: { uploaderFolders },
    type: UploaderActionType.SET_UPLOADER_FOLDERS,
  }),

  clearState: (): UploaderAction => ({
    type: UploaderActionType.CLEAR,
  }),
};

export default actions;
