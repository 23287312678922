// apollo
import { ApolloClient, gql } from '@apollo/client';

// graphql
import { Upload, UploadResponse } from 'src/__generated__/graphql';

const CANCEL_UPLOAD = gql(`
  mutation cancelUpload($uploadId: String!) {
    cancelUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
    }
  }
`);

const mutationCancelUploadRecord = async (
  client: ApolloClient<object>,
  uploadId: string
): Promise<Upload> => {
  try {
    const response = await client.mutate<
      { cancelUpload: UploadResponse }, // mutation response
      { uploadId: string } // mutation input
    >({
      mutation: CANCEL_UPLOAD,
      variables: { uploadId },
    });

    if (!response?.data?.cancelUpload.status.errorCode) {
      if (response.data?.cancelUpload.upload) {
        return response.data?.cancelUpload.upload;
      } else {
        throw new Error('Received invalid GraphQL upload response.');
      }
    } else {
      const error = new Error(response.data.cancelUpload.status.errorDetails?.message as string);
      throw error;
    }
  } catch (e) {
    throw e;
  }
};

export default mutationCancelUploadRecord;
