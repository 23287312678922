import { Uploads, Upload } from './loadingScreens';
import { LoadingScreenHandlerType } from './types';

const UploadsPageLoadingScreen: LoadingScreenHandlerType = {
  isPathnameMatched: (pathname: string) => pathname === '/upload',
  renderLoadingScreen: () => <Uploads />,
};

const UploadPageLoadingScreen: LoadingScreenHandlerType = {
  isPathnameMatched: (pathname: string, uploadId?: string, folderName?: string) =>
    uploadId && folderName ? pathname.includes(`/upload/${uploadId}/${folderName}`) : false,
  renderLoadingScreen: () => <Upload />,
};

export const LoadingScreenHandler: LoadingScreenHandlerType[] = [
  UploadsPageLoadingScreen,
  UploadPageLoadingScreen,
];
