// ca
import { Radar } from 'ca-react-component-lib';

// constants
import {
  FIRST_AND_LAST_CELL_WIDTH,
  TABLE_MIN_WIDTH,
} from 'src/pages/Uploads/UploadRecords/UploadTable/constants';
import {
  FILTER_BUTTON_SKELETON_WIDTH,
  HEADER_SKELETON_HEIGHT,
  TABLE_ITEM_SKELETON_WIDTH,
} from './constants';

// radar
const {
  Box,
  Skeleton,
  Stack,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  Button,
  Typography,
} = Radar;

const Uploads = () => (
  <Stack>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: HEADER_SKELETON_HEIGHT,
        my: 6.25,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 7, height: 'inherit' }}>
        <Skeleton variant="rectangular" height="100%">
          <Typography variant="h3">Uploader</Typography>
        </Skeleton>
        <Skeleton variant="rectangular" width={FILTER_BUTTON_SKELETON_WIDTH} height="100%" />
      </Box>
      <Skeleton variant="rectangular" height="100%">
        <Button>New Upload</Button>
      </Skeleton>
    </Box>
    <Paper sx={{ p: 4 }}>
      <TableContainer>
        <Table
          aria-label="upload table skeleton"
          sx={{
            tableLayout: 'fixed',
            minWidth: TABLE_MIN_WIDTH,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: FIRST_AND_LAST_CELL_WIDTH }} />
              {Array.from({ length: 6 }).map((_, index) => (
                <TableCell key={'table-head-cell-skeleton' + index}>
                  <Skeleton height={20} width={TABLE_ITEM_SKELETON_WIDTH} />
                </TableCell>
              ))}
              <TableCell sx={{ width: FIRST_AND_LAST_CELL_WIDTH }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 3 }).map((_, index) => (
              <TableRow
                key={'table-row-skeleton' + index}
                sx={{ '&:last-of-type td': { borderBottom: 0 } }}
              >
                <TableCell sx={{ width: FIRST_AND_LAST_CELL_WIDTH }}>
                  <Skeleton />
                </TableCell>
                {Array.from({ length: 6 }).map((_, index) => (
                  <TableCell key={'table-row-cell-skeleton' + index}>
                    <Skeleton width={TABLE_ITEM_SKELETON_WIDTH} />
                  </TableCell>
                ))}
                <TableCell sx={{ width: FIRST_AND_LAST_CELL_WIDTH }}>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Stack>
);

export default Uploads;
