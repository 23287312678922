import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// hooks
import useAuth from 'src/hooks/useAuth';

// pages
import Login from 'src/pages/Login';

// components
import { Radar } from 'ca-react-component-lib';

import { AppHeaderSkeleton, LoadingScreen } from 'src/components';

const { Box } = Radar;
type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return (
      <>
        <AppHeaderSkeleton />
        <Box sx={{ px: 12, mt: 16 }}>
          <LoadingScreen />
        </Box>
      </>
    );
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
