import { ReactNode, useEffect, useState, useCallback } from 'react';

// utils
import { debounce } from 'ca-react-component-lib';

const useRenderOnScroll = (elements: any[], elementHeight: number) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [totalScrollPosition, setTotalScrollPosition] = useState(0);
  const [isScrollBottom, setScrollBottom] = useState(false);
  const [elementsToRender, setElementsToRender] = useState<ReactNode[]>([]);
  const [container, setContainer] = useState<any>(undefined);

  const onScroll = useCallback(
    (e: any) => {
      debounce(() => {
        setScrollPosition(e?.target?.scrollTop);

        if (e?.target.scrollTop > totalScrollPosition) {
          setTotalScrollPosition(e?.target?.scrollTop);
        }
      }, 50);
    },
    [setScrollPosition, setTotalScrollPosition, totalScrollPosition]
  );

  useEffect(() => {
    if (elements.length === 0) {
      return;
    }

    // this amount is an approximation.
    const amountOfElements =
      Math.round(
        ((scrollPosition < totalScrollPosition && totalScrollPosition
          ? totalScrollPosition
          : scrollPosition) + container?.height ?? 100) / elementHeight
      ) * container?.elementsByRow ?? 1;

    const endIndex = Math.min(amountOfElements, elements.length - 1);

    setScrollBottom(amountOfElements >= elements.length - 1);
    setElementsToRender(elements?.slice(0, endIndex + 1));
  }, [
    container,
    scrollPosition,
    totalScrollPosition,
    elements,
    elementHeight,
    setElementsToRender,
    setScrollBottom,
  ]);

  return {
    setContainer,
    onScroll,
    isScrollBottom,
    elementsToRender,
    setElementsToRender,
    setScrollPosition,
    setTotalScrollPosition,
  };
};

export default useRenderOnScroll;
