import { all } from 'redux-saga/effects';

import SearchQuerySaga from 'src/store/SiteSearchQuery/sagas';
import AuthSaga from 'src/store/Authentication/sagas';
import SiteSaga from 'src/store/Site/sagas';
import UploadRecordSaga from 'src/store/UploadRecord/sagas';

function* rootSagas() {
  yield all([...SearchQuerySaga]);
  yield all([...AuthSaga]);
  yield all([...SiteSaga]);
  yield all([...UploadRecordSaga]);
}

export default rootSagas;
