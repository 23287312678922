import { useCallback, useMemo } from 'react';
import { Radar } from 'ca-react-component-lib';

// types
import { CallToActionDropzoneProps } from './types';

// hooks
import { useUploadRecord } from 'src/hooks';

// radar
const { Box, Typography, useTheme, FontAwesome, Avatar, useDropzone, Link, CircularProgress } =
  Radar;

export function CallToActionDropzone({ accept, onDropped, disabled }: CallToActionDropzoneProps) {
  const theme = useTheme();
  const { sendFilesUploadRecordLoading } = useUploadRecord();

  const onDrop = useCallback(
    (droppedFiles) => {
      if (!onDropped) return;
      onDropped(droppedFiles);
    },
    [onDropped]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    maxFiles: undefined,
    onDrop,
    accept: accept,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderRadius: 4,
        backgroundColor: disabled
          ? theme.palette.primary.shade[300]
          : theme.palette.primary.shade[200],
        width: '100%',
        height: '100%',
        border: disabled
          ? 'none'
          : `1px dashed ${isDragAccept ? theme.palette.primary.main : theme.palette.divider}`,
        cursor: disabled ? 'initial' : 'pointer',
      },
    }),
    [theme, isDragAccept, disabled]
  );

  return (
    /* @ts-ignore */
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {sendFilesUploadRecordLoading ? (
        <CircularProgress size={40} />
      ) : (
        <Avatar
          sx={{
            backgroundColor: disabled ? theme.palette.text.disabled : theme.palette.primary.dark,
          }}
        >
          <FontAwesome
            sx={{ pointerEvents: 'none' }}
            icon="cloud-arrow-up"
            type="solid"
            size={theme.size.icon.huge}
          />
        </Avatar>
      )}
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Link
            underline={disabled ? 'none' : 'always'}
            variant={'subtitle1'}
            color={disabled ? theme.palette.text.disabled : undefined}
          >
            Select files
          </Link>
          <Typography
            variant={'subtitle1'}
            color={disabled ? theme.palette.text.disabled : undefined}
          >
            or drag and drop
          </Typography>
        </Box>
        <Typography
          variant={'body2'}
          color={disabled ? theme.palette.text.disabled : theme.palette.text.secondary}
        >
          JPG or JPEG (max. 50MB)
        </Typography>
      </Box>
    </div>
  );
}
