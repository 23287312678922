// store
import store from 'src/store';

// flow
import Flow from '@flowjs/flow.js';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { FileType } from 'src/types/FileTypes';
import { FolderName } from 'src/types/Folder';
import { FileStatusSettings } from 'src/types/FileStatus';

// utils
import getUnixTimestamp from 'src/utils/getUnixTimestamp';

// constants
import { ACCUMULATE_FILES_TIME_INTERVAL } from './constants';

class UploaderFolder {
  flow: any = undefined;

  uploadId: string | undefined = undefined;

  onFileProgress: Function | undefined = undefined;

  uploadRecord: UploadRecord | undefined = undefined;

  folderName: FolderName | undefined = undefined;

  files: FileType[] = [];

  // used to accumulate files inside the timer
  uploadedFiles: FileType[] = [];

  // used to accumulate files inside the timer.
  timer: any = undefined;

  constructor(
    uploadRecord: UploadRecord,
    folderName: FolderName,
    onFileProgress: Function,
    onFilesUploaded: Function,
    onFolderUploaded: Function
  ) {
    this.uploadId = uploadRecord.upload?.id;
    this.files = uploadRecord.files;
    this.onFileProgress = onFileProgress;
    this.folderName = folderName;
    this.uploadedFiles = [];
    this.timer = undefined;

    try {
      this.flow = new Flow({
        target: uploadRecord.signedUrl?.url,
        testChunks: false,
        forceChunkSize: true,
        setChunkTypeFromFile: true,
        prioritizeFirstAndLastChunk: false,
        chunkRetryInterval: 1000,
        progressCallbacksInterval: 1500,
        query: (flowFile, chunk) => {
          chunk.getParams = () => {};
          const key = uploadRecord?.signedUrl?.fields.key.replace(
            // eslint-disable-next-line no-template-curly-in-string
            '${filename}',
            flowFile.uniqueIdentifier
          );
          return {
            AWSAccessKeyId: uploadRecord?.signedUrl?.fields?.awsAccessKeyId,
            'Content-Type': uploadRecord?.signedUrl?.fields?.contentType,
            key: key,
            policy: uploadRecord?.signedUrl?.fields?.policy,
            signature: uploadRecord?.signedUrl?.fields?.signature,
          };
        },
        successStatuses: [200, 202, 204],
        chunkSize: Number.MAX_VALUE,
        generateUniqueIdentifier: (fileFlow) => {
          const fileType: FileType | undefined = uploadRecord.files.find(
            (fileType: FileType) => fileType.name === fileFlow.name
          );
          if (!fileType) {
            console.error('Failed to generate unique identifier. Invalid upload record files.');
          }
          return fileType?.path;
        },
      });

      this.flow.on('fileSuccess', (file) => {
        let uploadedFile: FileType | undefined = this.getFileType(uploadRecord, file);
        if (!uploadedFile) {
          return;
        }

        uploadedFile.uploadedAt = getUnixTimestamp(new Date());

        // timer is running
        if (this.timer) {
          this.uploadedFiles.push(uploadedFile);
        } else {
          this.uploadedFiles.push(uploadedFile);

          this.timer = setTimeout(() => {
            onFilesUploaded(this.uploadId, this.uploadedFiles);

            this.timer = undefined;
            clearTimeout(this.timer);

            // Upload complete.
            if (this.flow.progress() === 1) {
              onFolderUploaded(uploadRecord.upload?.id, folderName);
            }
          }, ACCUMULATE_FILES_TIME_INTERVAL);
        }
      });
      this.flow.on('fileProgress', (file) => {
        let updatedUploadRecord = store
          .getState()
          .UploadRecord.uploadRecords.find(
            (uploadRecord: UploadRecord) => uploadRecord.upload?.id === this.uploadId
          );

        const fileType = this.getFileType(updatedUploadRecord, file);
        if (!fileType || !updatedUploadRecord) {
          return;
        }

        fileType.progress = file.progress();
        fileType.sizeUploaded = file.sizeUploaded();
        fileType.timeRemaining = file.timeRemaining();
        fileType.averageSpeed = file.averageSpeed;
        fileType.currentSpeed = file.currentSpeed;

        if (!fileType.activeAt) {
          fileType.activeAt = getUnixTimestamp(new Date());
          fileType.status = FileStatusSettings.active.value;

          onFileProgress(fileType, updatedUploadRecord);
        }
      });

      this.flow.on('error', (message, file: any) => {
        setTimeout(() => {
          file.debounceTimeout = Math.min((file.debounceTimeout || 1000) * 2, 30000); // every 2, 4, 8, 16, 30, 30, 30... seconds
          file.retry();
        }, file.debounceTimeout || 1000);
      });

      uploadRecord.files.forEach((fileType: FileType) => {
        const blob = fileType.file;
        const pathToS3 = fileType.path;

        if (blob && pathToS3) {
          this.flow.addFile(fileType.file);
        } else {
          console.error('Tried to add invalid files');
        }
      });

      window.addEventListener("online", () => this.flow.upload());
    } catch (err) {
      console.log('uploadOneSingleFile:', err);
    }
  }

  isUploading = () => this.flow.isUploading();

  upload = () => {
    this.flow.upload();
  };

  pause() {
    if (this.flow) {
      this.flow.pause();
    } else {
      console.error('Flow not instantiated to pause.');
    }
  }

  resume() {
    if (this.flow) {
      this.flow.resume();

      this.flow.files.forEach((file) => {
        if (!file.isComplete() && this.onFileProgress && this.uploadRecord && this.folderName) {
          this.onFileProgress(this.getFileType(this.uploadRecord, file));
        }
      });
    } else {
      console.error('Flow not instantiated to resume.');
    }
  }

  cancel() {
    if (this.flow) {
      this.flow.off();
      this.flow.cancel();
    } else {
      console.error('Flow not instantiated to cancel.');
    }
  }

  getFileType(uploadRecord: UploadRecord | undefined, file): FileType | undefined {
    if (!uploadRecord) {
      return undefined;
    }

    return uploadRecord.files.find((fileType: FileType) => fileType.path === file.uniqueIdentifier);
  }
}

export default UploaderFolder;
