import { UploadIconVariantType } from './UploadIcons';

export type FolderStatus = 'complete' | 'failed';

export const FolderStatusSettings = {
  complete: {
    iconVariant: 'complete' as UploadIconVariantType,
    value: 'complete' as FolderStatus,
  },
  failed: {
    iconVariant: 'failed' as UploadIconVariantType,
    value: 'failed' as FolderStatus,
  },
};
