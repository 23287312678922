import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { Upload } from 'src/__generated__/graphql';

// hooks
import useUploadRecordUtils from './useUploadRecordUtils';

// reducer
import actions from 'src/store/UploadRecord/actions';

// api
import mutationCancelUploadRecord from '../api/mutationCancelUploadRecord';

// types
import { UploadRecord } from 'src/types/UploadRecord';

const useCancelUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { updateUploadRecordsByUpload } = useUploadRecordUtils();

  const cancelUploadRecord = useCallback(
    (uploadId: string, uploadRecords: UploadRecord[]) => {
      const handleCancelUpload = async () => {
        try {
          dispatch(actions.setCancelUploadRecordLoading(true));

          const uploadResponse: Upload = await mutationCancelUploadRecord(client, uploadId);
          updateUploadRecordsByUpload(uploadResponse, uploadRecords);
        } catch (error) {
          dispatch(actions.setCancelUploadRecordError(error));
        } finally {
          dispatch(actions.setCancelUploadRecordLoading(false));
        }
      };

      handleCancelUpload();
    },
    [client, dispatch, updateUploadRecordsByUpload]
  );

  return {
    cancelUploadRecord,
  };
};

export default useCancelUploadRecord;
