import request from '../request';
import endpoints from './endpoints';

const api = {
  fetchProfile: async () =>
    request(endpoints.getProfile, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }),
};

export default api;
