import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import actions from 'src/store/Profile/actions';

// selectors
import profileSelectors from 'src/store/Profile/selectors';

const useProfile = () => {
  const profile = useSelector(profileSelectors.getUserData);
  const displayName = useSelector(profileSelectors.getDisplayName);
  const profileId = useSelector(profileSelectors.getProfileId);
  const error = useSelector(profileSelectors.getError);

  // This companies belongs to the user.
  const companies = useSelector(profileSelectors.getCompanies);

  const dispatch = useDispatch();

  const setError = useCallback(
    (error: Error | undefined) => {
      dispatch(actions.setError(error));
    },
    [dispatch]
  );

  return {
    profile,
    profileId,
    companies,
    displayName,
    error,
    setError,
  };
};

export default useProfile;
