import { FileType } from 'src/types/FileTypes';
import { FileInput } from 'src/__generated__/graphql';

const createFileInput = (fileType: FileType[]): FileInput[] =>
  fileType.map((fileType: FileType) => ({
    id: fileType.id,
    path: fileType.path,
    size: fileType.size,
    activeAt: fileType.activeAt,
    uploadedAt: fileType.uploadedAt,
  }));

export default createFileInput;
