import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import { FileListInput } from 'src/__generated__/graphql';
import { UploadRecord } from 'src/types/UploadRecord';
import { FileType } from 'src/types/FileTypes';
import { FolderName, Folders } from 'src/types/Folder';
import { FileStatusSettings } from 'src/types/FileStatus';
import { UploadStatusSettings } from 'src/types/UploadStatus';

// hooks
import { useUploadFiles, useUploadRecord } from 'src/hooks';

// reducer
import Store from 'src/store';
import uploaderSelectors from 'src/store/Uploader/selectors';
import uploaderActions from 'src/store/Uploader/actions';
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';
import uploadRecordActions from 'src/store/UploadRecord/actions';

// utils
import createFileInput from 'src/utils/createFileInput';
import getUploadRecordFilesCountByStatus from 'src/utils/getUploadRecordFilesCountByStatus';

// class
import UploaderFolder from './UploaderFolder';

const useUploader = () => {
  const dispatch = useDispatch();
  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);
  const uploaderFolders: UploaderFolder[] = useSelector(uploaderSelectors.getUploaderFolders);

  const { startUploadRecord } = useUploadRecord();
  const { uploadedUploadFiles, cancelUploadFiles, cancelMultipleUploadFiles } = useUploadFiles();

  const getUploaderFoldersById = useCallback(
    (
      uploadId: string | undefined,
      folderName: FolderName | undefined = undefined
    ): UploaderFolder[] => {
      const { uploaderFolders } = Store.getState().Uploader;

      if (folderName) {
        return uploaderFolders.filter(
          (uploaderFolder: UploaderFolder) =>
            uploaderFolder.uploadId === uploadId && uploaderFolder.folderName === folderName
        );
      }

      return uploaderFolders.filter(
        (uploaderFolder: UploaderFolder) => uploaderFolder.uploadId === uploadId
      );
    },
    []
  );

  const deleteUploaderFoldersById = useCallback(
    (uploadId: string | undefined, folderName: FolderName | undefined = undefined) => {
      const { uploaderFolders } = Store.getState().Uploader;
      let filteredUploaderFolders: UploaderFolder[];

      if (folderName) {
        filteredUploaderFolders = uploaderFolders.filter(
          (uploaderFolder: UploaderFolder) =>
            uploaderFolder.uploadId !== uploadId && folderName === uploaderFolder.folderName
        );
      } else {
        filteredUploaderFolders = uploaderFolders.filter(
          (uploaderFolder: UploaderFolder) => uploaderFolder.uploadId !== uploadId
        );
      }

      dispatch(uploaderActions.setUploaderFolders(filteredUploaderFolders));
    },
    [dispatch]
  );

  const getCancelableById = useCallback(
    (uploadRecord: UploadRecord | undefined) => {
      if (!uploadRecord?.upload) {
        console.error(`Tried to cancel with invalid upload: "${uploadRecord?.upload?.name}"`);
        return;
      }

      let cancelable: FileListInput = {
        files: [],
        uploadId: uploadRecord.upload.id,
      };

      // get upload folders by upload record id.
      const uploaderFolders = getUploaderFoldersById(uploadRecord.upload.id);

      // create `FileListInput` object based on the upload folders.
      // cancel each upload
      uploaderFolders.forEach((uploaderFolder: UploaderFolder) => {
        if (uploaderFolder.isUploading()) {
          uploaderFolder.cancel();
          if (cancelable.uploadId === uploaderFolder.uploadId) {
            cancelable.files = [...cancelable.files, ...createFileInput(uploaderFolder.files)];
          } else {
            cancelable.files = createFileInput(uploaderFolder.files);
          }
        }
      });

      // append to cancelable the failed files.
      const failedFiles = uploadRecord.files.filter(
        (fileType: FileType) => fileType.status === FileStatusSettings.failed.value
      );
      cancelable = {
        ...cancelable,
        files: [...cancelable.files, ...createFileInput(failedFiles)],
      };

      if (cancelable.files.length > 0) {
        // delete the upload folders by upload record id.
        deleteUploaderFoldersById(uploadRecord.upload.id);

        return cancelable;
      }
    },
    [getUploaderFoldersById, deleteUploaderFoldersById]
  );

  const handleFolderUploaded = useCallback(
    (uploadId: string, folderName: FolderName | undefined) => {
      // Remove uploader folder instance since the upload is complete.
      deleteUploaderFoldersById(uploadId, folderName);
    },
    [deleteUploaderFoldersById]
  );

  const handleFileProgress = useCallback(
    (newFileType: FileType, newUploadRecord: UploadRecord) => {
      const uploadRecord: UploadRecord | undefined =
        Store.getState().UploadRecord.uploadRecords.find(
          (uploadRecord: UploadRecord) => uploadRecord.upload?.id === newUploadRecord.upload?.id
        );

      if (!uploadRecord) {
        console.error('Tried to update upload progress for invalid upload.');
        return;
      }

      uploadRecord.files = uploadRecord.files.map((fileType: FileType) =>
        fileType.id === newFileType.id ? newFileType : fileType
      );

      if (uploadRecord.status !== UploadStatusSettings.active.value) {
        uploadRecord.alreadyUploadedFiles = getUploadRecordFilesCountByStatus(
          FileStatusSettings.uploaded.value,
          uploadRecord,
          FileStatusSettings.validated.value
        );
      }

      dispatch(uploadRecordActions.setUploadRecord(uploadRecord));
    },

    [dispatch]
  );

  const handleFilesUploaded = useCallback(
    (uploadId: string, fileTypes: FileType[]) => {
      uploadedUploadFiles(uploadId, fileTypes);
    },
    [uploadedUploadFiles]
  );

  /**
   * Start upload.
   */
  const startUploaderFolder = useCallback(
    (uploadRecord: UploadRecord | undefined, folderNames: FolderName[] = []) => {
      const uploadId = uploadRecord?.upload?.id;

      if (!uploadId) {
        console.error('Tried to start upload with invalid upload.');
        return;
      }
      if (uploadRecord.files.length === 0) {
        return;
      }
      if (!uploadRecord.signedUrl) {
        console.error('Tried to start upload with invalid signed url.');
        return;
      }

      const newUploaderFolders: UploaderFolder[] = [];

      const folders =
        folderNames.length === 0
          ? Object.values(Folders).map((folder) => folder.name)
          : folderNames;

      folders.forEach((folder) => {
        const fileTypes: FileType[] = uploadRecord.files.filter((fileType: FileType) => {
          const isValid =
            (fileType.status === FileStatusSettings.paused.value ||
              fileType.status === FileStatusSettings.pendingUploading.value) &&
            fileType.folderName === folder &&
            fileType.file;

          return isValid;
        });

        if (fileTypes.length === 0) {
          return;
        }

        const uploaderFolder = new UploaderFolder(
          {
            ...uploadRecord,
            files: fileTypes,
          },
          folder,
          handleFileProgress,
          handleFilesUploaded,
          handleFolderUploaded
        );
        newUploaderFolders.push(uploaderFolder);
      });
      const { uploaderFolders } = Store.getState().Uploader;
      dispatch(uploaderActions.setUploaderFolders([...uploaderFolders, ...newUploaderFolders]));

      newUploaderFolders.forEach((uploaderFolder: UploaderFolder) => {
        uploaderFolder.upload();
      });
      startUploadRecord(uploadId);
    },
    [dispatch, startUploadRecord, handleFileProgress, handleFilesUploaded, handleFolderUploaded]
  );

  /**
   * Cancel uploader folders from one UploadRecord.
   */
  const cancelUploaderFolder = useCallback(
    (uploadRecord: UploadRecord | undefined) => {
      const cancelable: FileListInput | undefined = getCancelableById(uploadRecord);

      if (cancelable) {
        cancelUploadFiles(cancelable, uploadRecords);
      }
    },
    [cancelUploadFiles, getCancelableById, uploadRecords]
  );

  /**
   * Cancel all uploader folders.
   */
  const cancelUploads = useCallback(() => {
    let cancelableList: FileListInput[] = [];

    uploadRecords.forEach((uploadRecord: UploadRecord) => {
      const cancelable: FileListInput | undefined = getCancelableById(uploadRecord);

      if (cancelable) {
        cancelableList.push(cancelable);
      }
    });

    if (cancelableList.length > 0) {
      cancelMultipleUploadFiles(cancelableList, uploadRecords);
    }
  }, [uploadRecords, cancelMultipleUploadFiles, getCancelableById]);

  /**
   * Check if there is any upload.
   */
  const isUploading = useMemo(
    () => !!uploaderFolders.find((uploaderFolder: UploaderFolder) => uploaderFolder.isUploading()),
    [uploaderFolders]
  );

  return {
    startUploaderFolder,
    deleteUploaderFoldersById,
    cancelUploaderFolder,
    cancelUploads,
    isUploading,
    uploaderFolders,
  };
};

export default useUploader;
