import { combineReducers } from 'redux';

import UploadRecordReducer from './UploadRecord/reducer';
import SiteSearchQueryReducer from './SiteSearchQuery/reducer';
import AuthenticationReducer from './Authentication/reducer';
import SiteReducer from './Site/reducer';
import ProfileReducer from './Profile/reducer';
import UploaderReducer from './Uploader/reducer';
import UploadFilesReducer from './UploadFiles/reducer';

const reducers = combineReducers({
  UploadRecord: UploadRecordReducer,
  SiteSearchQuery: SiteSearchQueryReducer,
  Authentication: AuthenticationReducer,
  Site: SiteReducer,
  Profile: ProfileReducer,
  Uploader: UploaderReducer,
  UploadFiles: UploadFilesReducer,
});

export default reducers;
export type ReducerState = ReturnType<typeof reducers>;
