// api
import api from './api';

// types
import { Company } from 'src/types/Company';
import { User } from 'src/types/User';
import { UserRequestResponse } from './types';

const formatCompanyRequestResponseToCompany = (company: any | null): Company | undefined => {
  if (company) {
    return {
      id: company.Id,
      name: company.Name,
      shortName: company.ShortName,
      abbreviation: company.Abbreviation,
      website: company.Website,
      user: company.User,
      accountsPayableEmail: company.AccountsPayableEmail ?? undefined,
      billToAddress: company.BillToAddress ?? undefined,
      accountsReceivableEmail: company.AccountsReceivableEmail ?? undefined,
      billFromAddress: company.BillFromAddress,
      usersCount: company.UsersCount ?? undefined,
    };
  }

  return undefined;
};

export const parseUserRequestResponseToUser = (user: UserRequestResponse): User => ({
  id: user.id,
  company: formatCompanyRequestResponseToCompany(user.company),
  currentCompany: formatCompanyRequestResponseToCompany(user.current_company),
  email: user.email,
  firstName: user.first_name,
  lastName: user.last_name,
  invited: user.invited,
  phoneNumber: user.phone_number ?? undefined,
  dateCreated: new Date(user.date_created),
});

export const fetchProfile = async (): Promise<User> => {
  try {
    const { user } = await api.fetchProfile();
    return parseUserRequestResponseToUser(user);
  } catch (e) {
    console.error(e);
    throw e;
  }
};
