import { User } from 'src/types/User';
import {
  ProfileAction,
  ProfileActionType,
  ProfileReducer,
  ProfilePayload,
  ProfileState,
} from './types';

const emptyUserData: User = {
  id: '',
  company: undefined,
  currentCompany: undefined,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  invited: false,
};

export const initialState: ProfileState = {
  error: undefined,
  userData: emptyUserData,
  loading: false,
  companies: [],
};

const setError: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  loading: !!payload?.loading,
});

const setProfileUser: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  userData: (payload && payload.userData) ?? state.userData,
});

const setCompanies: ProfileReducer = (state: ProfileState, payload?: ProfilePayload) => ({
  ...state,
  companies: (payload && payload.companies) ?? state.companies,
});

const clearState: ProfileReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<ProfileActionType, ProfileReducer>([
  [ProfileActionType.SET_PROFILE_USER, setProfileUser],
  [ProfileActionType.SET_COMPANIES, setCompanies],
  [ProfileActionType.SET_LOADING, setLoading],
  [ProfileActionType.SET_ERROR, setError],
  [ProfileActionType.CLEAR, clearState],
]);

const reducer = (state: ProfileState = initialState, action: ProfileAction): ProfileState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
