// routes
import Router from './routes';

// theme
import { Radar } from 'ca-react-component-lib';

// components
import {
  ShareDialog,
  DeleteUploadDialog,
  UploadSettingsDialog,
  GlobalUploadWidget,
  IgnoreConfirmationDialog,
} from 'src/components';

const { RadarThemeProvider } = Radar;

export default function App() {
  return (
    <RadarThemeProvider>
      <Router />
      <ShareDialog />
      <DeleteUploadDialog />
      <UploadSettingsDialog />
      <IgnoreConfirmationDialog />
      <GlobalUploadWidget />
    </RadarThemeProvider>
  );
}
