export const UploadAlertTypes = {
  complete: {
    title: 'Upload complete',
    buttonText: 'Start a new upload',
    severity: 'success',
    variant: 'standard',
  },
  warning: {
    severity: 'error',
  },
};
