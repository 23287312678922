import { useMemo } from 'react';

// hooks
import { useUploadRecord } from 'src/hooks';

// constants
import { LabelHandlers } from './constants';

const Label = () => {
  const { uploadRecords } = useUploadRecord();

  return useMemo(() => {
    for (const LabelHandler of LabelHandlers) {
      if (LabelHandler.isMatched(uploadRecords)) {
        return LabelHandler.renderLabel();
      }
    }
    return null;
  }, [uploadRecords]);
};

export default Label;
