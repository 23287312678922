import { UploadControlButton } from 'src/components';
import { useUploader, useUploadFiles } from 'src/hooks';

const Cancel = () => {
  const { cancelUploads } = useUploader();
  const { cancelUploadFilesLoading } = useUploadFiles();

  return (
    <UploadControlButton
      variant={'cancel'}
      component={'button'}
      buttonLabel={'Cancel all'}
      onClick={cancelUploads}
      loading={cancelUploadFilesLoading}
    />
  );
};

export default Cancel;
