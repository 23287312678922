// ca
import { Radar } from 'ca-react-component-lib';

// constants
import { UploadMenus } from './constants';

// hooks
import { useShareDialog, useDeleteDialog, useUploadSettingsDialog } from 'src/hooks';

// types
import { UploadMenuProps, UploadMenuType } from './types';

const { CustomMenu } = Radar;

const UploadMenu = ({ open, onClose, anchorOrigin, transformOrigin, upload }: UploadMenuProps) => {
  const shareDialog = useShareDialog();

  const uploadSettingDialog = useUploadSettingsDialog();

  const deleteDialog = useDeleteDialog();

  const handleItemClicked = (value: UploadMenuType, event: any) => {
    event.stopPropagation();

    if (UploadMenus.Share.value === value && upload?.name && upload?.id) {
      shareDialog.setOpen(true, {
        name: upload.name,
        id: upload.id,
      });
    }

    if (UploadMenus.Setting.value === value) {
      uploadSettingDialog.setOpen(true, {
        uploadName: upload?.name,
        uploadId: upload?.id,
        groupId: upload?.groupId,
        externalId: upload?.externalId,
        variant: 'edit',
      });
    }

    if (UploadMenus.Delete.value === value && upload?.name && upload?.id) {
      deleteDialog.setOpen(true, {
        name: upload.name,
        id: upload.id,
        totalFilesCount: upload.totalFilesCount || 0,
      });
    }

    onClose(event);
  };

  return (
    <CustomMenu
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      items={Object.entries(UploadMenus).map((item) => item[1])}
      onItemClick={handleItemClicked}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    />
  );
};

export { UploadMenu };
