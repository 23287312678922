import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.SiteSearchQuery;

const getQuickSearchQueryResult = (state: ApplicationState) =>
  _getData(state).quickSearchQueryResult;
const getQuickSearchLoading = (state: ApplicationState) => _getData(state).isQuickSearchLoading;

const selectors = {
  getQuickSearchQueryResult,
  getQuickSearchLoading,
};

export default selectors;
