// types
import { UploadFilesAction, UploadFilesActionType } from './types';

const actions = {
  // uploaded upload files
  setUploadedUploadFilesLoading: (uploadedUploadFilesLoading: boolean): UploadFilesAction => ({
    payload: { uploadedUploadFilesLoading },
    type: UploadFilesActionType.SET_UPLOADED_UPLOAD_FILES_LOADING,
  }),

  setUploadedUploadFilesError: (uploadedUploadFilesError?: Error): UploadFilesAction => ({
    payload: { uploadedUploadFilesError },
    type: UploadFilesActionType.SET_UPLOADED_UPLOAD_FILES_ERROR,
  }),

  // pause upload files
  setPauseUploadFilesLoading: (cancelUploadFilesLoading: boolean): UploadFilesAction => ({
    payload: { cancelUploadFilesLoading },
    type: UploadFilesActionType.SET_PAUSE_UPLOAD_FILES_LOADING,
  }),

  setPauseUploadFilesError: (cancelUploadFilesError: Error): UploadFilesAction => ({
    payload: { cancelUploadFilesError },
    type: UploadFilesActionType.SET_PAUSE_UPLOAD_FILES_ERROR,
  }),

  // cancel upload files
  setCancelUploadFilesLoading: (cancelUploadFilesLoading: boolean): UploadFilesAction => ({
    payload: { cancelUploadFilesLoading },
    type: UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_LOADING,
  }),

  setCancelUploadFilesSuccess: (cancelUploadFilesSuccess: boolean): UploadFilesAction => ({
    payload: { cancelUploadFilesSuccess },
    type: UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_SUCCESS,
  }),

  setCancelUploadFilesError: (cancelUploadFilesError: Error): UploadFilesAction => ({
    payload: { cancelUploadFilesError },
    type: UploadFilesActionType.SET_CANCEL_UPLOAD_FILES_ERROR,
  }),

  // delete upload files
  setDeleteUploadFilesLoading: (deleteUploadFilesLoading: boolean): UploadFilesAction => ({
    payload: { deleteUploadFilesLoading },
    type: UploadFilesActionType.SET_DELETE_UPLOAD_FILES_LOADING,
  }),

  setDeleteUploadFilesError: (deleteUploadFilesError?: Error): UploadFilesAction => ({
    payload: { deleteUploadFilesError },
    type: UploadFilesActionType.SET_DELETE_UPLOAD_FILES_ERROR,
  }),

  // replace upload files
  setReplaceUploadFilesLoading: (replaceUploadFilesLoading: boolean): UploadFilesAction => ({
    payload: { replaceUploadFilesLoading },
    type: UploadFilesActionType.SET_REPLACE_UPLOAD_FILES_LOADING,
  }),

  setReplaceUploadFilesError: (replaceUploadFilesError?: Error): UploadFilesAction => ({
    payload: { replaceUploadFilesError },
    type: UploadFilesActionType.SET_REPLACE_UPLOAD_FILES_ERROR,
  }),

  clearState: (): UploadFilesAction => ({
    type: UploadFilesActionType.CLEAR,
  }),
};

export default actions;
