import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { Upload } from 'src/__generated__/graphql';

// reducer
import actions from 'src/store/UploadRecord/actions';
import store from 'src/store';

// api
import mutationCompleteUploadRecord from '../api/mutationCompletedUploadRecord';

// types
import { UploadRecord } from 'src/types/UploadRecord';

const useCompleteUploadRecord = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const completeUploadRecord = useCallback(
    (
      uploadId: string
    ) => {
      const handleCompleteUpload = async () => {
        try {
          dispatch(actions.setCompleteUploadRecordLoading(true));

          const upload: Upload = await mutationCompleteUploadRecord(client, uploadId);
          const uploadRecords = store.getState().UploadRecord.uploadRecords;
          const uploadRecord: UploadRecord | undefined = store
            .getState()
            .UploadRecord.uploadRecords.find(
              (uploadRecord: UploadRecord) => uploadRecord.upload?.id === uploadId
            );

          if (uploadRecord) {
            dispatch(
              actions.setUploadRecord({
                ...uploadRecord,
                upload: upload,
              })
            );
            dispatch(
              actions.setUploadRecords(uploadRecords.map((UR: any) => (UR.upload.id === upload.id) ? {
                ...UR,
                status: upload.status,
                upload
              } : UR))
            );
          }
        } catch (error) {
          dispatch(actions.setCompleteUploadRecordError(error));
        } finally {
          dispatch(actions.setCompleteUploadRecordLoading(false));
        }
      };

      handleCompleteUpload();
    },
    [
      client,
      dispatch,
    ]
  );

  return {
    completeUploadRecord,
  };
};

export default useCompleteUploadRecord;
