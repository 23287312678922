import { FileStatus, FileStatusSettings } from 'src/types/FileStatus';

const useFileStatus = () => {
  const getFileStatusSetting = (fileStatus: FileStatus) => {
    const fileStatusSetting = Object.values(FileStatusSettings).find(
      (fileStatusSetting) => fileStatusSetting.value === fileStatus
    );
    
    if (!fileStatusSetting) {
      console.error(`Tried to get invalid file status setting: ${fileStatus}`);
      return FileStatusSettings.pendingUploading;
    }
    
    return fileStatusSetting;
  };

  const getFileStatus = (status: string): FileStatus => {
    const settings = Object.values(FileStatusSettings).find(
      (fileStatusSetting) => fileStatusSetting.value === status
    );

    if (settings) {
      return status as FileStatus;
    } else {
      return FileStatusSettings.active.value;
    }
  };

  return {
    getFileStatusSetting,
    getFileStatus,
  };
};

export default useFileStatus;
