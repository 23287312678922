import React, { createContext, useState } from 'react';

// types
import { GlobalUploadWidgetProviderProps, GlobalUploadWidgetContextProps } from './types';

const initialState: GlobalUploadWidgetContextProps = {
  open: true,
  setOpen: () => {},
  expanded: false,
  setExpanded: () => {},
};

const GlobalUploadWidgetContext = createContext(initialState);

const GlobalUploadWidgetProvider = ({ children }: GlobalUploadWidgetProviderProps) => {
  const [open, setOpen] = useState(initialState.open);
  const [expanded, setExpanded] = useState(initialState.expanded);

  return (
    <GlobalUploadWidgetContext.Provider
      value={{
        open,
        setOpen,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </GlobalUploadWidgetContext.Provider>
  );
};

export { GlobalUploadWidgetContext, GlobalUploadWidgetProvider };
