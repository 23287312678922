import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import authenticationActions from 'src/store/Authentication/actions';

// selectors
import authenticationSelectors from 'src/store/Authentication/selectors';

const useAuth = () => {
  const isAuthenticated = useSelector(authenticationSelectors.isAuthenticated);
  const isInitialized = useSelector(authenticationSelectors.isInitialized);
  const isLoading = useSelector(authenticationSelectors.isLoading);
  const error = useSelector(authenticationSelectors.getError);

  const dispatch = useDispatch();

  useEffect(() => {
    // auto login when an auth token already stored.
    if (!isInitialized) {
      dispatch(authenticationActions.initialize());
    }
  }, [isInitialized, dispatch]);

  const login = useCallback(
    (email: string, password: string) => {
      dispatch(authenticationActions.login(email, password));
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    dispatch(authenticationActions.logout());
  }, [dispatch]);

  return {
    isAuthenticated,
    isLoading,

    isInitialized,
    error,

    login,
    logout,
  };
};

export default useAuth;
