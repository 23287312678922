import {
  UploaderAction,
  UploaderActionType,
  UploaderReducer,
  UploaderPayload,
  UploaderState,
} from './types';

export const initialState: UploaderState = {
  uploaderFolders: [],
};

const setUploaderFolders: UploaderReducer = (state: UploaderState, payload?: UploaderPayload) => ({
  ...state,
  uploaderFolders: payload?.uploaderFolders ?? initialState.uploaderFolders,
});

const clearState: UploaderReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<UploaderActionType, UploaderReducer>([
  [UploaderActionType.SET_UPLOADER_FOLDERS, setUploaderFolders],
  [UploaderActionType.CLEAR, clearState],
]);

const reducer = (state: UploaderState = initialState, action: UploaderAction): UploaderState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
