// types
import { UploaderFilter, UploadRecord } from 'src/types/UploadRecord';
import { UploadRecordAction, UploadRecordActionType } from './types';
import { Upload } from 'src/__generated__/graphql';

const actions = {
  setUploadRecordId: (uploadRecordId: string | undefined): UploadRecordAction => ({
    payload: { uploadRecordId },
    type: UploadRecordActionType.SET_UPLOAD_RECORD_ID,
  }),

  setUploadRecord: (uploadRecord: UploadRecord, operation?: string): UploadRecordAction => ({
    payload: { uploadRecord, operation },
    type: UploadRecordActionType.SET_UPLOAD_RECORD,
  }),
  
  setUploadRecords: (uploadRecords: UploadRecord[]): UploadRecordAction => ({
    payload: { uploadRecords },
    type: UploadRecordActionType.SET_UPLOAD_RECORDS,
  }),
  
  updateUploadRecords: (uploadRecords: UploadRecord[]): UploadRecordAction => ({
    payload: { uploadRecords },
    type: UploadRecordActionType.UPDATE_UPLOAD_RECORDS,
  }),

  // get upload records
  setFetchUploadRecordsLoading: (fetchUploadRecordsLoading: boolean): UploadRecordAction => ({
    payload: { fetchUploadRecordsLoading },
    type: UploadRecordActionType.SET_FETCH_UPLOAD_RECORDS_LOADING,
  }),

  setFetchUploadRecordsError: (fetchUploadRecordsError?: Error): UploadRecordAction => ({
    payload: { fetchUploadRecordsError },
    type: UploadRecordActionType.SET_FETCH_UPLOAD_RECORDS_ERROR,
  }),

  // get upload record
  setFetchUploadRecordLoading: (fetchUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { fetchUploadRecordLoading },
    type: UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_LOADING,
  }),

  setFetchUploadRecordSuceess: (fetchUploadRecordSuccess: boolean): UploadRecordAction => ({
    payload: { fetchUploadRecordSuccess },
    type: UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_SUCCESS,
  }),

  setFetchUploadRecordError: (fetchUploadRecordError?: Error): UploadRecordAction => ({
    payload: { fetchUploadRecordError },
    type: UploadRecordActionType.SET_FETCH_UPLOAD_RECORD_ERROR,
  }),

  // create upload
  watcherSetUploadRecord: (): UploadRecordAction => ({
    type: UploadRecordActionType.WATCHER_SET_UPLOAD_RECORD,
  }),

  setCreateUploadRecordLoading: (createUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { createUploadRecordLoading },
    type: UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_LOADING,
  }),

  setCreateUploadRecordSuccess: (createUploadRecordSuccess?: Upload): UploadRecordAction => ({
    payload: { createUploadRecordSuccess },
    type: UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_SUCCESS,
  }),

  setCreateUploadRecordError: (createUploadRecordError?: Error): UploadRecordAction => ({
    payload: { createUploadRecordError },
    type: UploadRecordActionType.SET_CREATE_UPLOAD_RECORD_ERROR,
  }),

  // start upload
  setStartUploadRecordLoading: (startUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { startUploadRecordLoading },
    type: UploadRecordActionType.SET_START_UPLOAD_RECORD_LOADING,
  }),

  setStartUploadRecordError: (startUploadRecordError?: Error): UploadRecordAction => ({
    payload: { startUploadRecordError },
    type: UploadRecordActionType.SET_START_UPLOAD_RECORD_ERROR,
  }),

  // pause upload
  setPauseUploadRecordLoading: (pauseUploadRecordLoading?: boolean): UploadRecordAction => ({
    payload: { pauseUploadRecordLoading },
    type: UploadRecordActionType.SET_PAUSE_UPLOAD_RECORD_LOADING,
  }),

  setPauseUploadRecordError: (pauseUploadRecordError?: Error): UploadRecordAction => ({
    payload: { pauseUploadRecordError },
    type: UploadRecordActionType.SET_PAUSE_UPLOAD_RECORD_ERROR,
  }),

  // delete upload
  setDeleteUploadRecordLoading: (deleteUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { deleteUploadRecordLoading },
    type: UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_LOADING,
  }),

  setDeleteUploadRecordSuccess: (deleteUploadRecordSuccess?: Upload): UploadRecordAction => ({
    payload: { deleteUploadRecordSuccess },
    type: UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_SUCCESS,
  }),

  setDeleteUploadRecordError: (deleteUploadRecordError?: Error): UploadRecordAction => ({
    payload: { deleteUploadRecordError },
    type: UploadRecordActionType.SET_DELETE_UPLOAD_RECORD_ERROR,
  }),

  // cancel upload
  setCancelUploadRecordLoading: (cancelUploadRecordLoading?: boolean): UploadRecordAction => ({
    payload: { cancelUploadRecordLoading },
    type: UploadRecordActionType.SET_CANCEL_UPLOAD_RECORD_LOADING,
  }),

  setCancelUploadRecordError: (cancelUploadRecordError?: Error): UploadRecordAction => ({
    payload: { cancelUploadRecordError },
    type: UploadRecordActionType.SET_CANCEL_UPLOAD_RECORD_ERROR,
  }),

  // complete upload
  setCompleteUploadRecordLoading: (completeUploadRecordLoading?: boolean): UploadRecordAction => ({
    payload: { completeUploadRecordLoading },
    type: UploadRecordActionType.SET_COMPLETE_UPLOAD_RECORD_LOADING,
  }),

  setCompleteUploadRecordError: (completeUploadRecordError?: Error): UploadRecordAction => ({
    payload: { completeUploadRecordError },
    type: UploadRecordActionType.SET_COMPLETE_UPLOAD_RECORD_ERROR,
  }),

  // upload record filters
  setUploaderFilter: (uploaderFilter?: UploaderFilter): UploadRecordAction => ({
    payload: { uploaderFilter },
    type: UploadRecordActionType.SET_UPLOADER_FILTER,
  }),

  // send files to upload record
  setSendFilesUploadRecordLoading: (sendFilesUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { sendFilesUploadRecordLoading },
    type: UploadRecordActionType.SET_SEND_FILES_UPLOAD_RECORD_LOADING,
  }),

  setSendFilesUploadRecordError: (sendFilesUploadRecordError?: Error): UploadRecordAction => ({
    payload: { sendFilesUploadRecordError },
    type: UploadRecordActionType.SET_SEND_FILES_UPLOAD_RECORD_ERROR,
  }),

  // rename upload record
  setRenameUploadRecordLoading: (renameUploadRecordLoading: boolean): UploadRecordAction => ({
    payload: { renameUploadRecordLoading },
    type: UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_LOADING,
  }),

  setRenameUploadRecordSuccess: (renameUploadRecordSuccess?: boolean): UploadRecordAction => ({
    payload: { renameUploadRecordSuccess },
    type: UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_SUCCESS,
  }),

  setRenameUploadRecordError: (renameUploadRecordError?: Error): UploadRecordAction => ({
    payload: { renameUploadRecordError },
    type: UploadRecordActionType.SET_RENAME_UPLOAD_RECORD_ERROR,
  }),

  clearState: (): UploadRecordAction => ({
    type: UploadRecordActionType.CLEAR,
  }),
};

export default actions;
