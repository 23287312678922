import { FileStatus } from 'src/types/FileStatus';
import { UploadRecord } from 'src/types/UploadRecord';

const getUploadRecordFilesCountByStatus = (
  targetStatus: FileStatus,
  uploadRecord: UploadRecord | undefined,
  validateStatus?: FileStatus,
  folderName?: string,
) => {
  if (validateStatus) {
    const files = uploadRecord?.files?.filter(
      (file) =>
        (file.status === targetStatus || file.status === validateStatus)
        && (!folderName || folderName === file.folderName)
    );
    return files?.length || 0;
  } else {
    const files = uploadRecord?.files?.filter((file) => file.status === targetStatus);
    return files?.length || 0;
  }
};

export default getUploadRecordFilesCountByStatus;
