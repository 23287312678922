import { Radar } from 'ca-react-component-lib';

const { FontAwesome } = Radar;

export type UploadControlButtonVariantType = 'resume' | 'pause' | 'cancel' | 'info' | 'start';

export type UploadControlButtonProps = {
  component?: 'icon-button' | 'button';
  variant: UploadControlButtonVariantType;
  buttonLabel?: string;
  onClick?: () => void;
  loading?: boolean;
};

export const UploadControlButtonVariants = (theme: any) => ({
  resume: {
    buttonIcon: <FontAwesome icon={'play'} type="solid" size={theme.size.icon.large} />,
    iconButtonIcon: (
      <FontAwesome
        icon={'play'}
        type="solid"
        color={theme.palette.text.disabled}
        size={theme.size.icon.large}
      />
    ),
    tooltip: 'Resume',
  },
  pause: {
    buttonIcon: <FontAwesome icon={'pause'} size={theme.size.icon.large} />,
    iconButtonIcon: (
      <FontAwesome
        icon={'pause'}
        color={theme.palette.text.disabled}
        size={theme.size.icon.large}
      />
    ),
    tooltip: 'Pause',
  },
  cancel: {
    buttonIcon: (
      <FontAwesome icon={'xmark'} size={theme.size.icon.large} color={theme.palette.error.main} />
    ),
    iconButtonIcon: (
      <FontAwesome
        icon={'x'}
        type={'solid'}
        color={theme.palette.text.disabled}
        size={theme.size.icon.large}
      />
    ),
    tooltip: 'Cancel',
  },
  info: {
    buttonIcon: <FontAwesome icon={'circle-info'} size={theme.size.icon.large} />,
    iconButtonIcon: (
      <FontAwesome
        icon={'circle-info'}
        color={theme.palette.text.disabled}
        size={theme.size.icon.large}
      />
    ),
    tooltip: 'More info',
  },
  start: {
    buttonIcon: <FontAwesome icon={'arrow-up'} size={theme.size.icon.huge} type="solid" />,
    iconButtonIcon: (
      <FontAwesome
        icon={'arrow-up'}
        color={theme.palette.text.disabled}
        size={theme.size.icon.huge}
        type="solid"
      />
    ),
    tooltip: 'Start uploading',
  },
});
