// ca
import { Radar } from 'ca-react-component-lib';

// hooks
import { useNavigate } from 'src/hooks';

// components
import { UploadControlButton, UploadIcon, UploadProgressBar } from 'src/components';

// types
import { ControlsProps } from '../types';

// Radar DS
const { useTheme, Stack, Box } = Radar;

const Completed = ({ uploadRecord }: ControlsProps) => {
  const theme = useTheme();
  const { navigateTo } = useNavigate();

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.text.disabled,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <UploadIcon variant="complete" />
          {uploadRecord?.upload?.name || ''}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          Complete
          <UploadControlButton
            variant="info"
            onClick={() => navigateTo(uploadRecord?.upload?.id)}
          />
        </Box>
      </Box>
      <UploadProgressBar
        progress={uploadRecord.progress || 0}
        barColor={theme.palette.primary.shade[200]}
      />
    </Stack>
  );
};

export default Completed;
