import {
  AuthenticationAction,
  AuthenticationActionType,
  AuthenticationReducer,
  AuthenticationPayload,
  AuthenticationState,
} from './types';

export const initialState: AuthenticationState = {
  authenticated: false,
  error: undefined,
  loading: false,
  initialized: false,
};

const setError: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const setLoading: AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => ({
  ...state,
  loading: !!payload?.loading,
});

const setAuthenticated: AuthenticationReducer = (state: AuthenticationState) => ({
  ...state,
  authenticated: true,
});

const setInitialized: AuthenticationReducer = (state: AuthenticationState) => ({
  ...state,
  initialized: true,
});

const clearState: AuthenticationReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<AuthenticationActionType, AuthenticationReducer>([
  [AuthenticationActionType.SET_AUTHENTICATED, setAuthenticated],
  [AuthenticationActionType.SET_LOADING, setLoading],
  [AuthenticationActionType.SET_INITIALIZED, setInitialized],
  [AuthenticationActionType.SET_ERROR, setError],
  [AuthenticationActionType.CLEAR, clearState],
]);

const reducer = (
  state: AuthenticationState = initialState,
  action: AuthenticationAction
): AuthenticationState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;
