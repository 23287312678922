import React from 'react';

// ca
import { Radar, useTheme } from 'ca-react-component-lib';

// types
import { DeleteDialogContentProps } from './types';

// Radar
const { Stack, Typography, Box, Paper, FontAwesome } = Radar;

export const DeleteUploadDialogContent = ({ title, totalFilesCount }: DeleteDialogContentProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 8, width: '100%' }}>
      <Stack gap={4}>
        <Stack display={'inline'} textAlign={'center'}>
          <Typography variant="body1" display={'inline'}>
            Are you sure you want to delete
          </Typography>
          <Typography variant="h6" display={'inline'} sx={{ ml: 1 }}>
            {title}?
          </Typography>
        </Stack>
        <Paper
          sx={{
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <FontAwesome
              icon="circle-check"
              type="solid"
              color={theme.palette.success.shade[400]}
              size={theme.size.icon.huge}
              sx={{ mr: 2 }}
            />
            <Typography variant="subtitle2">{totalFilesCount} files uploaded</Typography>
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
};
