import { Radar } from 'ca-react-component-lib';
import { UploadIconSize } from 'src/components/UploadIcon/types';

const { FontAwesome, CircularProgress } = Radar;

export type UploadIconVariantType =
  | 'default'
  | 'defaultWhite'
  | 'paused'
  | 'loading'
  | 'complete'
  | 'failed'
  | 'canceled'
  | 'folder';

export const UploadIconsVariants = (theme: any, size?: UploadIconSize) => ({
  loading: <CircularProgress size={size} />,
  complete: (
    <FontAwesome
      icon="circle-check"
      size={size}
      color={theme.palette.success.main}
      type={'solid'}
    />
  ),
  failed: (
    <FontAwesome
      icon="circle-exclamation"
      size={size}
      color={theme.palette.error.main}
      type={'solid'}
    />
  ),
  canceled: (
    <FontAwesome
      icon="circle-xmark"
      size={size}
      color={theme.palette.text.disabled}
      type={'solid'}
    />
  ),
  paused: (
    <FontAwesome
      icon="circle-pause"
      size={size}
      color={theme.palette.text.secondary}
      type={'solid'}
    />
  ),
  default: (
    <FontAwesome
      icon="circle-arrow-up"
      size={size}
      color={theme.palette.primary.main}
      type={'solid'}
    />
  ),
  defaultWhite: (
    <FontAwesome
      icon="circle-arrow-up"
      size={size}
      color={theme.palette.common.white}
      type={'solid'}
    />
  ),
  folder: <FontAwesome icon="folder-open" size={size} color={theme.palette.primary.main} />,
});
