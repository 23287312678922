export enum AuthenticationActionType {
  LOGIN = 'Authentication@LOGIN',
  SET_LOADING = 'Authentication@SET_LOADING',
  SET_AUTHENTICATED = 'Authentication@SET_AUTHENTICATED',
  SET_ERROR = 'Authentication@SET_ERROR',
  SET_INITIALIZED = 'Authentication@SET_INITIALIZED',
  INITIALIZE = 'Authentication@INITIALIZE',
  LOGOUT = 'Authentication@LOGOUT',
  CLEAR = 'Authentication@CLEAR',
}

export type AuthenticationState = {
  loading: boolean;
  error: undefined | Error;
  authenticated: boolean;
  initialized: boolean;
};

export type AuthenticationPayload = {
  email?: string;
  password?: string;
  loading?: boolean;
  error?: undefined | Error;
  authenticated?: boolean;
  initialized?: boolean;
  token?: string;
  refreshToken?: string;
};

export type AuthenticationAction = {
  payload?: AuthenticationPayload;
  type: AuthenticationActionType;
};

export type AuthenticationReducer = (
  state: AuthenticationState,
  payload?: AuthenticationPayload
) => AuthenticationState;

export enum AuthenticationError {
  LOGIN = 'Login',
  REGISTER = 'Register',
  VALIDATE = 'Validate',
}
