import { useCallback } from 'react';
import { SelectChangeEvent, Radar } from 'ca-react-component-lib';

// hooks
import { useUploadSettingsDialog } from 'src/hooks';

// constants
import { Captures } from './constants';

const { Box, FormControl, InputLabel, Select, MenuItem } = Radar;

const CapturesSelection = () => {
  const { settings, setSettings } = useUploadSettingsDialog();

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setSettings({
        ...settings,
        capture: event.target.value as string,
      });
    },
    [settings, setSettings]
  );

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size={'small'}>
        <InputLabel id="demo-simple-select-label">Capture type</InputLabel>
        <Select
          value={settings?.capture ?? ''}
          label="Capture type"
          placeholder="Select capture type"
          onChange={handleChange}
        >
          <MenuItem value={Captures.preConstruction}>{Captures.preConstruction}</MenuItem>
          <MenuItem value={Captures.postConstruction}>{Captures.postConstruction}</MenuItem>
          <MenuItem value={Captures.capture}>{Captures.capture}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default CapturesSelection;
