// storage
import {
  getAuthToken,
  setAuthToken,
  getAuthRefreshToken,
  setAuthRefreshToken,
} from 'src/storage/AuthToken';

// Used to call logout when token and refresh token are invalid.
import Store from 'src/store';
import authActions from 'src/store/Authentication/actions';

type ResponseType = 'json' | 'text' | 'arraybuffer';

const refresh = async () => {
  const authRefreshToken = getAuthRefreshToken();
  const authToken = getAuthToken();

  if (authRefreshToken && authToken) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_V2_API_ENDPOINT}/api/auth/refresh/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/plain',
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      const responseJson = await response.json();

      if (responseJson?.auth_data?.access_token && responseJson?.auth_data?.refresh_token) {
        setAuthToken(responseJson.auth_data.access_token);
        setAuthRefreshToken(responseJson.auth_data.refresh_token);
      } else {
        const { dispatch } = Store;
        dispatch(authActions.logout());
        dispatch(authActions.setError(new Error('Session expired')));
      }
    } catch (e) {
      throw new Error(e);
    }
  } else {
    throw new Error('Not possible to refresh the token. Logout.');
  }
};

const request = async (
  url: string,
  options: RequestInit,
  responseType?: ResponseType
): Promise<any> => {
  const sessionToken = getAuthToken();

  const customOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      ...(sessionToken && { authorization: `Bearer ${sessionToken}` }),
    },
  };

  try {
    const response = await fetch(url, customOptions);

    // token expired.
    if (response.status === 401) {
      await refresh();
      return await request(url, options, responseType);
    }

    // unknown fetch error.
    if (response.status !== 200) {
      const { Errors } = await response.json();
      throw new Error(Errors);
    }

    if (responseType === 'text') {
      return await response.text();
    }

    if (responseType === 'arraybuffer') {
      return await response.arrayBuffer();
    }

    const data = await response.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export default request;
