import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// apollo
import { useApolloClient } from '@apollo/client';

// graphql
import { gql } from 'src/__generated__/gql';
import { File, UploadFilesResponse } from 'src/__generated__/graphql';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus } from 'src/types/UploadStatus';
import { FileType } from 'src/types/FileTypes';

// reducer
import actions from 'src/store/UploadRecord/actions';
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';
import getCancelableFiles from 'src/utils/getCancelableFiles';

// api
import mutationCancelUploadFiles from '../../uploadFiles/api/mutationCancelUploadFiles';
import getUploadRecordStatusFromFiles from "../../../utils/getUploadRecordStatusFromFiles";

export const GET_UPLOAD = gql(`
  query getUpload($uploadId: String!) {
    getUpload(uploadId: $uploadId) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        id
        path
        size
        status
        folderName
        fileName
        activeAt
        uploadedAt
      }
      signedUrl {
        fields {
          awsAccessKeyId
          contentType
          key
          policy
          signature
        }
        url
      }
    }
  }
`);

const useGetUploadRecord = () => {
  const dispatch = useDispatch();
  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);
  const client = useApolloClient();

  const getUploadRecord = useCallback(
    (uploadId: string) => {
      const handleGetUploadRecord = async () => {

        try {
          const currentUploadRecord = uploadRecords.find(
            (ur: UploadRecord) => ur.upload?.id === uploadId
          );

          // Used to avoid unnecessary refetch for upload id.
          if (currentUploadRecord?.files.length === currentUploadRecord?.upload?.totalFilesCount)
            return;

          dispatch(actions.setFetchUploadRecordLoading(true));

          const response = await client.query<
            { getUpload: UploadFilesResponse },
            { uploadId: String }
          >({
            query: GET_UPLOAD,
            variables: { uploadId: uploadId },
          });

          if (!response.data?.getUpload?.status?.errorCode) {
            const uploadRecord = uploadRecords.find(
              (uploadRecord) => uploadRecord.upload?.id === uploadId
            );

            let fileTypes: FileType[] = updateFilesWithGQLFiles(
              response?.data?.getUpload?.files as File[],
              uploadRecord?.files
            );

            const newUploadRecord: UploadRecord = {
              files: fileTypes,
              status: response?.data?.getUpload?.upload?.status as UploadStatus,
              upload: response?.data?.getUpload.upload,
              signedUrl: response?.data?.getUpload.signedUrl,
            };

            // when application close unexpected, the application have no time to update the files statuses.
            // we have check and update the upload record based on the files statuses.
            const cancelableFiles = getCancelableFiles(uploadId, fileTypes);
            if (cancelableFiles.fileListInput.files.length !== 0) {
              await mutationCancelUploadFiles(client, cancelableFiles.fileListInput);
            }

            dispatch(
              actions.setUploadRecords(
                uploadRecords.map((uploadRecord: UploadRecord) => {
                  if (uploadRecord.upload?.id === newUploadRecord.upload?.id) {
                    newUploadRecord.status = getUploadRecordStatusFromFiles(newUploadRecord);
                    return newUploadRecord;
                  } else {
                    return uploadRecord;
                  }
                })
              )
            );

            dispatch(actions.setFetchUploadRecordSuceess(true));
            dispatch(actions.setFetchUploadRecordSuceess(false));
          } else {
            const error = new Error(
              response?.data?.getUpload?.status?.errorDetails?.message as string
            );

            dispatch(actions.setFetchUploadRecordError(error));
          }
        } catch (error) {
          dispatch(actions.setFetchUploadRecordError(error));
        } finally {
          dispatch(actions.setFetchUploadRecordLoading(false));
        }
      };

      handleGetUploadRecord();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, dispatch, uploadRecords]
  );

  return {
    getUploadRecord,
  };
};

export default useGetUploadRecord;
