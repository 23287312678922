import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// hooks
import { useUploadRecord } from 'src/hooks';

// apollo
import { useApolloClient, gql } from '@apollo/client';

// reducer
import actions from 'src/store/UploadFiles/actions';
import uploadRecordActions from 'src/store/UploadRecord/actions';

// graphql
// import { gql } from 'src/__generated__/gql';
import { FileListInput, File, UploadFilesResponse } from 'src/__generated__/graphql';

// utils
import { updateFilesWithGQLFiles } from 'src/utils/createFileType';

// types
import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';

const DELETE_UPLOAD_FILES = gql(`
  mutation deleteFiles($params: FileListInput!) {
    deleteFiles(params: $params) {
      status {
        errorCode
        errorDetails {
          message
        }
      }
      upload {
        id
        externalId
        groupId
        name
        userId
        status
        totalFilesCount
        extraContent
        lastModified
        pendingUploadingFilesCount
        canceledFilesCount
        pausedFilesCount
        uploadedFilesCount
      }
      files {
        fileName
        folderName
        id
        path
        size
        status
        activeAt
        uploadedAt
      }
      signedUrl {
        fields {
          awsAccessKeyId
          contentType
          key
          policy
          signature
        }
        url
      }
    }
  }
`);

const useDeleteUploadFiles = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { uploadRecord } = useUploadRecord();

  const deleteUploadFiles = useCallback(
    (params: FileListInput) => {
      const handleDeleteUploadFiles = async () => {
        try {
          dispatch(actions.setDeleteUploadFilesLoading(true));

          const response = await client.mutate<
            { deleteFiles: UploadFilesResponse }, // mutation response
            { params: FileListInput } // mutation input
          >({
            mutation: DELETE_UPLOAD_FILES,
            variables: { params },
          });

          if (!response?.data?.deleteFiles.status?.errorCode && uploadRecord) {
            const updatedUploadRecord: UploadRecord = {
              status:
                response?.data?.deleteFiles?.files?.length === 0
                  ? UploadStatusSettings.created.value
                  : (response?.data?.deleteFiles?.upload?.status as UploadStatus),
              upload: response?.data?.deleteFiles.upload,
              signedUrl: response?.data?.deleteFiles.signedUrl,
              files: updateFilesWithGQLFiles(
                response?.data?.deleteFiles?.files as File[],
                uploadRecord.files
              ),
            };

            dispatch(uploadRecordActions.setUploadRecord(updatedUploadRecord));
          } else {
            const error = new Error(
              response?.data?.deleteFiles?.status?.errorDetails?.message as string
            );
            dispatch(actions.setDeleteUploadFilesError(error));
          }
        } catch (error) {
          dispatch(actions.setDeleteUploadFilesError(error));
        } finally {
          dispatch(actions.setDeleteUploadFilesLoading(false));
        }
      };

      handleDeleteUploadFiles();
    },
    [dispatch, client, uploadRecord]
  );

  return {
    deleteUploadFiles,
  };
};

export default useDeleteUploadFiles;
