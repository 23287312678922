// ca
import { Radar } from 'ca-react-component-lib';

// hooks
import {
  useUploader,
  // useUploadRecord
} from 'src/hooks';

// components
import { UploadControlButton, UploadIcon, UploadProgressBar } from 'src/components';

// types
import { ControlsProps } from '../types';

// Radar DS
const { useTheme, Stack, Box } = Radar;

const Active = ({ uploadRecord }: ControlsProps) => {
  const theme = useTheme();
  // const { uploadRecords } = useUploadRecord();
  const {
    // pauseUploadFolder,
    cancelUploaderFolder,
  } = useUploader();

  // const handlePauseFiles = () => {
  //   pauseUploadFolder(uploadRecord);
  // };

  const handleCancelFiles = () => {
    cancelUploaderFolder(uploadRecord);
  };

  return (
    <Stack spacing={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: theme.palette.text.disabled,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <UploadIcon variant="folder" />
          {uploadRecord?.upload?.name || ''}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {/*<Tooltip*/}
          {/*  title={*/}
          {/*    <>*/}
          {/*      83mb / <b>3gb</b>*/}
          {/*    </>*/}
          {/*  }*/}
          {/*  arrow*/}
          {/*  placement={'top'}*/}
          {/*>*/}
          {/*  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>*/}
          {/*    {`${progress}%`}*/}
          {/*  </div>*/}
          {/*</Tooltip>*/}

          {/*<UploadControlButton variant="pause" onClick={handlePauseFiles} />*/}
          <UploadControlButton variant="cancel" onClick={handleCancelFiles} />
        </Box>
      </Box>
      <UploadProgressBar
        progress={uploadRecord.progress || 0}
        barColor={theme.palette.primary.light}
      />
    </Stack>
  );
};

export default Active;
