import React from 'react';

// ca
import { Radar } from 'ca-react-component-lib';

// types
import { ShareDialogContentProps } from './types';

// radar
const {
  Stack,
  useTheme,
  Typography,
  TextField,
  FontAwesome,
  Box,
  LinearProgress,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} = Radar;

export const ShareDialogContent = ({ copied, link, isCopying, title }: ShareDialogContentProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ mt: 8, width: '100%' }}>
      {!copied ? (
        <Stack gap={2}>
          {!isCopying ? (
            <Box sx={{ px: 1 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Copy link
              </Typography>
              <Typography variant="body2" sx={{ mb: 4 }} color={theme.palette.text.secondary}>
                People with access to Consilience App will see
              </Typography>
              <TextField
                label={'Shareable link'}
                focused={true}
                value={link}
                disabled={true}
                fullWidth
              />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress sx={{ pb: 6, justifySelf: 'center' }} />
                <Typography variant={'h6'} sx={{ my: 6 }} color={theme.palette.text.secondary}>
                  Link to '{title}' copied
                </Typography>
              </Box>
              <FormControl size="small">
                <InputLabel>Shareable link</InputLabel>
                <Select
                  label={'Shareable link'}
                  value={0}
                  disabled={true}
                  fullWidth
                  sx={{
                    '.MuiSelect-icon': {
                      display: 'none',
                    },
                    '.MuiSelect-select': {
                      p: 2,
                      pr: `${theme.spacing(2)} !important`,
                    },
                  }}
                >
                  <MenuItem
                    value={0}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <LinearProgress
                      sx={{
                        height: 18,
                        borderRadius: 5,
                        mt: 1,
                        width: '100%',
                      }}
                      variant="indeterminate"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Stack>
      ) : (
        <Stack alignItems={'center'} width={'100%'}>
          <FontAwesome
            icon={'circle-check'}
            size={50}
            type="solid"
            color={theme.palette.success.light}
            sx={{
              my: 8,
            }}
          />
          <Typography variant={'h6'}>Link to '{title}' copied</Typography>
          <TextField
            label={'Shareable link'}
            focused={true}
            value={link}
            disabled={true}
            sx={{ mt: 3 }}
            fullWidth
          />
        </Stack>
      )}
    </Box>
  );
};
