// utils
import getPercentage from 'src/utils/getPercentage';
import getUploadRecordFilesCountByStatus from 'src/utils/getUploadRecordFilesCountByStatus';

// types
import { FileStatusSettings } from 'src/types/FileStatus';
import { UploadRecord } from 'src/types/UploadRecord';
import { ProgressType } from 'src/types/ProgressType';

export const getUploadRecordProgress = (uploadRecord: UploadRecord): ProgressType | undefined => {
  const uploadedFiles = getUploadRecordFilesCountByStatus(
    FileStatusSettings.uploaded.value,
    uploadRecord,
    FileStatusSettings.validated.value
  );
  const canceledFiles = getUploadRecordFilesCountByStatus(
    FileStatusSettings.canceled.value,
    uploadRecord
  );

  let alreadyUploadedFiles = uploadRecord.alreadyUploadedFiles || 0;


  // @ts-ignore for Object.groupBy (too fresh?)
  const filesGroupedByFolder = Object.groupBy(uploadRecord.files, ({ folderName }) => folderName);

  let progressByFolder: number[] = [];
  for (const [key, value] of Object.entries(filesGroupedByFolder)) {
    const folder = value as any;
    const activeFilesCount = folder.filter(x =>
      x.status === FileStatusSettings.active.value
    ).length;
    const uploadedFilesCount = folder.filter(x =>
      x.status === FileStatusSettings.validated.value
      || x.status === FileStatusSettings.uploaded.value
    ).length;
    const pendingFilesCount = folder.filter(x => x.status === FileStatusSettings.pendingUploading.value).length;

    if (activeFilesCount > 0 || pendingFilesCount > 0) {
      progressByFolder.push(Math.min(100,
        Math.max(0,
          uploadedFilesCount / (activeFilesCount + uploadedFilesCount + pendingFilesCount) * 100
        )
      ));
    }
  }
  const progress = progressByFolder.length > 0 ? Math.floor(progressByFolder.reduce((a, b) => a + b) / progressByFolder.length) : 0;
  let progressRelative = getPercentage(
    uploadedFiles - alreadyUploadedFiles,
    uploadRecord.files.length - canceledFiles - alreadyUploadedFiles
  );

  return {
    progress: progress,
    progressRelative: progressRelative,
  };
};
