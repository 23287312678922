import { SiteAction, SiteActionType } from './types';
import { SiteListMember } from 'src/types/Site';

const actions = {
  fetchSiteListMember: (siteListMemberId: string): SiteAction => ({
    payload: { siteListMemberId },
    type: SiteActionType.FETCH_SITE_LIST_MEMBER,
  }),
  setSiteListMember: (siteListMember?: SiteListMember): SiteAction => ({
    payload: { siteListMember },
    type: SiteActionType.SET_SITE_LIST_MEMBER,
  }),
  setSiteListMemberLoading: (siteListMemberLoading: boolean): SiteAction => ({
    payload: { siteListMemberLoading },
    type: SiteActionType.SET_SITE_LIST_MEMBER_LOADING,
  }),
  setSiteListMemberError: (siteListMemberError: Error): SiteAction => ({
    payload: { siteListMemberError },
    type: SiteActionType.SET_SITE_LIST_MEMBER_ERROR,
  }),
  updateAssessment: (assessmentId: string, action: string): SiteAction => ({
    payload: { assessmentId, action },
    type: SiteActionType.UPDATE_ASSESSMENT,
  }),
  setUpdateAssessmentError: (updateAssessmentError: Error): SiteAction => ({
    payload: { updateAssessmentError },
    type: SiteActionType.SET_UPDATE_ASSESSMENT_ERROR,
  }),

  clearState: (): SiteAction => ({
    type: SiteActionType.CLEAR,
  }),
};

export default actions;
