import React, { useState } from 'react';

import { Radar } from 'ca-react-component-lib';

// routes
import { MAIN_PATHS } from 'src/routes/paths';

// hooks
import { useAuth, useProfile } from 'src/hooks';

// components
import { useNavigate } from 'react-router-dom';

const {
  alpha,
  Box,
  Typography,
  MenuItem,
  Menu,
  IconButton,
  Avatar,
  Divider,
  useTheme,
  useIsMountedRef,
} = Radar;

export default function ProfilePopover() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { logout } = useAuth();
  const { profile, displayName } = useProfile();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const isMountedRef = useIsMountedRef();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(MAIN_PATHS.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ width: theme.size.avatar.medium, height: theme.size.avatar.medium }} />
      </IconButton>

      <Menu
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
            height: '100%',
            maxHeight: `calc(100vh - ${64}px)`,
            width: '100%',
            borderRadius: 2.5,
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {displayName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {profile.email}
            </Typography>
          </Box>

          <Divider />

          <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
            Logout
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
}
