import { Radar } from 'ca-react-component-lib';
import { CHIP_SKELETON_HEIGHT, CHIP_SKELETON_WIDTH, MENU_ITEM_SKELETON_WIDTH } from './constants';

const { AppBar, Toolbar, Skeleton, LogoMini, useTheme, Avatar, Box } = Radar;

export const AppHeaderSkeleton = () => {
  const theme = useTheme();
  return (
    <AppBar>
      <Toolbar sx={{ mx: 6, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <LogoMini />
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: `${theme.spacing(16)} !important`,
              height: CHIP_SKELETON_HEIGHT,
              width: CHIP_SKELETON_WIDTH,
              mr: 8,
            }}
          />
          <Skeleton variant="rectangular" width={MENU_ITEM_SKELETON_WIDTH} />
        </Box>
        <Skeleton variant="circular">
          <Avatar sx={{ width: theme.size.avatar.medium, height: theme.size.avatar.medium }} />
        </Skeleton>
      </Toolbar>
    </AppBar>
  );
};
