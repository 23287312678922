import React, { createContext, useState } from 'react';

// type
import {
  IgnoreConfirmationDialogProps,
  IgnoreConfirmationDialogProviderProps,
  IgnoreConfirmationDialogSettings,
} from './types';

const initialState: IgnoreConfirmationDialogProps = {
  open: false,
  setOpen: () => {},
  setSettings: () => {},
  settings: undefined,
};

const IgnoreConfirmationDialogContext = createContext(initialState);

const IgnoreConfirmationDialogProvider = ({ children }: IgnoreConfirmationDialogProviderProps) => {
  const [open, setOpen] = useState(initialState.open);
  const [settings, setSettings] = useState<IgnoreConfirmationDialogSettings | undefined>(
    initialState.settings
  );

  return (
    <IgnoreConfirmationDialogContext.Provider
      value={{
        open,
        settings,
        setOpen: (open: boolean, currentSettings?: IgnoreConfirmationDialogSettings) => {
          setOpen(open);

          // if we are going to close
          if (!open) {
            setTimeout(() => {
              setSettings(initialState.settings);
            }, 1000);
          } else {
            setSettings(currentSettings);
          }
        },
        setSettings,
      }}
    >
      {children}
    </IgnoreConfirmationDialogContext.Provider>
  );
};

export { IgnoreConfirmationDialogContext, IgnoreConfirmationDialogProvider };
