import { Radar } from 'ca-react-component-lib';

// components
import { UploadIcon } from 'src/components';

// types
import { FolderStatusSettings } from 'src/types/FolderStatus';
import { UploadIconSize } from 'src/components/UploadIcon/types';
import { FolderProps } from '../types';
import { useMemo } from 'react';

const { useTheme, FontAwesome, Avatar, Button, Typography } = Radar;

export const FolderContent = ({
  active = false,
  label,
  onClick,
  icon = 'folder-image',
  status,
  isDragAccept,
  disabled,
}: FolderProps) => {
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    if (disabled) {
      return theme.palette.grey[200];
    } else if (active) {
      return theme.palette.primary.shade[300];
    } else if (isDragAccept) {
      return theme.palette.primary.shade[100];
    }
  }, [isDragAccept, disabled, active, theme]);

  const avatarColor = useMemo(() => {
    if (disabled) {
      return theme.palette.text.disabled;
    } else if (active) {
      return theme.palette.primary.main;
    } else {
      return undefined;
    }
  }, [active, disabled, theme]);

  return (
    <Button
      onClick={onClick}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        py: theme.spacing(1),
        px: theme.spacing(2),
        background: backgroundColor ?? 'transparent',
        border: isDragAccept && !disabled ? `1px dashed ${theme.palette.primary.main}` : 0,
        '&:hover': {
          backgroundColor: disabled ? theme.palette.primary.shade[400] : undefined,
        },
      }}
      startIcon={
        <Avatar
          sx={{
            width: theme.spacing(8),
            height: theme.spacing(8),
            background: avatarColor,
          }}
        >
          <FontAwesome icon={icon} size={theme.size.icon.large} type="regular" />
        </Avatar>
      }
      endIcon={
        status && (
          <UploadIcon
            variant={FolderStatusSettings[status].iconVariant}
            size={UploadIconSize.SMALL}
          />
        )
      }
    >
      <Typography variant={'body1'}>{label}</Typography>
    </Button>
  );
};
