// ca
import 'ca-react-component-lib/dist/esm/index.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'ca-react-component-lib';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { Provider as ReduxProvider } from 'react-redux';

// provider
import {
  ShareDialogProvider,
  DeleteDialogProvider,
  UploadSettingsDialogProvider,
  GlobalUploadWidgetProvider,
  IgnoreConfirmationDialogProvider,
} from 'src/provider';

import Store from './store';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Initialize Sentry only for production.
if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_UPLOADER_SERVICE_GRAPHQL || '',
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

ReactDOM.render(
  <HelmetProvider>
    <ApolloProvider client={client}>
      <ReduxProvider store={Store}>
        <ShareDialogProvider>
          <UploadSettingsDialogProvider>
            <IgnoreConfirmationDialogProvider>
              <GlobalUploadWidgetProvider>
                <BrowserRouter>
                  <DeleteDialogProvider>
                    <App />
                  </DeleteDialogProvider>
                </BrowserRouter>
              </GlobalUploadWidgetProvider>
            </IgnoreConfirmationDialogProvider>
          </UploadSettingsDialogProvider>
        </ShareDialogProvider>
      </ReduxProvider>
    </ApolloProvider>
  </HelmetProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
