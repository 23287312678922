import api from './api';

import {
  SitePageSearchQueryResultResponse,
  QC_RECORD_STATUS,
  SiteAssessment,
  SitePageSearchQueryResultDataResponse,
} from './types';
import { Vendor, SiteAssessmentImages, SiteListMember } from 'src/types/Site';

const parseSiteAssessmentsRequestResponseToSiteAssessments = (
  siteAssessments: any[] | undefined
): SiteAssessment[] | undefined => {
  if (!siteAssessments) {
    return undefined;
  }

  const parsed: any[] = [];

  siteAssessments.forEach((siteAssessment: any) => {
    parsed.push({
      clientSiteIdentifier: siteAssessment.ClientSiteIdentifier || undefined,
      date: siteAssessment.Date ? new Date(siteAssessment.Date) : undefined,
      powerProviderId: siteAssessment.PowerProviderId || undefined,
      fiberProviderId: siteAssessment.FiberProviderId || undefined,
      fiberHandHoleUri: siteAssessment.FiberHandHoleUri || undefined,
      statusId: siteAssessment.StatusId || undefined,
      storyUrl: siteAssessment.StoryUrl || undefined,
      tilesetUrl: siteAssessment.TilesetUrl || undefined,
      modelGeoReferenced: siteAssessment.ModelGeoReferenced || false,
      modelCenterAtOrigin: siteAssessment.ModelCenterAtOrigin || false,
      id: siteAssessment.Id || undefined,
      siteListMemberId: siteAssessment.SiteListMemberId || undefined,
      dataReceived: siteAssessment.DataReceived || undefined,
      dataType: siteAssessment.DataType || undefined,
      aerotriangulationStart: siteAssessment.AerotriangulationStart || undefined,
      aerotriangulationEnd: siteAssessment.AerotriangulationEnd || undefined,
      reconstructionStart: siteAssessment.ReconstructionStart || undefined,
      reconstructionEnd: siteAssessment.ReconstructionEnd || undefined,
      cadprepFileStart: siteAssessment.CadprepFileStart || undefined,
      cadprepFileEnd: siteAssessment.CadprepFileEnd || undefined,
      uploadedInternally: siteAssessment.UploadedInternally || undefined,
      uploadedToClientSite: siteAssessment.UploadedToClientSite || undefined,
      lastUserIdUpdated: siteAssessment.LastUserIdUpdated || undefined,
      cadprepFileUrl: siteAssessment.CadprepFileUrl || undefined,
      threeMXLink: siteAssessment.ThreeMXLink || undefined,
      clientId: siteAssessment.ClientId || undefined,
      uavoperatorId: siteAssessment.UavoperatorId || undefined,
      cadprepUploadedInternally: siteAssessment.CadprepUploadedInternally || undefined,
      processingStatus: siteAssessment.ProcessingStatus || undefined,
      storage: siteAssessment.Storage || undefined,
      dataTypeId: siteAssessment.DataTypeId || undefined,
      processingWorkerId: siteAssessment.ProcessingWorkerId || undefined,
      processingWorkerHostname: siteAssessment.ProcessingWorkerHostname || undefined,
      processingWorkerHostnameIp: siteAssessment.ProcessingWorkerHostnameIp || undefined,
      processingWorkerHostnameExtIp: siteAssessment.ProcessingWorkerHostnameExtIp || undefined,
      images: siteAssessment.Images || undefined,
      thumbnails: siteAssessment.Thumbnails || undefined,
      thumbnailsFailed: siteAssessment.ThumbnailsFailed || undefined,
      processingCodeVersion: siteAssessment.ProcessingCodeVersion || undefined,
      lastLogId: siteAssessment.LastLogId || undefined,
      lastLogTimestamp: siteAssessment.LastLogTimestamp || undefined,
      action: siteAssessment.Action || undefined,
      settings: siteAssessment.Settings || undefined,
      storageFolder: siteAssessment.StorageFolder || undefined,
      storageBucket: siteAssessment.StorageBucket || undefined,
      virtualTourUrl: siteAssessment.VirtualTourUrl || undefined,
      assessmentTag: siteAssessment.AssessmentTag || undefined,
      viewerUrl: siteAssessment.ViewerUrl || undefined,
      lastQcRecordStatus: siteAssessment.LastQcRecordStatus
        ? (siteAssessment.LastQcRecordStatus as QC_RECORD_STATUS)
        : QC_RECORD_STATUS.UNKNOWM,
      droneVendorCompanyId: siteAssessment.DroneVendorCompanyId || undefined,
      createdBy: siteAssessment.CreatedBy || undefined,
      qcForms: siteAssessment.QcForms || undefined,
      dateCreated: siteAssessment.DateCreated ? new Date(siteAssessment.DateCreated) : undefined,
      uavOperatorName: siteAssessment.UavOperatorName || undefined,
      flightAnalysisReportUrl: siteAssessment.FlightAnalysisReportUrl || undefined,
      files: siteAssessment.Files?.map((item) => ({
        fileFormat: item.FileFormat || undefined,
        filePurpose: item.FilePurpose || undefined,
        uri: item.Uri || undefined,
      })),
      vendor: parseVendorRequestResponseToVendor(siteAssessment.Vendor),
      setting: siteAssessment.Setting || undefined,
      siteAssessmentImages: siteAssessment.SiteAssessmentImages?.map((image: any) =>
        parseSiteImageResponseToSite(image)
      ),
      tenantId: siteAssessment.TenantId || undefined,
      exif: siteAssessment.Exif || undefined,
      zipUrl: siteAssessment.ZipUrl || undefined,
      zipJobStatus: siteAssessment.ZipJobStatus || undefined,
      dataTypeNavigation: siteAssessment.DataTypeNavigation || undefined,
      lastUserIdUpdatedNavigation: siteAssessment.LastUserIdUpdatedNavigation || undefined,
      siteListMember: siteAssessment.SiteListMember || undefined,
      tenant: siteAssessment.Tenant || undefined,
      uavoperator: siteAssessment.Uavoperator || undefined,
      tilesetSetting: siteAssessment.TilesetSetting || undefined,
      cadPrepJobQueues: siteAssessment.CadPrepJobQueues || undefined,
      coldStorageQueues: siteAssessment.ColdStorageQueues || undefined,
      fileUploadLogs: siteAssessment.FileUploadLogs || undefined,
      fileUploadRequests: siteAssessment.FileUploadLogs || undefined,
      otiqrevisions: siteAssessment.Otiqrevisions || undefined,
      processSettings: siteAssessment.ProcessSettings || undefined,
      scaleCoefficients: siteAssessment.ScaleCoefficients || undefined,
      droneVendorCompany: siteAssessment.DroneVendorCompany || undefined,
      title: siteAssessment.Title || undefined,
    });
  });

  return parsed;
};

const parseSiteImageResponseToSite = (image: any): SiteAssessmentImages => ({
  id: image.Id,
  siteAssessmentId: image.SiteAssessmentId,
  folder: image.Folder,
  file: image.File,
  thumbnail: image.Thumbnail,
  failed: image.Thumbnail,
  index: image.Index,
  points: image.Points ? parseTiePoints(image.Points) : undefined,
  latitude: image.Latitude,
  longitude: image.Longitude,
  absoluteAltitude: image.AbsoluteAltitude,
  relativeAltitude: image.RelativeAltitude,
  gimbalYawDegree: image.GimbalYawDegree,
  exif: image.Exif,
  exifJson: image.ExifJson,
  createdDate: image.CreatedDate,
  exifDateCreated: image.ExifDateCreated,
  imageMetadataRequests: image.ImageMetadataRequests,
});

const parseTiePoints = (points: string): any => {
  const parsedPoints: any = JSON.parse(points);
  return {
    Ax: parsedPoints.ax,
    Ay: parsedPoints.ay,
    Bx: parsedPoints.bx,
    By: parsedPoints.by,
  };
};

const parseVendorRequestResponseToVendor = (vendor: any | undefined): Vendor | undefined => {
  if (vendor) {
    return {
      id: vendor.Id,
      companyId: vendor.CompanyId,
      name: vendor.Name,
      userUpdateId: vendor.UserUpdateId,
    };
  }

  return undefined;
};

const parseDataItem = (dataItem: any) => ({
  addressCity: dataItem.address_city,
  addressLine1: dataItem.address_line1,
  addressState: dataItem.address_state,
  aoiCode: dataItem.aoi_code,
  assetClassName: dataItem.asset_class_name,
  companyMarket: dataItem.company_market,
  elevation: dataItem.elevation,
  elevationResolution: dataItem.elevation_resolution,
  floodZoneDesignation: dataItem.flood_zone_designation,
  id: dataItem.id,
  lastUpdateUserId: dataItem.last_update_user_id,
  latitude: dataItem.latitude,
  longitude: dataItem.longitude,
  mktId: dataItem.mkt_id,
  companySiteId: dataItem.name,
  ownerId: dataItem.owner_id,
  ownerName: dataItem.owner_id,
  ownerSiteId: dataItem.owner_site_id,
  siteListMemberId: dataItem.site_list_member_id,
  region: dataItem.region,
  siteAssessments: dataItem.assessments?.map((siteAssessment) => ({
    assessmentTag: siteAssessment.assessment_tag,
    clientSiteIdentifier: siteAssessment.client_site_identifier,
    dataReceived: siteAssessment.data_received,
    dataType: siteAssessment.data_type,
    date: siteAssessment.date,
    id: siteAssessment.id,
    lastQcRecordStatus: siteAssessment.last_qc_record_status
      ? (siteAssessment.last_qc_record_status as QC_RECORD_STATUS)
      : QC_RECORD_STATUS.UNKNOWM,
    storage: siteAssessment.storage,
    storageBucket: siteAssessment.storage_bucket,
    storageFolder: siteAssessment.storage_folder,
    storyUrl: siteAssessment.story_url,
    tenantId: siteAssessment.tenant_id,
    threeMxlink: siteAssessment.three_mx_link,
    virtualTourUrl: siteAssessment.virtual_tour_url,
  })),
  structureHeightAgl: dataItem.structure_height_agl,
  structureTypeName: dataItem.structure_type_name,
  tenantId: dataItem.tenant_id,
  tenantName: dataItem.tenant_name,
});

const parseSearchQueryResponse = (res: SitePageSearchQueryResultResponse): any => ({
  perPage: res.page_size,
  previous_page_cursor: res.previous_page_cursor,
  next_page_cursor: res.previous_page_cursor,
  total: res.total_count,
  data: res.sites.map((dataItem) => parseDataItem(dataItem)),
});

const parseSiteListMemberQueryResponse = (res: any): any => parseDataItem(res.site);

export const fetchSiteListMemberSearch = async (siteSearchQuery: any): Promise<any> => {
  try {
    const response: SitePageSearchQueryResultResponse = await api.fetchSiteListMemberSearch(
      siteSearchQuery
    );

    return parseSearchQueryResponse(response);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSiteListMember = async (siteListMemberId: string): Promise<any> => {
  try {
    const response: SitePageSearchQueryResultResponse = await api.fetchSiteListMember(
      siteListMemberId
    );
    return parseSiteListMemberQueryResponse(response);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createSiteAssessmentStaging = async (siteId, capture, uploadName): Promise<any> => {
  try {
    if (!capture) return;
    const payload = {
      assessment: {
        storage: "sitewalkdata",
        assessment_tag: capture,
        title: uploadName,
      }
    };
    const response = await api.createSiteAssessmentStaging(payload, siteId);
    return response.assessment.id;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateAssessment = async (assessmentId: string, action: string): Promise<any> => {
  try {
    const payload = {
      assessment: {
        action: action,
      }
    };
    const response = await api.updateAssessment(payload, assessmentId);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
