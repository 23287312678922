import { Upload } from 'src/__generated__/graphql';
import { UploaderFilter, UploadRecord } from 'src/types/UploadRecord';

export enum UploadRecordActionType {
  UPDATE_UPLOAD_RECORDS = 'UploadRecord@UPDATE_UPLOAD_RECORDS',
  SET_UPLOAD_RECORD = 'UploadRecord@SET_UPLOAD_RECORD',
  SET_UPLOAD_RECORDS = 'UploadRecord@SET_UPLOAD_RECORDS',
  SET_UPLOAD_RECORD_ID = 'UploadRecord@SET_UPLOAD_RECORD_ID',

  // create upload record
  WATCHER_SET_UPLOAD_RECORD = 'UploadRecord@WATCHER_SET_UPLOAD_RECORD',
  SET_CREATE_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_CREATE_UPLOAD_RECORD_LOADING',
  SET_CREATE_UPLOAD_RECORD_SUCCESS = 'UploadRecord@SET_CREATE_UPLOAD_RECORD_SUCCESS',
  SET_CREATE_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_CREATE_UPLOAD_RECORD_ERROR',

  // start upload record
  SET_START_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_START_UPLOAD_RECORD_LOADING',
  SET_START_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_START_UPLOAD_RECORD_ERROR',

  // pause upload record
  SET_PAUSE_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_PAUSE_UPLOAD_RECORD_LOADING',
  SET_PAUSE_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_PAUSE_UPLOAD_RECORD_ERROR',

  // cancel upload record
  SET_CANCEL_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_CANCEL_UPLOAD_RECORD_LOADING',
  SET_CANCEL_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_CANCEL_UPLOAD_RECORD_ERROR',

  // complete upload record
  SET_COMPLETE_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_COMPLETE_UPLOAD_RECORD_LOADING',
  SET_COMPLETE_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_COMPLETE_UPLOAD_RECORD_ERROR',

  // delete upload record
  SET_DELETE_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_DELETE_UPLOAD_RECORD_LOADING',
  SET_DELETE_UPLOAD_RECORD_SUCCESS = 'UploadRecord@SET_DELETE_UPLOAD_RECORD_SUCCESS',
  SET_DELETE_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_DELETE_UPLOAD_RECORD_ERROR',

  // get uploads record
  SET_FETCH_UPLOAD_RECORDS_LOADING = 'UploadRecord@SET_FETCH_UPLOAD_RECORDS_LOADING',
  SET_FETCH_UPLOAD_RECORDS_ERROR = 'UploadRecord@SET_FETCH_UPLOAD_RECORDS_ERROR',

  // get upload record
  SET_FETCH_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_FETCH_UPLOAD_RECORD_LOADING',
  SET_FETCH_UPLOAD_RECORD_SUCCESS = 'UploadRecord@SET_FETCH_UPLOAD_RECORD_SUCCESS',
  SET_FETCH_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_FETCH_UPLOAD_RECORD_ERROR',

  // rename upload record
  SET_RENAME_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_RENAME_UPLOAD_RECORD_LOADING',
  SET_RENAME_UPLOAD_RECORD_SUCCESS = 'UploadRecord@SET_RENAME_UPLOAD_RECORD_SUCCESS',
  SET_RENAME_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_RENAME_UPLOAD_RECORD_ERROR',

  // upload record filter
  SET_UPLOADER_FILTER = 'UploadRecord@SET_UPLOADER_FILTER',

  // send files upload record
  SET_SEND_FILES_UPLOAD_RECORD_LOADING = 'UploadRecord@SET_SEND_FILES_UPLOAD_RECORD_LOADING',
  SET_SEND_FILES_UPLOAD_RECORD_ERROR = 'UploadRecord@SET_SEND_FILES_UPLOAD_RECORD_ERROR',
  CLEAR = 'UploadRecord@CLEAR',
}

export type UploadRecordState = {
  uploadRecords: UploadRecord[];
  uploadRecordId?: string;

  // get upload records
  fetchUploadRecordsLoading: boolean;
  fetchUploadRecordsError?: Error;

  // get upload record
  fetchUploadRecordLoading: boolean;
  fetchUploadRecordSuccess: boolean;
  fetchUploadRecordError?: Error;

  // create upload record
  createUploadRecordLoading: boolean;
  createUploadRecordSuccess?: Upload;
  createUploadRecordError?: Error;

  // start upload
  startUploadRecordLoading: boolean;
  startUploadRecordError?: Error;

  // delete upload record
  deleteUploadRecordLoading: boolean;
  deleteUploadRecordSuccess?: Upload;
  deleteUploadRecordError?: Error;

  // upload records filter
  uploaderFilter?: UploaderFilter;

  // send files upload record
  sendFilesUploadRecordLoading: boolean;
  sendFilesUploadRecordError?: Error;

  // pause upload record
  pauseUploadRecordLoading: boolean;
  pauseUploadRecordError?: Error;

  // cancel upload record
  cancelUploadRecordLoading: boolean;
  cancelUploadRecordError?: Error;

  // complete upload record
  completeUploadRecordLoading: boolean;
  completeUploadRecordError?: Error;

  // rename upload record
  renameUploadRecordLoading: boolean;
  renameUploadRecordSuccess?: boolean;
  renameUploadRecordError?: Error;
};

export type UploadRecordPayload = {
  uploadRecords?: UploadRecord[];
  uploadRecord?: UploadRecord;
  operation?: string;
  uploadRecordId?: string;

  // get upload records
  fetchUploadRecordsLoading?: boolean;
  fetchUploadRecordsError?: Error;

  // get upload record
  fetchUploadRecordLoading?: boolean;
  fetchUploadRecordSuccess?: boolean;
  fetchUploadRecordError?: Error;

  // create upload record
  createUploadRecordLoading?: boolean;
  createUploadRecordSuccess?: Upload;
  createUploadRecordError?: Error;

  // start upload
  startUploadRecordLoading?: boolean;
  startUploadRecordError?: Error;

  // delete upload record
  deleteUploadRecordLoading?: boolean;
  deleteUploadRecordSuccess?: Upload;
  deleteUploadRecordError?: Error;

  // upload records filter
  uploaderFilter?: UploaderFilter;

  // send files upload record
  sendFilesUploadRecordLoading?: boolean;
  sendFilesUploadRecordError?: Error;

  // pause upload record
  pauseUploadRecordLoading?: boolean;
  pauseUploadRecordError?: Error;

  // cancel upload record
  cancelUploadRecordLoading?: boolean;
  cancelUploadRecordError?: Error;

  // complete upload record
  completeUploadRecordLoading?: boolean;
  completeUploadRecordError?: Error;

  // rename upload record
  renameUploadRecordLoading?: boolean;
  renameUploadRecordSuccess?: boolean;
  renameUploadRecordError?: Error;
};

export type UploadRecordAction = {
  payload?: UploadRecordPayload;
  type: UploadRecordActionType;
};

export type UploadRecordReducer = (
  state: UploadRecordState,
  payload?: UploadRecordPayload
) => UploadRecordState;
