import { UploadRecord } from 'src/types/UploadRecord';
import { UploadStatus, UploadStatusSettings } from 'src/types/UploadStatus';
import { FileStatusSettings } from 'src/types/FileStatus';

const getUploadRecordStatusFromFiles = (uploadRecord: UploadRecord): UploadStatus => {
  const canceledFiles = uploadRecord.files?.filter(
    (file) => file.status === FileStatusSettings.canceled.value
  ) || [];

  const activeFiles = uploadRecord.files?.filter(
    (file) => file.status === FileStatusSettings.active.value
  ) || [];

  const pendingUploadingFiles = uploadRecord.files?.filter(
    (file) => file.status === FileStatusSettings.pendingUploading.value
  ) || [];

  const failedFiles = uploadRecord.files?.filter(
    (file) => file.status === FileStatusSettings.failed.value
  ) || [];

  const pausedFiles = uploadRecord.files?.filter(
    (file) => file.status === FileStatusSettings.paused.value
  ) || [];

  if (failedFiles.length > 0) {
    return UploadStatusSettings.failed.value;
  }
  if (activeFiles.length > 0) {
    return UploadStatusSettings.active.value;
  }
  if (pausedFiles.length > 0) {
    return UploadStatusSettings.paused.value;
  }
  if (pendingUploadingFiles.length > 0) {
    return UploadStatusSettings.pendingUploading.value;
  }
  if (uploadRecord.status === UploadStatusSettings.completed.value) {
    return UploadStatusSettings.completed.value;
  }
  if (uploadRecord.status === UploadStatusSettings.canceled.value) {
    return UploadStatusSettings.canceled.value;
  }
  return UploadStatusSettings.created.value;
};

export default getUploadRecordStatusFromFiles;
