import { KEYS, getData, setData, clearData } from '../localStorageManager';

// Token.
export const getAuthToken = () => getData(KEYS.SESSION);
export const setAuthToken = (token: string) => setData(KEYS.SESSION, token);
export const clearAuthToken = () => clearData(KEYS.SESSION);

// Refresh token.
export const getAuthRefreshToken = () => getData(KEYS.REFRESH_SESSION);
export const setAuthRefreshToken = (refreshToken: string) =>
  setData(KEYS.REFRESH_SESSION, refreshToken);
export const clearAuthRefreshToken = () => clearData(KEYS.REFRESH_SESSION);
