import { useCallback, useMemo } from 'react';

// ca
import { Radar } from 'ca-react-component-lib';

// constants
import { DROPZONE_HEIGHT } from './constants';

// types
import { QuickDropzoneProps } from './types';
import { FileStatusSettings } from 'src/types/FileStatus';

// radar
const { Box, Link, Typography, useTheme, useDropzone } = Radar;

export function QuickDropzone({
  disabled,
  accept,
  onDropped,
  selectedFolder,
}: QuickDropzoneProps) {
  const theme = useTheme();

  const onDrop = useCallback(
    (droppedFiles) => {
      if (!onDropped) return;
      onDropped(droppedFiles);
    },
    [onDropped]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    maxFiles: undefined,
    onDrop,
    accept: accept,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderRadius: 4,
        backgroundColor: disabled
          ? theme.palette.primary.shade[300]
          : theme.palette.primary.shade[200],
        width: '100%',
        border: disabled
          ? 'none'
          : `1px dashed ${isDragAccept ? theme.palette.primary.main : theme.palette.divider}`,
        height: DROPZONE_HEIGHT,
        cursor: disabled ? 'initial' : 'pointer',
      },
    }),
    [theme, isDragAccept, disabled]
  );

  const isFailedFiles = useMemo(() => {
    if (selectedFolder) {
      return selectedFolder.files.some((file) => file.status === FileStatusSettings.failed.value);
    }
  }, [selectedFolder]);

  const disableDropZoneColor = useMemo(() => {
    if (disabled) {
      return theme.palette.text.disabled;
    } else if (isFailedFiles) {
      return theme.palette.error.main;
    } else return theme.palette.text.secondary;
  }, [disabled, isFailedFiles, theme]);

  return (
    /* @ts-ignore */
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Link
            underline={disabled ? 'none' : 'always'}
            variant={'subtitle1'}
            color={disabled ? theme.palette.text.disabled : undefined}
          >
            Select files
          </Link>
          <Typography
            variant={'subtitle1'}
            color={disabled ? theme.palette.text.disabled : undefined}
          >
            or drag and drop
          </Typography>
        </Box>
        <Typography variant={'body2'} color={disableDropZoneColor}>
          JPG or JPEG (max. 50MB)
        </Typography>
      </Box>
    </div>
  );
}
