// ca
import { Radar } from 'ca-react-component-lib';

// assets
import { Logo } from 'src/assets';

// components
import { LoginForm } from './LoginForm';

const { useTheme, Container, Paper, Box, Stack } = Radar;

const Login = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
        backgroundImage: "url('/static/backgrounds/default-background.jpg')",
        backgroundColor: theme.palette.background.default,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      }}
    >
      <Container maxWidth={'sm'} sx={{ minHeight: '100vh' }}>
        <Stack sx={{ alignItems: 'center', pt: 16 }}>
          <Logo />
          <Paper
            sx={{
              p: 8,
              borderRadius: 4,
              mt: 20,
              width: 500,
              boxShadow: theme.shadows[1],
            }}
          >
            <LoginForm />
          </Paper>
        </Stack>
      </Container>
    </Box>
  );
};
export default Login;
