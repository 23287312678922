import { ApplicationState } from '../types';

const _getData = (state: ApplicationState) => state.Authentication;

const isAuthenticated = (state: ApplicationState) => _getData(state).authenticated;

const isInitialized = (state: ApplicationState) => _getData(state).initialized;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const selectors = {
  isAuthenticated,
  isInitialized,
  getError,
  isLoading,
};

export default selectors;
