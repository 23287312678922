import { useCallback } from 'react';
import { Radar } from 'ca-react-component-lib';

// types
import { FolderProps } from './types';

// components
import { FolderContent } from './FolderContent';

// radar
const { useDropzone } = Radar;

export const Folder = ({ dropzone, onDropped, disabled, ...other }: FolderProps) => {
  const onDrop = useCallback(
    (droppedFiles) => {
      if (!onDropped) return;
      onDropped(droppedFiles);
    },
    [onDropped]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    noClick: true,
    onDrop,
    disable: dropzone,
  });

  return (
    <>
      {dropzone && !disabled ? (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <FolderContent {...other} isDragAccept={isDragAccept} />
        </div>
      ) : (
        <FolderContent {...other} disabled={disabled} />
      )}
    </>
  );
};
