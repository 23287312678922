// ca
import { Radar } from 'ca-react-component-lib';

// constants
import {
  BACK_BUTTON_SKELETON_WIDTH,
  FOLDER_SKELETON_HEIGHT,
  FOLDER_TITLE_SKELETON_WIDTH,
  HEADER_TITLE_SKELETON_WIDTH,
} from './constants';

// radar
const { Box, Skeleton, Stack, Paper, Grid, Avatar, useTheme } = Radar;

const Upload = () => {
  const theme = useTheme();
  return (
    <Stack sx={{ height: '100%' }}>
      <Stack sx={{ mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 7,
            height: theme.spacing(3),
            mt: 8.5,
            mb: 3,
          }}
        >
          <Skeleton variant="rectangular" width={BACK_BUTTON_SKELETON_WIDTH} height="100%" />
        </Box>

        <Skeleton sx={{ fontSize: 28, width: HEADER_TITLE_SKELETON_WIDTH }} />
      </Stack>
      <Grid container flexDirection="row" spacing={6}>
        <Grid item xs={3}>
          <Paper>
            <Stack gap={1} sx={{ p: 6 }}>
              {Array.from({ length: 7 }).map((_, index: number) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    height: FOLDER_SKELETON_HEIGHT,
                  }}
                >
                  <Skeleton variant="circular">
                    <Avatar
                      sx={{ width: theme.size.avatar.medium, height: theme.size.avatar.medium }}
                    />
                  </Skeleton>
                  <Skeleton width={FOLDER_TITLE_SKELETON_WIDTH} height={20} />
                </Box>
              ))}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Skeleton variant="rectangular" height="100%" />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Upload;
