import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// graphql
import {
  useGetUploadRecords,
  useGetUploadRecord,
  useCreateUploadRecord,
  useSendFilesUploadRecord,
  usePauseUploadRecord,
  useCancelUploadRecord,
  useCompleteUploadRecord,
  useDeleteUploadRecord,
  useStartUploadRecord,
  useRenameUploadRecord,
} from 'src/services/uploadRecord/graphql';

// reducer
import uploadRecordSelectors from 'src/store/UploadRecord/selectors';
import actions from 'src/store/UploadRecord/actions';

// types
import { UploaderFilter, UploadRecord } from 'src/types/UploadRecord';

const useUploadRecord = () => {
  const dispatch = useDispatch();

  const uploadRecords: UploadRecord[] = useSelector(uploadRecordSelectors.getUploadRecords);
  const uploadRecordId: string | undefined = useSelector(uploadRecordSelectors.getUploadRecordId);
  const uploadRecord: UploadRecord | undefined = uploadRecords.find(
    (uploadRecord: UploadRecord) => uploadRecord.upload?.id === uploadRecordId
  );

  const { createUploadRecord } = useCreateUploadRecord();
  const { startUploadRecord } = useStartUploadRecord();
  const { deleteUploadRecord } = useDeleteUploadRecord();
  const { getUploadRecords } = useGetUploadRecords();
  const { getUploadRecord } = useGetUploadRecord();
  const { sendFilesUploadRecord } = useSendFilesUploadRecord();
  const { pauseUploadRecord } = usePauseUploadRecord();
  const { cancelUploadRecord } = useCancelUploadRecord();
  const { completeUploadRecord } = useCompleteUploadRecord();
  const { renameUploadRecord } = useRenameUploadRecord();

  // create upload record
  const createUploadRecordLoading = useSelector(uploadRecordSelectors.getCreateUploadRecordLoading);
  const createUploadRecordSuccess = useSelector(uploadRecordSelectors.getCreateUploadRecordSuccess);
  const createUploadRecordError = useSelector(uploadRecordSelectors.getCreateUploadRecordError);

  // rename upload record
  const renameUploadRecordLoading = useSelector(uploadRecordSelectors.getRenameUploadRecordLoading);
  const renameUploadRecordSuccess = useSelector(uploadRecordSelectors.getRenameUploadRecordSuccess);
  const renameUploadRecordError = useSelector(uploadRecordSelectors.getRenameUploadRecordError);

  // start upload record
  const startUploadRecordLoading = useSelector(uploadRecordSelectors.getStartUploadRecordLoading);
  const startUploadRecordError = useSelector(uploadRecordSelectors.getStartUploadRecordLoading);

  // delete upload record
  const deleteUploadRecordLoading = useSelector(uploadRecordSelectors.getDeleteUploadRecordLoading);
  const deleteUploadRecordSuccess = useSelector(uploadRecordSelectors.getDeleteUploadRecordSuccess);
  const deleteUploadRecordError = useSelector(uploadRecordSelectors.getDeleteUploadRecordError);

  // get upload records
  const fetchUploadRecordsLoading = useSelector(uploadRecordSelectors.getFetchUploadRecordsLoading);
  const fetchUploadRecordsError = useSelector(uploadRecordSelectors.getFetchUploadRecordsError);

  // get upload record
  const fetchUploadRecordLoading: boolean = useSelector(
    uploadRecordSelectors.getFetchUploadRecordLoading
  );
  const fetchUploadRecordSuccess: boolean = useSelector(
    uploadRecordSelectors.getFetchUploadRecordSuccess
  );
  const fetchUploadRecordError: Error | undefined = useSelector(
    uploadRecordSelectors.getFetchUploadRecordError
  );

  // upload records filter
  const uploaderFilter = useSelector(uploadRecordSelectors.getUploaderFilter);

  // send files upload record
  const sendFilesUploadRecordLoading = useSelector(
    uploadRecordSelectors.getSendFilesUploadRecordLoading
  );
  const sendFilesUploadRecordError = useSelector(
    uploadRecordSelectors.getSendFilesUploadRecordError
  );

  // pause upload
  const pauseUploadRecordLoading = useSelector(uploadRecordSelectors.getPauseUploadRecordLoading);
  const pauseUploadRecordError = useSelector(uploadRecordSelectors.getPauseUploadRecordError);

  // cancel upload
  const cancelUploadRecordLoading = useSelector(uploadRecordSelectors.getCancelUploadRecordLoading);
  const cancelUploadRecordError = useSelector(uploadRecordSelectors.getCancelUploadRecordError);

  // complete upload
  const completeUploadRecordLoading = useSelector(
    uploadRecordSelectors.getCompleteUploadRecordLoading
  );
  const completeUploadRecordError = useSelector(uploadRecordSelectors.getCompleteUploadRecordError);

  // upload records filter
  const setUploaderFilter = useCallback(
    (UploaderFilter?: UploaderFilter) => {
      dispatch(actions.setUploaderFilter(UploaderFilter));
    },
    [dispatch]
  );

  const setUploadRecordId = useCallback(
    (uploadRecordId: string | undefined) => {
      dispatch(actions.setUploadRecordId(uploadRecordId));
    },
    [dispatch]
  );

  return {
    uploadRecords,
    uploadRecord,
    setUploadRecordId,
    uploadRecordId,

    // create upload record
    createUploadRecord,
    createUploadRecordLoading,
    createUploadRecordSuccess,
    createUploadRecordError,

    // start upload record
    startUploadRecord,
    startUploadRecordLoading,
    startUploadRecordError,

    // delete upload record
    deleteUploadRecord,
    deleteUploadRecordLoading,
    deleteUploadRecordSuccess,
    deleteUploadRecordError,

    // get upload records
    getUploadRecords,
    fetchUploadRecordsLoading,
    fetchUploadRecordsError,

    // upload record filters
    setUploaderFilter,
    uploaderFilter,

    // get upload record
    getUploadRecord,
    fetchUploadRecordLoading,
    fetchUploadRecordSuccess,
    fetchUploadRecordError,

    // send files upload record
    sendFilesUploadRecord,
    sendFilesUploadRecordLoading,
    sendFilesUploadRecordError,

    // pause upload
    pauseUploadRecord,
    pauseUploadRecordLoading,
    pauseUploadRecordError,

    // cancel upload
    cancelUploadRecord,
    cancelUploadRecordLoading,
    cancelUploadRecordError,

    // complete upload
    completeUploadRecord,
    completeUploadRecordLoading,
    completeUploadRecordError,

    // rename upload
    renameUploadRecord,
    renameUploadRecordLoading,
    renameUploadRecordSuccess,
    renameUploadRecordError,
  };
};

export default useUploadRecord;
