import { Radar } from 'ca-react-component-lib';
import { ButtonVariantProps } from './types';

const { useTheme, LoadingButton } = Radar;

const ButtonVariant = ({ isCancel, onClick, buttonLabel, icon, loading }: ButtonVariantProps) => {
  const theme = useTheme();

  return (
    <LoadingButton
      size={'medium'}
      sx={{ color: isCancel ? theme.palette.error.main : 'inherit', px: 2 }}
      startIcon={loading ? undefined : icon}
      onClick={onClick}
      loading={loading}
    >
      {buttonLabel}
    </LoadingButton>
  );
};

export default ButtonVariant;
