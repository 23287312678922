import { call, select, put, take, takeEvery, actionChannel } from 'redux-saga/effects';

// reducer
import actions from './actions';
import selectors from './selectors';

// types
import { UploadRecordActionType } from './types';
import { UploadRecord } from 'src/types/UploadRecord';

// utils
import { getUploadRecordProgress } from './util';
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';

function* handleUpdateUploadRecords(
  newUploadRecord: UploadRecord,
  uploadRecords: UploadRecord[],
  operation: string | undefined
) {
  const uploadRecord: UploadRecord | undefined = uploadRecords.find(
    (uploadRecord: UploadRecord) => uploadRecord.upload?.id === newUploadRecord.upload?.id
  );

  if (operation === 'REMOVE') {
    // Remove upload record
    uploadRecords = uploadRecords.filter(
      (uploadRecord: UploadRecord) => uploadRecord.upload?.id !== newUploadRecord.upload?.id
    );
  } else if (!uploadRecord) {
    // Add upload record
    uploadRecords = [newUploadRecord, ...uploadRecords];
  } else {
    // Update upload record
    uploadRecords = uploadRecords.map((uploadRecord: UploadRecord) =>
      uploadRecord?.upload?.id === newUploadRecord.upload?.id ? newUploadRecord : uploadRecord
    );
  }

  uploadRecords = uploadRecords.map((uploadRecord: UploadRecord) => {
    const status = getUploadRecordStatusFromFiles(uploadRecord);
    const progress = getUploadRecordProgress(uploadRecord);

    return {
      ...uploadRecord,
      status: status,
      progress: progress?.progress,
      progressRelative: progress?.progressRelative,
    };
  });

  yield put(actions.updateUploadRecords(uploadRecords));
}

export function* updateUploadsRecordsActionChannel() {
  const requestChannel = yield actionChannel(UploadRecordActionType.SET_UPLOAD_RECORD);

  while (true) {
    const { payload } = yield take(requestChannel);
    const uploadRecords = yield select(selectors.getUploadRecords);

    if (!payload || !payload.uploadRecord) {
      return;
    }

    yield call(handleUpdateUploadRecords, payload.uploadRecord, uploadRecords, payload.operation);
  }
}

const sagas = [
  takeEvery(UploadRecordActionType.WATCHER_SET_UPLOAD_RECORD, updateUploadsRecordsActionChannel),
];

export default sagas;
