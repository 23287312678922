import UploaderFolder from 'src/hooks/useUploader/UploaderFolder';

export enum UploaderActionType {
  SET_UPLOADER_FOLDERS = 'Uploader@SET_UPLOADER_FOLDERS',
  CLEAR = 'Uploader@CLEAR',
}

export type UploaderState = {
  uploaderFolders: UploaderFolder[];
};

export type UploaderPayload = {
  uploaderFolders?: UploaderFolder[];
};

export type UploaderAction = {
  payload?: UploaderPayload;
  type: UploaderActionType;
};

export type UploaderReducer = (state: UploaderState, payload?: UploaderPayload) => UploaderState;
