import { Fragment } from 'react';
import { useLocation, useParams } from 'react-router';
import { LoadingScreenHandler } from './constants';

export const LoadingScreen = () => {
  const { pathname } = useLocation();
  const { uploadId, folderName } = useParams();

  const renderLoadingScreen = () =>
    LoadingScreenHandler.map((loadingScreen, index: number) => {
      if (loadingScreen.isPathnameMatched(pathname, uploadId, folderName)) {
        return <Fragment key={index}>{loadingScreen.renderLoadingScreen()}</Fragment>;
      } else return null;
    });
  return <>{renderLoadingScreen()}</>;
};
