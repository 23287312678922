import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

// CA
import { Radar } from 'ca-react-component-lib';

// components
import Header from './Header';

// hooks
import {
  useAuth,
  useGlobalUploadWidget,
  useProfile,
  useUploadRecord,
  useUrlQueryParams,
} from 'src/hooks';

// constants
import { MAIN_PATHS } from 'src/routes/paths';
import {
  WIDGET_WIDTH,
  ACCORDION_SUMMARY_HEIGHT,
  CONTROLS_BOX_MAX_HEIGHT,
  ControlButtonsHandler,
} from './constants';

// types
import { UploadStatusSettings } from 'src/types/UploadStatus';
import { UploadRecord } from 'src/types/UploadRecord';

// utils
import getUploadRecordStatusFromFiles from 'src/utils/getUploadRecordStatusFromFiles';
import { UploadProgressControl } from './UploadProgressControl';

// Radar DS
const {
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Stack,
  Scrollbar,
} = Radar;

export const GlobalUploadWidget = () => {
  const theme = useTheme();
  const { profileId } = useProfile();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { uploadId } = useUrlQueryParams();
  const { getUploadRecords, uploadRecords, getUploadRecord } = useUploadRecord();
  const { open, expanded } = useGlobalUploadWidget();

  /**
   * Fetch all upload records.
   * If have upload id, also fetch upload record by id.
   */
  useEffect(() => {
    if (profileId) {
      getUploadRecords(uploadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  /**
   * Fetch upload record by id when changed.
   */
  useEffect(() => {
    if (uploadId && uploadRecords.length !== 0) {
      getUploadRecord(uploadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadId, profileId]);

  // creates an array of status values that require global controls for rendering global control buttons
  const uploadStatusesWithControls = useMemo(() => {
    if (uploadRecords.length !== 0) {
      // create array with extracted status values from each upload
      let statusesArr = uploadRecords.map((uploadRecord: UploadRecord) =>
        getUploadRecordStatusFromFiles(uploadRecord)
      );

      // filter status values array to keep only unique values
      let uniqueStatusesArr = statusesArr.filter((val, i, arr) => arr.indexOf(val) === i);

      // filter unique values array to keep status values that require global control buttons
      let filteredUniqueStatusesArr = uniqueStatusesArr.filter(
        (status) =>
          status !== UploadStatusSettings.canceled.value &&
          status !== UploadStatusSettings.completed.value &&
          status !== UploadStatusSettings.pendingUploading.value &&
          status !== UploadStatusSettings.created.value
      );

      return filteredUniqueStatusesArr;
    }

    return [];
  }, [uploadRecords]);

  const renderControlButtons = () =>
    ControlButtonsHandler.map(
      (controlButtonHandler, index: number) =>
        controlButtonHandler.isIncluded(uploadStatusesWithControls) && (
          <div key={index}>{controlButtonHandler.renderButton()}</div>
        )
    );

  if (pathname === MAIN_PATHS.unknown) {
    return null;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (open) {
    return (
      <Accordion
        expanded={expanded}
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 48,
          zIndex: theme.zIndex.modal,
          overflow: 'hidden',
          width: WIDGET_WIDTH,
          '&.MuiPaper-root': {
            borderRadius: expanded ? '16px 16px 0px 0px' : 4,
            mb: expanded ? 0 : 8,
          },
          '.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
            cursor: 'default',
          },
        }}
        disableGutters
      >
        <AccordionSummary
          sx={{
            background: theme.palette.primary.main,
            maxHeight: ACCORDION_SUMMARY_HEIGHT + 'px',
            minHeight: ACCORDION_SUMMARY_HEIGHT + 'px',
          }}
        >
          <Header />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 4,
          }}
        >
          {uploadRecords.length === 0 ? (
            <Typography color={theme.palette.text.disabled}>Select a folder to upload</Typography>
          ) : (
            <Stack spacing={3} sx={{ width: '100%' }}>
              <Scrollbar sx={{ maxHeight: CONTROLS_BOX_MAX_HEIGHT }}>
                <Stack spacing={3} sx={{ mr: uploadRecords.length > 4 ? 6 : 0 }}>
                  {uploadRecords.map((uploadRecord: UploadRecord) => (
                    <Box key={uploadRecord.upload?.id}>
                      <UploadProgressControl uploadRecord={uploadRecord} />
                    </Box>
                  ))}
                </Stack>
              </Scrollbar>

              {uploadStatusesWithControls?.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                  {renderControlButtons()}
                </Box>
              )}
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }

  return null;
};
